import { InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";

const CustomTextField = styled(TextField)({
    "& .MuiInput-underline:after": {
        borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
        height: 40,
        borderRadius: 4,
        backgroundColor: "white",
        color: "#B0B0B0",
        "& fieldset": {
            borderColor: "transparent",
        },
        "&:hover fieldset": {
            borderColor: "transparent",
        },
        "&.Mui-focused fieldset": {
            borderColor: "transparent",
        },
    },
    "& .MuiInputBase-input": {
        height: 8,
    },
});

const SearchField = ({ onChange, placeholder, value, isLoading }) => {
    return (
        <CustomTextField
            value={value}
            placeholder={placeholder}
            fullWidth
            disabled={isLoading}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#B0B0B0" }} />
                    </InputAdornment>
                ),
            }}
            onChange={(e) => onChange(e.target.value)}
        />
    );
};

export { SearchField };
