import {
    CircularProgress,
    FormControl,
    InputBase,
    MenuItem,
    Select,
    Typography,
    Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { constants } from "../../../config/constants";
import { getToken, getUserStorage } from "../../utils";

const CustomSelectCSS = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        position: "relative",
        // backgroundColor: "white",
        border: "1px solid #ced4da",
        padding: "5px 16px 6px 12px",
        fontSize: 14,
        borderRadius: "16px",
        borderRight: 0,
        // "&:focus": {
        //     borderRadius: "16px",
        // },
    },
}));

const ExportButton = ({ overviewPDF, pageName, isLoading }) => {
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(isLoading);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    useEffect(() => {
        setIsGeneratingPdf(isLoading);
    }, [isLoading]);

    const exportAsPDF = async () => {
        setIsGeneratingPdf(true);
        try {
            const response = await fetch(
                // "http://localhost:3002/generate-pdf",
                "https://console.ballurh.io/generate-pdf",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        // pageURL: `http://localhost:3001/${pageName}/`,
                        pageURL: `${constants.baseURL}/${pageName}/`,
                        token: getToken(),
                        user: getUserStorage(),
                        overviewPDF: {
                            ...overviewPDF,
                            isArabic,
                            printDate: `${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`,
                        },
                    }),
                }
            );

            if (response.ok) {
                // The PDF generation was successful
                const pdfBlob = await response.blob();
                const pdfUrl = URL.createObjectURL(pdfBlob);

                // Create a temporary link element to trigger the download
                const link = document.createElement("a");
                link.href = pdfUrl;
                link.download = `ballurh_${pageName}_${new Date().getTime()}.pdf`; // Set the desired filename
                link.click();

                // Clean up the temporary URL object
                URL.revokeObjectURL(pdfUrl);
                setIsGeneratingPdf(false);
            } else {
                // Handle error cases
                console.error("PDF generation failed");
            }
        } catch (error) {
            // TODO: Pending on design
        }
    };

    const exportAsPng = async () => {
        setIsGeneratingPdf(true);
        try {
            const response = await fetch(
                // "http://localhost:3002/generate-png",
                "https://console.ballurh.io/generate-pdf",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        // pageURL: `http://localhost:3001/${pageName}/`,
                        pageURL: `${constants.baseURL}/${pageName}/`,
                        token: getToken(),
                        user: getUserStorage(),
                        overviewPDF: {
                            ...overviewPDF,
                            isArabic,
                            printDate: `${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`,
                            exportType: "png",
                        },
                    }),
                }
            );

            if (response.ok) {
                // The PDF generation was successful
                const pdfBlob = await response.blob();
                const pdfUrl = URL.createObjectURL(pdfBlob);

                // Create a temporary link element to trigger the download
                const link = document.createElement("a");
                link.href = pdfUrl;
                link.download = `ballurh_${pageName}_${new Date().getTime()}.png`; // Set the desired filename
                link.click();

                // Clean up the temporary URL object
                URL.revokeObjectURL(pdfUrl);
                setIsGeneratingPdf(false);
            } else {
                // Handle error cases
                console.error("PNG generation failed");
            }
        } catch (error) {
            // TODO: Pending on design
        }
    };

    return (
        <FormControl
            sx={{
                width: 150,
                backgroundColor: "white",
                height: 36,
                borderRadius: "16px",
                position: "relative",
            }}
            disabled={isGeneratingPdf}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "5px",
                    left: "14px",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                }}
            >
                {isGeneratingPdf && <CircularProgress size={15} />}

                <Typography color={isGeneratingPdf && "gray"}>
                    {t("common.export")}
                </Typography>
            </Box>

            <Select
                value="Export"
                label="Export"
                placeholder="Export"
                displayEmpty
                input={<CustomSelectCSS />}
                IconComponent={ExpandMoreIcon}
            >
                <MenuItem
                    disabled={isGeneratingPdf}
                    onClick={() => exportAsPDF()}
                >
                    {t("common.export_pdf")}
                </MenuItem>
                {/* <MenuItem
                    disabled={isGeneratingPdf}
                    onClick={() => exportAsPng()}
                >
                    {t("common.export_png")}
                </MenuItem> */}
            </Select>
        </FormControl>
    );
};

export { ExportButton };
