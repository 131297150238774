export const topProductsFakeData = [
    {
        name: "Lymoon",
        sold: 430,
        price: 33366,
    },
    {
        name: "Espresso",
        sold: 412,
        price: 10132,
    },
    {
        name: "Latte",
        sold: 90,
        price: 8508,
    },
    {
        name: "Black Coffee",
        sold: 86,
        price: 2630,
    },
    {
        name: "Water",
        sold: 10,
        price: 45,
    },
];
