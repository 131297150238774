import {
    Box,
    Grid,
    Stack,
    Icon,
    Typography,
    LinearProgress,
    Divider,
    linearProgressClasses,
    Tooltip,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { format } from "date-fns";

import {
    DoughnutChart,
    GridCard,
    GridCardFlatLabels,
} from "../../../../../common/components";
import { InfoCard } from "../../Components/InfoCard";
import { TopCustomersTable } from "../TopCustomers";
import { CustomersCountsData } from "../../Components/CustomersCountsData";
import { numberWithCommas } from "../../../../../common/utils/numberWithCommas";
import { PageHeader } from "../../../../../common/components";
import { ExtendArabicLogoPDF } from "../../../Overview/pdf/OverviewPDF";
import { constants } from "../../../../../config/constants";
import { VictoryPie } from "victory";

const GenderIcon = ({ iconPath }) => {
    return (
        <Box
            width="48px"
            height="48px"
            backgroundColor="#F1F5F9"
            borderRadius="6px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            // padding={2.3}
        >
            <Icon
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <img src={iconPath} alt="infoCardIcon" />
            </Icon>
        </Box>
    );
};

const CustomersInsightsPDF = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const navigate = useNavigate();

    const [printDate, setPrintDate] = useState("");
    const [topCustomers, setTopCustomers] = useState(null);
    const [customerSegments, setCustomerSegments] = useState(null);
    const [customerInsights, setCustomerInsights] = useState(null);
    const [customersCount, setCustomersCount] = useState(null);
    const [genderData, setGenderData] = useState(null);
    const [genderInsights, setGenderInsights] = useState(null);
    const [period, setPeriod] = useState({
        startDate: null,
        endDate: format(new Date(), "y-M-d"),
    });

    function getTotalCount(arr, key = "total_sales") {
        let count = 0;
        arr.forEach((i) => (count = count + +i[key]));

        return count;
    }

    useEffect(() => {
        const pdfData = JSON.parse(localStorage.getItem("overview-pdf"));

        setTopCustomers(pdfData.top_customers);
        setCustomerSegments(pdfData.segments);
        setGenderInsights(pdfData.gender_insights);
        setCustomersCount(pdfData.customers_count);
        setGenderData(pdfData.gender_data);
        setCustomerInsights(pdfData.insights);
        setPrintDate(pdfData.printDate);
        setPeriod(pdfData.period);

        if (pdfData.isArabic) {
            localStorage.setItem(constants.storage.language, "ar");
            i18n.changeLanguage("ar");
        }
    }, []);

    const getGenderName = (genderName) => {
        if (genderName === "Male") return t("common.male");
        if (genderName === "Female") return t("common.female");
        return t("common.other");
    };

    const emptyChart = [
        {
            name: t("common.empty"),
            value: 100,
            fill: "#E3E3E3",
            outerRadius: 200,
        },
    ];

    return (
        <Stack dir={isArabic ? "rtl" : "ltr"}>
            <Box
                height={55}
                backgroundColor="black"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={8}
                sx={{
                    pageBreakAfter: "avoid",
                }}
            >
                {isArabic ? (
                    <ExtendArabicLogoPDF />
                ) : (
                    <img
                        src="/logo_extend.svg"
                        width={176}
                        height="31px"
                        alt="lgo"
                    />
                )}

                <Typography
                    fontSize={12}
                    color="white"
                    fontWeight="bold"
                    position="relative"
                    top="12px"
                >
                    {printDate}
                </Typography>
            </Box>

            <Stack padding={8} spacing={8} minWidth="100%">
                <Stack spacing={4}>
                    <PageHeader title={t("customer.insights")} />

                    <Typography fontSize={14}>
                        {period.startDate} &rarr; {period.endDate}
                    </Typography>
                </Stack>

                <Grid
                    container
                    spacing={6}
                    style={{
                        margin: isArabic ? 0 : "-24px",
                        marginTop: 2,
                        marginBottom: 2,
                    }}
                >
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2} rowGap={4}>
                            <Grid item xs={6} md={6}>
                                <InfoCard
                                    containerStyles={{
                                        height: 156,
                                        midWidth: 240,
                                        marginRight: isArabic ? 0 : 4,
                                        marginLeft: isArabic ? 4 : 0,
                                    }}
                                    label={t("customer.top_customers")}
                                    value={
                                        customerSegments?.top_customers
                                            ?.count || "0"
                                    }
                                    iconPath="/ic_Active_cust.svg"
                                    tooltipLabel={t("tooltips.top_customers")}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <InfoCard
                                    containerStyles={{
                                        height: 156,
                                        midWidth: 240,
                                        marginRight: isArabic ? 0 : 4,
                                        marginLeft: isArabic ? 4 : 0,
                                    }}
                                    label={t("customer.one_time_customers")}
                                    value={
                                        customerSegments?.one_time_customers
                                            ?.count || "0"
                                    }
                                    iconPath="/ic_customers_black.svg"
                                    tooltipLabel={t(
                                        "tooltips.one_time_customers"
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6} md={6}>
                                <InfoCard
                                    containerStyles={{
                                        height: 156,
                                        midWidth: 240,
                                        marginRight: isArabic ? 0 : 4,
                                        marginLeft: isArabic ? 4 : 0,
                                    }}
                                    label={t("customer.new_customers")}
                                    value={
                                        customerSegments?.new_customers
                                            ?.count || "0"
                                    }
                                    iconPath="/ic_new.svg"
                                    tooltipLabel={t("tooltips.new_customers")}
                                />
                            </Grid>

                            <Grid item xs={6} md={6}>
                                <InfoCard
                                    containerStyles={{
                                        height: 156,
                                        midWidth: 240,
                                        marginRight: isArabic ? 0 : 4,
                                        marginLeft: isArabic ? 4 : 0,
                                    }}
                                    label={t("customer.customers_at_risk")}
                                    value={
                                        customerSegments?.customers_at_risk
                                            ?.count || "0"
                                    }
                                    iconPath="/ic_Risk.svg"
                                    tooltipLabel={t(
                                        "tooltips.customers_at_risk"
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6} md={6}>
                                <InfoCard
                                    containerStyles={{
                                        height: 156,
                                        midWidth: 240,
                                        marginRight: isArabic ? 0 : 4,
                                        marginLeft: isArabic ? 4 : 0,
                                    }}
                                    label={t("customer.lost_customers")}
                                    value={
                                        customerSegments?.lost_customers
                                            ?.count || "0"
                                    }
                                    iconPath="/ic_Lost.svg"
                                    tooltipLabel={t("tooltips.lost_customers")}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}></Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Box paddingTop={10}>
                    <Stack
                        width="100%"
                        backgroundColor="white"
                        boxShadow="0 1px 3px hsla(220,6%,43%,0.2)"
                        borderRadius={2}
                        height="fit-content"
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            padding={6}
                            height={74}
                        >
                            <Typography
                                fontWeight="bold"
                                fontSize={20}
                                color="#202020"
                            >
                                {t("customer.customer_persona")}
                            </Typography>
                        </Box>

                        <Divider />

                        <Grid
                            container
                            spacing={6}
                            style={{
                                margin: isArabic ? 0 : "-24px",
                                marginTop: 2,
                                marginBottom: 2,
                                padding: "16px",
                            }}
                        >
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={6}>
                                        <InfoCard
                                            containerStyles={{
                                                height: 156,
                                                border: "1px solid #E5E6E9",
                                                boxShadow: "none",
                                            }}
                                            label={t(
                                                "customer.average_basket_value"
                                            )}
                                            value={`${
                                                numberWithCommas(
                                                    customerInsights
                                                        ?.average_basket_value
                                                        ?.average_value
                                                ) || 0
                                            } ${t("common.currency")} `}
                                            tooltipLabel={t(
                                                "tooltips.customer_avg_basket_value"
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <InfoCard
                                            containerStyles={{
                                                height: 156,
                                                border: "1px solid #E5E6E9",
                                                boxShadow: "none",
                                            }}
                                            label={t(
                                                "customer.average_transactions"
                                            )}
                                            value={`${
                                                customerInsights
                                                    ?.average_transactions
                                                    ?.average_value || 0
                                            }`}
                                            tooltipLabel={t(
                                                "tooltips.customer_avg_transactions"
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <InfoCard
                                            containerStyles={{
                                                height: 156,
                                                border: "1px solid #E5E6E9",
                                                boxShadow: "none",
                                            }}
                                            label={t(
                                                "overview.avg_basket_size"
                                            )}
                                            value={`${
                                                customerInsights
                                                    ?.average_basket_size
                                                    ?.average_value || 0
                                            } ${t("common.product_s")}`}
                                            tooltipLabel={t(
                                                "tooltips.customer_avg_basket_size"
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <InfoCard
                                            containerStyles={{
                                                height: 156,
                                                border: "1px solid #E5E6E9",
                                                boxShadow: "none",
                                            }}
                                            label={t("overview.total_sales")}
                                            value={`${
                                                numberWithCommas(
                                                    customerInsights
                                                        ?.total_sales
                                                        ?.average_value
                                                ) || 0
                                            } ${t("common.currency")} `}
                                            tooltipLabel={t(
                                                "tooltips.customer_total_sales"
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Box padding={4}>
                            {genderInsights && (
                                <Stack
                                    border="1px solid #E5E6E9"
                                    borderRadius={5}
                                    width="100%"
                                    height="350px"
                                    gap={3}
                                    paddingX={8}
                                    paddingY={6}
                                    paddingBottom={0}
                                >
                                    <Box
                                        display="flex"
                                        gap={3}
                                        alignItems="baseline"
                                    >
                                        <Typography
                                            fontSize="18px"
                                            color="#999999"
                                        >
                                            {t("customer.demographic_info")}
                                        </Typography>

                                        <Tooltip
                                            title={t(
                                                "tooltips.customer_demographic_info"
                                            )}
                                        >
                                            <HelpOutlineIcon
                                                sx={{ fontSize: 16 }}
                                            />
                                        </Tooltip>
                                    </Box>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        dir="ltr"
                                    >
                                        <Box
                                            width="50%"
                                            maxWidth={200}
                                            height="100%"
                                        >
                                            <svg
                                                viewBox="0 0 400 300"
                                                style={{
                                                    // width: "100%",
                                                    // height: "100%",
                                                    padding: "0",
                                                    // marginTop: "10px",
                                                    overflow: "visible",
                                                }}
                                            >
                                                <VictoryPie
                                                    standalone={false}
                                                    x="name"
                                                    y="value"
                                                    data={
                                                        genderInsights?.length
                                                            ? genderInsights
                                                            : emptyChart
                                                    }
                                                    colorScale={
                                                        genderInsights?.length
                                                            ? constants.pieColors
                                                            : ["#E3E3E3"]
                                                    }
                                                    radius={47}
                                                    innerRadius={52}
                                                    labelRadius={({ datum }) =>
                                                        datum.outerRadius + 80
                                                    }
                                                    labels={({ datum }) => ""}
                                                    style={{
                                                        labels: {
                                                            fontSize: 26,
                                                        },
                                                    }}
                                                />

                                                <VictoryPie
                                                    standalone={false}
                                                    x="name"
                                                    y="value"
                                                    data={
                                                        genderInsights?.length
                                                            ? genderInsights
                                                            : emptyChart
                                                    }
                                                    colorScale={
                                                        genderInsights?.length
                                                            ? constants.pieColors
                                                            : ["#E3E3E3"]
                                                    }
                                                    radius={({ datum }) =>
                                                        datum.outerRadius
                                                    }
                                                    labelRadius={80}
                                                    innerRadius={55}
                                                    // labelComponent={
                                                    //     <VictoryTooltip
                                                    //         renderInPortal
                                                    //         style={{
                                                    //             fill: "black",
                                                    //             fontSize: "22px",
                                                    //         }}
                                                    //         pointerLength={0}
                                                    //         flyoutPadding={10}
                                                    //         flyoutStyle={{
                                                    //             fill: "white",
                                                    //             strokeWidth: 1,
                                                    //         }}
                                                    //     />
                                                    // }
                                                    style={{
                                                        labels: {
                                                            fontSize: 24,
                                                            color: "white",
                                                            fill: "white",
                                                        },
                                                    }}
                                                    labels={
                                                        ({ datum }) =>
                                                            `${datum.value}%`
                                                        // `${((datum.value / total) * 100).toFixed(
                                                        //     0
                                                        // )}%`
                                                    }
                                                    // labels={({ datum }) =>
                                                    //     data?.length > 5
                                                    //         ? ` `
                                                    //         : isArabic
                                                    //         ? `%${((datum.value / total) * 100).toFixed(
                                                    //               0
                                                    //           )}`
                                                    //         : `${((datum.value / total) * 100).toFixed(
                                                    //               0
                                                    //           )}%`
                                                    // }
                                                />
                                            </svg>
                                        </Box>
                                    </Box>

                                    <Box
                                        position="relative"
                                        top="45px"
                                        display="flex"
                                        justifyContent="center"
                                        width="100%"
                                    >
                                        <GridCardFlatLabels
                                            data={
                                                genderInsights?.length
                                                    ? genderInsights
                                                    : emptyChart
                                            }
                                            isFlat
                                            isCenter
                                            withPercentage={false}
                                        />
                                    </Box>
                                </Stack>
                            )}
                        </Box>
                    </Stack>
                </Box>

                <Grid container rowSpacing={8} flexWrap="wrap" paddingTop={10}>
                    <GridCard padding={0} height={360} fullWidth>
                        <TopCustomersTable
                            data={topCustomers}
                            withViewAll={false}
                        />
                    </GridCard>

                    <GridCard padding={0} fullWidth>
                        <Stack>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                padding={6}
                                height={74}
                            >
                                <Typography fontSize={20} fontWeight="bold">
                                    {t("customer.sales_value")} /{" "}
                                    {t("common.gender")}
                                </Typography>

                                <Typography fontSize={18} color="#71737B">
                                    {t("overview.total_sales")}{" "}
                                    {getTotalCount(genderData || [])}{" "}
                                    {t("common.currency")}
                                </Typography>
                            </Box>

                            <Divider />

                            <Box padding={6}>
                                <Stack spacing={8}>
                                    {genderData?.map((gender) => (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            position="relative"
                                            gap={4}
                                        >
                                            <Box>
                                                {gender.name === "Male" && (
                                                    <GenderIcon iconPath="/ic_male.svg" />
                                                )}
                                                {gender.name === "Female" && (
                                                    <GenderIcon iconPath="/ic_female.svg" />
                                                )}
                                                {!gender.name && (
                                                    <GenderIcon iconPath="/ic_Other.svg" />
                                                )}
                                            </Box>

                                            <Stack width="100%" spacing={3}>
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="baseline"
                                                        gap={1}
                                                    >
                                                        <Typography
                                                            fontSize={14}
                                                            color="#999999"
                                                        >
                                                            {getGenderName(
                                                                gender.name
                                                            )}
                                                        </Typography>

                                                        <Typography
                                                            fontSize={14}
                                                            color="#334155"
                                                        >
                                                            {t(
                                                                "common.currency"
                                                            )}{" "}
                                                            {gender.total_sales}
                                                        </Typography>
                                                    </Box>

                                                    <Typography
                                                        fontSize={16}
                                                        fontWeight="bold"
                                                        color="#334155"
                                                    >
                                                        {isArabic
                                                            ? `%${gender.value.toFixed(
                                                                  2
                                                              )}`
                                                            : `${gender.value.toFixed(
                                                                  2
                                                              )}%`}
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ width: "100%" }}>
                                                    <LinearProgress
                                                        sx={{
                                                            backgroundColor:
                                                                "#F1F5F9",
                                                            [`& .${linearProgressClasses.bar}`]:
                                                                {
                                                                    borderRadius: 5,
                                                                    backgroundColor:
                                                                        !!gender.name
                                                                            ? gender.name ===
                                                                              "Male"
                                                                                ? "lightBlue"
                                                                                : "lightGreen"
                                                                            : "dark",
                                                                },
                                                        }}
                                                        variant="determinate"
                                                        value={gender.value}
                                                    />
                                                </Box>
                                            </Stack>
                                        </Box>
                                    ))}
                                </Stack>
                            </Box>
                        </Stack>
                    </GridCard>
                </Grid>

                <Grid container spacing={0} paddingTop={10}>
                    <GridCard gutter padding={0} fullWidth>
                        <CustomersCountsData data={customersCount} />
                    </GridCard>
                </Grid>
            </Stack>
        </Stack>
    );
};

export { CustomersInsightsPDF };
