import axios from "axios";
import { constants } from "../../config/constants";
import { getToken } from "../../common/utils";

// configure axios
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

const POST = async (
    url,
    data,
    customAllowedHeaders = false,
    customHeaders = {}
) => {
    const session = getToken();
    const head = customAllowedHeaders || {
        headers: {
            Authorization: session ? `Bearer ${session}` : null,
            ...customHeaders,
            //   "Accept-Language": localStorage.getItem("i18nextLng"),
        },
    };
    const res = await axios.post(url, data, head);

    return res;
};

const PUT = async (url, data, customAllowedHeaders = false) => {
    const session = getToken();
    const head = customAllowedHeaders || {
        headers: {
            Authorization: `Bearer ${session}`,
            //   "Accept-Language": localStorage.getItem("i18nextLng"),
        },
    };
    const res = await axios.put(url, data, head);

    return res;
};

const PATCH = async (url, data) => {
    const session = getToken();
    const head = {
        headers: {
            Authorization: `Bearer ${session}`,
            //   "Accept-Language": localStorage.getItem("i18nextLng"),
        },
    };
    const res = await axios.patch(url, data, head);

    return res;
};
const DELETE = async (url, data, dataObject) => {
    const session = getToken();
    const head = {
        headers: {
            Authorization: `Bearer ${session}`,
            //   "Accept-Language": localStorage.getItem("i18nextLng"),
        },
    };
    const dataSent = dataObject ? { data: data } : { data: { data: data } };
    const res = await axios.delete(url, {
        ...dataSent,
        ...head,
    });

    return res;
};

const GET = async (url, customAllowedHeaders = false) => {
    const session = getToken();
    const head = customAllowedHeaders || {
        headers: {
            Authorization: `Bearer ${session}`,
            //   "Accept-Language": localStorage.getItem("i18nextLng"),
        },
    };
    const res = await axios.get(url, head);

    return res;
};

const exportedObject = { POST, GET, PUT, DELETE, PATCH };

export default exportedObject;
