import { Typography, Stack, Box } from "@mui/material";

const CylinderBar = ({ perc, height = 250, color, imgPath }) => {
    const colors = {
        darkBlue: ["#06262d", "#083b47"],
        lightBlue: ["#5ccfdf", "#65dfef"],
        green: ["#9fe3b9", "#a7eec2"],
        gray: ["#d5d5d5", "#e9e8e8"],
    };

    return (
        <Stack alignItems="center" spacing={8}>
            <Typography fontSize={22} fontWeight="bold" color="#06262D">
                {perc} %
            </Typography>
            <Box
                width="67px"
                height={height}
                position="relative"
                backgroundColor="#fafafa"
            >
                <Box
                    width="100%"
                    height="39px"
                    backgroundColor="#F4F4F4"
                    borderRadius="50%"
                    position="absolute"
                    top={-20}
                ></Box>

                <Box
                    width="67px"
                    height={`${perc}%`}
                    position="absolute"
                    bottom={0}
                    backgroundColor={colors[color][0]}
                    zIndex={1}
                >
                    <Box
                        width="100%"
                        height="39px"
                        backgroundColor={
                            perc > 0 ? colors[color][1] : "#fafafa"
                        }
                        borderRadius="50%"
                        position="absolute"
                        top={-20}
                    ></Box>

                    <Box
                        width="100%"
                        height="39px"
                        backgroundColor={
                            perc > 0 ? colors[color][0] : "#fafafa"
                        }
                        borderRadius="50%"
                        position="absolute"
                        bottom={-20}
                    ></Box>
                </Box>

                <Box
                    width="28px"
                    height="28px"
                    position="absolute"
                    bottom={0}
                    left="30%"
                    zIndex={2}
                >
                    <img
                        src={imgPath}
                        width="100%"
                        height="100%"
                        alt="barImage"
                    />
                </Box>

                <Box
                    width="100%"
                    height="39px"
                    backgroundColor="#fafafa"
                    borderRadius="50%"
                    position="absolute"
                    bottom={-20}
                ></Box>
            </Box>
        </Stack>
    );
};

export { CylinderBar };
