import { Box, Grid, Stack, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { services } from "../../services";
import { ProductDetails } from "./ProductDetails";

const LikeForLikeProducts = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const { data } = useQuery({
        queryKey: ["allProducts"],
        queryFn: services.getMerchantProducts,
    });

    const getProductNames = useCallback(() => {
        return data?.data?.response.map((p) => ({
            id: p.product_id,
            label: p.name,
        }));
    }, [data?.data?.response]);

    const allProducts = getProductNames(data?.data?.response) || [];

    useEffect(() => {
        window.pendo.pageLoad({
            name: "Products Like for Like Page",
        });
    }, []);

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <Stack spacing={0}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h5" fontSize={30}>
                        {t("likeForLike.product_like")}
                    </Typography>
                </Box>
            </Stack>

            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 0,
                    marginBottom: 0,
                }}
            >
                <ProductDetails
                    allProducts={allProducts}
                    label={`${t("likeForLike.first")} ${t(
                        "likeForLike.product"
                    )}`}
                    gutter={isArabic}
                />

                <ProductDetails
                    allProducts={allProducts}
                    label={`${t("likeForLike.second")} ${t(
                        "likeForLike.product"
                    )}`}
                />
            </Grid>
        </Stack>
    );
};

export { LikeForLikeProducts };
