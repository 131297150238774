import { Divider, Stack, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { SimpleBar } from "../../../../common/components";

const TrendingHours = ({ data }) => {
    const { t } = useTranslation();

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Typography fontWeight="bold" fontSize={20} color="#202020">
                    {t("sales.trending_hours")}
                </Typography>
            </Box>

            <Divider />

            <Box padding={6}>
                <SimpleBar
                    data={data}
                    container={{ width: "100%", height: 250 }}
                    margin={{}}
                />
            </Box>
        </Stack>
    );
};

export { TrendingHours };
