import { Divider, Stack, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridCardHeader, SimpleBar } from "../../../../../common/components";

const TrendingHours = ({ data }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    return (
        <Box sx={{ overflowX: "scroll", overflowY: "hidden" }}>
            <Stack minWidth="750px">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    padding={6}
                    height={50}
                >
                    <GridCardHeader title={t("overview.trending_hours")} />
                </Box>

                <Divider />

                <Box padding={6}>
                    <SimpleBar
                        data={data}
                        container={{ width: "100%", height: 250 }}
                        xTickProps={{ fontSize: 10 }}
                        yTickProps={{ fontSize: 10 }}
                        barSize={13}
                        margin={{
                            top: 5,
                            // right: isArabic ? 50 : 10,
                            // left: isArabic ? 0 : 50,
                            bottom: 5,
                        }}
                        xAxisProps={{
                            ticks: data.map((item) => item.name),
                        }}
                    />
                </Box>
            </Stack>
        </Box>
    );
};

export { TrendingHours };
