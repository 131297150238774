import { useTranslation } from "react-i18next";
import { GridCard } from "../../../common/components";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { ChainSettings } from "./ChainSettings";
import { Profile } from "./Profile";
import { Password } from "./Password";
import { Email } from "./Email";

const { Box, Stack, Typography, Grid, Tabs, Tab } = require("@mui/material");

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
        }}
    />
))({
    "& .MuiTabs-indicator": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
        // maxWidth: 40,
        width: "100%",
        backgroundColor: "#5BCFDE",
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: "#6B7280",
        "&.Mui-selected": {
            color: "#5BCFDE",
        },
        "&.Mui-focusVisible": {
            backgroundColor: "#5BCFDE",
        },
    })
);

const Account = () => {
    const { t } = useTranslation();

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <Stack spacing={0}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h5" fontSize={30} fontWeight="bold">
                        {t("account.account")}
                    </Typography>
                </Box>
            </Stack>

            <Grid container>
                <GridCard fullWidth height="fit-content">
                    <Stack spacing={15}>
                        {/* <Typography variant="h5" fontSize={18}>
                            {t("account.chain_profile")}
                        </Typography> */}
                        <StyledTabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <StyledTab
                                label={t("account.chain_profile")}
                                {...a11yProps(0)}
                            />
                            <StyledTab
                                label={t("account.profile")}
                                {...a11yProps(1)}
                            />
                            <StyledTab
                                label={t("account.password")}
                                {...a11yProps(2)}
                            />
                            <StyledTab
                                label={t("account.email")}
                                {...a11yProps(3)}
                            />
                        </StyledTabs>

                        {value === 0 && <ChainSettings />}
                        {value === 1 && <Profile />}
                        {value === 2 && <Password />}
                        {value === 3 && <Email />}
                    </Stack>
                </GridCard>
            </Grid>
        </Stack>
    );
};

export { Account };
