import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
    Typography,
    Stack,
    Button,
    FormControlLabel,
    Checkbox,
    Box,
} from "@mui/material";
import { toast } from "react-toastify";
import { CustomTextField } from "../../../common/components";
import { constants } from "../../../config/constants";
import { AuthServices } from "../../services";
import { getToken } from "../../../common/utils";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const Login = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const session = getToken();
    const [keepUserLoggedIn, setKeepUserLoggedIn] = useState(true);
    const navigate = useNavigate();
    const { handleSubmit, control } = useForm({
        defaultValues: {
            email: "",
            password: "",
        },
    });

    useEffect(() => {
        if (session) navigate("/overview");
    }, [session]);

    const loginCallback = (data) => {
        if (!data.error) {
            const token = data.response.token;

            if (keepUserLoggedIn) {
                localStorage.setItem(constants.storage.token, token);
            } else sessionStorage.setItem(constants.storage.token, token);

            navigate("/overview");
        } else {
            toast.error(t("common.auth_error"), {
                hideProgressBar: true,
            });
        }
    };

    const { mutate: signIn, isLoading } = useMutation({
        mutationFn: AuthServices.login,
        onSuccess: (res) => loginCallback(res.data),
        onError: (err) => console.log(err),
    });

    const onSubmit = (values) => {
        signIn(values);
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Stack
            w="100%"
            height="100vh"
            bgcolor="#f5f6f6"
            display="flex"
            justifyContent="center"
            alignItems="center"
            spacing={10}
            dir={isArabic ? "rtl" : "ltr"}
        >
            <Box>
                <img src="/logo_ballurh.png" width="260px" alt="ballurh logo" />
            </Box>

            <Stack
                spacing={8}
                padding={8}
                width="360px"
                height="400px"
                bgcolor="white"
                boxShadow="0 1px 3px hsla(220,6%,43%,0.2)"
                borderRadius="8px"
            >
                <Typography variant="h5" fontWeight="bold">
                    {t("login.login")}
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={10}>
                        <Stack spacing={4}>
                            <Stack spacing={1}>
                                <Typography variant="body1">
                                    {t("common.email")}
                                </Typography>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomTextField
                                            placeholder={t("login.enter_email")}
                                            variant="outlined"
                                            {...field}
                                        />
                                    )}
                                />
                            </Stack>

                            <Stack spacing={1} position="relative">
                                <Typography variant="body1">
                                    {t("login.password")}
                                </Typography>

                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomTextField
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            placeholder={t("login.password")}
                                            variant="outlined"
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    height: 40,
                                                    paddingRight: 9,
                                                },
                                            }}
                                            {...field}
                                        />
                                    )}
                                />

                                <Box
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{
                                        position: "absolute",
                                        top: 32,
                                        right: 2,
                                        color: "#6B7A90",
                                        cursor: "pointer",
                                        // bgcolor: "white",
                                        paddingX: 2,
                                    }}
                                >
                                    {showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </Box>
                            </Stack>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{
                                            padding: "0",
                                            paddingInlineEnd: 8,
                                        }}
                                        checked={keepUserLoggedIn}
                                        onChange={(e, nextValue) =>
                                            setKeepUserLoggedIn(nextValue)
                                        }
                                    />
                                }
                                label={
                                    <Typography variant="body2">
                                        {t("login.remember_me")}
                                    </Typography>
                                }
                            />
                        </Stack>
                        <Button
                            disabled={isLoading}
                            type="submit"
                            variant="contained"
                            style={{
                                textTransform: "capitalize",
                                backgroundColor: "#5BCFDE",
                                fontSize: 16,
                            }}
                        >
                            {t("login.sign_in")}
                        </Button>
                    </Stack>
                </form>
            </Stack>
        </Stack>
    );
};
