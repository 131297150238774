import { useTranslation } from "react-i18next";

const TooltipContent = ({ data }) => {
    const { t } = useTranslation();
    if (!data) return null; // If no data, don't render the tooltip

    // Assuming the payload contains 'popularity' and 'profitability' properties
    const popularity = data.popularity;
    const profitablility = data.profitablility;
    const name = data.name;

    return (
        <div
            style={{
                background: "white",
                padding: "8px",
                border: "1px solid #ccc",
            }}
        >
            <p>
                <strong>{t("common.product")}: </strong> {name}
            </p>
            <p>
                <strong>{t("menu.popularity")}:</strong> {popularity} %
            </p>
            <p>
                <strong>{t("menu.profitability")}:</strong> {profitablility} %
            </p>
        </div>
    );
};

export { TooltipContent };
