import {
    Box,
    Stack,
    Typography,
    Tooltip as MaterialTooltip,
} from "@mui/material";
import {
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    XAxis,
    YAxis,
    Tooltip,
} from "recharts";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { TooltipContent } from "./CustomToolTip";

const CustomStarIcon = ({ cx, cy, onMouseEnter, onMouseLeave, ...rest }) => {
    const handleMouseEnter = () => {
        onMouseEnter({
            id: rest.id,
            name: rest.name,
            popularity: rest.popularity,
            profitablility: rest.profitablility,
        });
    };

    const handleMouseLeave = () => {
        onMouseLeave(null);
    };

    return (
        <>
            <svg
                x={cx - 11}
                y={cy - 11}
                width="22px"
                height="22px"
                viewBox="0 0 22 22"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                onMouseOver={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                >
                    <g
                        id="UI_Owner-Menu-Insights"
                        transform="translate(-513, -1082)"
                    >
                        <g
                            id="Group-3-Copy-37"
                            transform="translate(513, 1082)"
                        >
                            <path
                                d="M21.999999,8.92075653 C21.8857171,9.31420536 21.6287552,9.59086172 21.3382822,9.85437612 C19.8795771,11.177709 18.4303604,12.5125185 16.9726473,13.8370666 C16.8432606,13.9546691 16.8067305,14.0569244 16.8459346,14.234341 C17.3008149,16.2915085 17.7456462,18.3510615 18.1960843,20.4093092 C18.313783,20.9471846 18.2039337,21.4121041 17.7732919,21.7469092 C17.3358787,22.08698 16.8697418,22.0701475 16.4074001,21.7814293 C14.6765087,20.7006827 12.9436333,19.6233567 11.217831,18.5337888 C11.0477739,18.4264027 10.9361994,18.4433252 10.7786065,18.542295 C9.05664273,19.6238968 7.33105613,20.6993325 5.60495198,21.7737782 C5.02115942,22.1371625 4.38444772,22.0363024 4.00707,21.5255211 C3.76822225,21.2022377 3.71124899,20.8375032 3.79694608,20.4445494 C4.24596088,18.3860317 4.69273298,16.3269738 5.1486484,14.2700763 C5.19246734,14.0724518 5.16063823,13.9519687 5.00994591,13.8152383 C3.486375,12.4330367 1.97061041,11.0414736 0.452344349,9.652926 C0.0600009018,9.29408735 -0.0978076805,8.84919583 0.0612516394,8.3259476 C0.216386231,7.81539127 0.572156385,7.525998 1.08724463,7.47523041 C2.29834679,7.35582756 3.51005275,7.24218557 4.72154307,7.12714837 C5.56251316,7.04730644 6.40348325,6.96687941 7.24484149,6.89230326 C7.37664336,6.88064652 7.43961153,6.82128264 7.49110741,6.69467871 C8.28463573,4.7448522 9.0866173,2.79885125 9.87962807,0.848799702 C10.0787541,0.359171498 10.4034715,0.0509654181 10.9187322,0.00483352096 C11.4507701,-0.0427835982 11.8824903,0.263442185 12.1162919,0.834082501 C12.8470677,2.61720409 13.5994942,4.39127933 14.2921872,6.19042332 C14.4916152,6.70836076 14.7350346,6.91552673 15.2765177,6.95378245 C17.0824534,7.08151155 18.8830843,7.28899257 20.6877692,7.43954008 C21.3564394,7.49534842 21.809379,7.78636193 21.999999,8.4723995 L21.999999,8.92075653 Z"
                                id="Fill-1"
                                fill="#06262D"
                                mask="url(#mask-2)"
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        </>
    );
};

const StarScatterChart = ({ data }) => {
    const { t } = useTranslation();

    const [tooltipData, setTooltipData] = useState(null);

    const handleScatterPointEnter = (proData) => {
        if (!tooltipData) setTooltipData(proData);
    };

    const handleScatterPointLeave = () => {
        if (tooltipData) {
            setTooltipData(null);
        }
    };

    return (
        <Stack width="100%" height="100%" spacing={4}>
            <Box display="flex" alignItems="center" gap={2}>
                <Typography fontSize={18} sx={{ color: "#1F2938" }}>
                    {t("menu.stars")}
                </Typography>

                <MaterialTooltip title={t("tooltips.menu_stars")}>
                    <HelpOutlineIcon sx={{ fontSize: 16 }} />
                </MaterialTooltip>
            </Box>

            <Box
                width="100%"
                height={280}
                border="1px solid #EEEFF4"
                borderRadius={4}
                backgroundColor="#FAFCFE"
            >
                <ResponsiveContainer width="100%" height={280}>
                    <ScatterChart
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <XAxis
                            dataKey="popularity"
                            type="number"
                            // reversed={true}
                            axisLine={false}
                            tick={false}
                        />
                        <YAxis
                            dataKey="profitablility"
                            type="number"
                            axisLine={false}
                            tick={false}
                        />
                        {/* <Tooltip /> */}
                        <Tooltip
                            content={<TooltipContent data={tooltipData} />} // Replace TooltipContent with your custom tooltip content component
                            isAnimationActive={false} // Optional: Disable tooltip animation for better responsiveness
                        />
                        <Scatter
                            name="star classes"
                            data={data}
                            shape={(props) => (
                                <CustomStarIcon
                                    {...props}
                                    onMouseEnter={handleScatterPointEnter}
                                    onMouseLeave={handleScatterPointLeave}
                                />
                            )}
                            cursor="pointer" // Enable pointer cursor for interactivity
                        />
                    </ScatterChart>
                </ResponsiveContainer>
            </Box>
        </Stack>
    );
};

export { StarScatterChart };
