import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { constants } from "../../../../../config/constants";

function sumValues(obj) {
    if (!obj) return;

    const { name, prev, ...rest } = obj;
    return Object.values(rest).reduce((sum, value) => sum + +value, 0);
}

const CustomTooltip = ({ active, payload, withCompare }) => {
    const { t } = useTranslation();

    if (active && payload && payload.length) {
        const dataPoint = payload[0].payload;
        const total = sumValues(dataPoint);
        const totalPrev = sumValues(dataPoint.prev);

        return (
            <div className="custom-tooltip">
                <Stack>
                    <Typography
                        color="#818992"
                        fontSize="14px"
                        fontWeight="bold"
                    >
                        {dataPoint.name.split("_")[0]}
                    </Typography>
                    <Typography
                        color="#06262D"
                        fontSize="12px"
                        fontWeight="bold"
                    >
                        {t("common.total_sales")} : {total}
                    </Typography>
                    {withCompare && (
                        <Typography
                            color="#06262D"
                            fontSize="12px"
                            fontWeight="bold"
                        >
                            {t("sales.previous_period")} : {totalPrev}
                        </Typography>
                    )}

                    <Stack>
                        {Object.keys(dataPoint)
                            .filter((d) => d !== "name")
                            .filter((d) => d !== "prev")
                            .map((dataKey) => (
                                <Stack flexDirection="row" key={dataKey}>
                                    <Typography color="#06262D" fontSize="12px">
                                        {dataKey}: {dataPoint[`${dataKey}`]}
                                    </Typography>{" "}
                                    {withCompare && dataPoint?.prev && (
                                        <>
                                            ({"  "}
                                            <Typography
                                                color="#06262D"
                                                fontSize="12px"
                                            >
                                                Previous Period:{" "}
                                                {dataPoint?.prev[`${dataKey}`]}
                                            </Typography>
                                            )
                                        </>
                                    )}
                                </Stack>
                            ))}
                    </Stack>
                </Stack>
            </div>
        );
    }
    return null;
};

const CustomTick = ({ x, y, payload, ...props }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const textAnchor = isArabic ? "start" : "end";
    const xOffset = isArabic ? 80 : 0; // Adjust this value as needed

    const truncateText = (text, maxLength) => {
        return text.length > maxLength
            ? `${text.slice(0, maxLength)}...`
            : text;
    };
    // Apply truncation to the label text
    const truncatedLabel = truncateText(`${payload.value}`, 15);

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={xOffset}
                y={0}
                dy={16}
                textAnchor={textAnchor}
                fill="#666"
                style={{
                    fontSize: "10px", // Adjust for better readability if necessary
                }}
                {...props}
            >
                {truncatedLabel}
            </text>
        </g>
    );
};
const CustomizedXAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
        <text x={x} y={y} dy={16} textAnchor="middle" fill="#202020">
            {payload.value}
        </text>
    );
};

const VerticalStackedBarChart = ({ data, withCompare }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const [bars, setBars] = useState([]);

    useEffect(() => {
        if (data?.length) {
            const barsSet = new Set();

            data.forEach((bar) => {
                Object.keys(bar).forEach((k) => {
                    if (k !== "name" && k !== "prev") barsSet.add(k);
                });
            });

            setBars(Array.from(barsSet));
        }
    }, [data]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                layout="vertical" // For vertical orientation
                data={data}
                margin={{
                    top: 20,
                    right: isArabic ? 30 : 0,
                    left: isArabic ? 0 : 30,
                    bottom: 5,
                }}
            >
                <CartesianGrid stroke="#EEEFF4" horizontal={false} />
                <XAxis
                    type="number"
                    tickLine={false}
                    stroke="#EEEFF4"
                    tick={<CustomizedXAxisTick />}
                    // domain={[0, 100]}
                    reversed={isArabic}
                />
                <YAxis
                    dataKey="name"
                    type="category"
                    tickLine={false}
                    // tick={{ fontSize: 10 }}
                    width={80}
                    orientation={isArabic ? "right" : "left"}
                    tick={(props) => <CustomTick {...props} />}
                />
                <Tooltip
                    content={<CustomTooltip withCompare={withCompare} />}
                    wrapperStyle={{ outline: "none" }}
                />
                {/* Stacked bars */}
                {bars?.map((b, i) => (
                    <Bar
                        dataKey={b}
                        stackId="a"
                        fill={constants.pieColors[i]}
                        barSize={20}
                    />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default VerticalStackedBarChart;
