import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomTextField } from "../../../common/components";
import { getUserStorage } from "../../../common/utils";
import { useMutation } from "@tanstack/react-query";
import { services } from "../services";
import { toast } from "react-toastify";

const ProfileRow = ({
    isEditMode,
    handleUpdate,
    onUpdate,
    control,
    name,
    placeholder,
    label,
    value,
    isNumber,
}) => {
    const { t } = useTranslation();

    return (
        <Stack gap={4}>
            <Box display="flex" alignItems="center">
                <Typography fontSize={14} flex={1} maxWidth={300}>
                    {label}
                </Typography>
                <Box flex={1}>
                    {isEditMode ? (
                        <Box width={400}>
                            <Controller
                                name={name}
                                control={control}
                                render={({ field }) => (
                                    <CustomTextField
                                        fullWidth
                                        placeholder={placeholder}
                                        variant="outlined"
                                        type={isNumber ? "number" : "text"}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                    ) : (
                        <Typography sx={{ color: "#64748B", fontSize: 14 }}>
                            {value}
                        </Typography>
                    )}
                </Box>

                <Box gap={4} display="flex">
                    {isEditMode ? (
                        <Button
                            variant="text"
                            sx={{
                                color: "#5BCFDE",
                                textTransform: "capitalize",
                            }}
                            onClick={handleUpdate}
                        >
                            {t("account.save")}
                        </Button>
                    ) : (
                        <Button
                            variant="text"
                            sx={{
                                color: "#5BCFDE",
                                textTransform: "capitalize",
                            }}
                            onClick={onUpdate}
                        >
                            {t("account.update")}
                        </Button>
                    )}
                </Box>
            </Box>
        </Stack>
    );
};

const Profile = () => {
    const { mutate: updateCurrentProfile } = useMutation({
        mutationFn: services.updateCurrentProfile,
        onSuccess: (res) => {
            if (!res.data.error) {
                toast.success(t("account.profile_updated"), {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    const user = getUserStorage();
    const { t } = useTranslation();

    const [updateList, setUpdateList] = useState({
        first_name: false,
        last_name: false,
        phone: false,
    });

    const { control, getValues } = useForm({
        defaultValues: {
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone,
        },
    });

    const handleFirstNameUpdate = () => {
        setUpdateList({
            ...updateList,
            first_name: false,
        });

        updateCurrentProfile({ first_name: getValues("first_name") });
    };

    const handleLastNameUpdate = () => {
        setUpdateList({
            ...updateList,
            last_name: false,
        });

        updateCurrentProfile({ last_name: getValues("last_name") });
    };

    const handlePhoneUpdate = () => {
        setUpdateList({
            ...updateList,
            phone: false,
        });

        updateCurrentProfile({ phone: getValues("phone") });
    };

    return (
        <Stack spacing={8} pb={40}>
            <Typography>{t("account.manage_profile")}</Typography>

            <Stack gap={4}>
                <ProfileRow
                    name="first_name"
                    value={getValues("first_name")}
                    label={t("account.first_name")}
                    placeholder={t("account.first_name")}
                    isEditMode={updateList.first_name}
                    onUpdate={() =>
                        setUpdateList({ ...updateList, first_name: true })
                    }
                    control={control}
                    handleUpdate={handleFirstNameUpdate}
                />
                <Divider />

                <ProfileRow
                    name="last_name"
                    value={getValues("last_name")}
                    label={t("account.last_name")}
                    placeholder={t("account.last_name")}
                    isEditMode={updateList.last_name}
                    onUpdate={() =>
                        setUpdateList({ ...updateList, last_name: true })
                    }
                    control={control}
                    handleUpdate={handleLastNameUpdate}
                />
                <Divider />

                <ProfileRow
                    name="phone"
                    value={getValues("phone")}
                    label={t("account.phone")}
                    placeholder={t("account.phone")}
                    isEditMode={updateList.phone}
                    onUpdate={() =>
                        setUpdateList({ ...updateList, phone: true })
                    }
                    control={control}
                    handleUpdate={handlePhoneUpdate}
                    isNumber
                />
            </Stack>
        </Stack>
    );
};

export { Profile };
