import { Box, Grid, Stack, Typography } from "@mui/material";
import { GridCard, OutlineButton } from "../../../../common/components";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { services } from "../../../manager/services";
import { useEffect, useMemo, useState } from "react";
import { SettingsOwnerCard } from "../Components/SettingsOwnerCards";
import { AddNew } from "./AddNew";

const Owners = () => {
    const { t } = useTranslation();

    const [allOwners, setAllOwners] = useState([]);
    const navigate = useNavigate();

    const { data, isFetching } = useQuery({
        queryKey: ["allOwners"],
        queryFn: services.getOwners,
    });

    const allOwnersResponse = useMemo(
        () =>
            data?.data?.response?.users.filter(
                (user) => user.role === 1 || user.role === 9
            ) || [],
        [data]
    );

    useEffect(() => {
        setAllOwners(allOwnersResponse);
    }, [allOwnersResponse]);

    const handleDelete = (id) => {
        setAllOwners(allOwners.filter((m) => m.id !== id));
    };

    useEffect(() => {
        window.pendo.pageLoad({
            name: "My Team Page",
        });
    }, []);

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <Stack flexDirection="row" justifyContent="space-between">
                <Typography variant="h5" fontSize={24}>
                    {t("settings.my_team")}
                </Typography>

                <AddNew />
            </Stack>

            <Grid container flexWrap="nowrap">
                <GridCard
                    fullWidth
                    boxStyles={{
                        minHeight: allOwners.length === 0 ? 500 : 1200,
                        height: "fit-content",
                    }}
                >
                    <Stack spacing={8}>
                        <Box>
                            <Typography variant="h5" fontSize={24}>
                                {t("settings.chain_owners")}
                            </Typography>
                        </Box>

                        <Grid
                            container
                            wrap="wrap"
                            width="100%"
                            justifyContent={allOwners.length === 0 && "center"}
                        >
                            {allOwners.length === 0 && !isFetching && (
                                <Typography alignSelf="center">
                                    {t("common.empty")}
                                </Typography>
                            )}
                            {allOwners?.map((owner) => {
                                return (
                                    <Grid xs={12} sm={6} md={4}>
                                        <SettingsOwnerCard
                                            key={owner.id}
                                            owner={owner}
                                            gutter
                                            onUserDelete={(id) =>
                                                handleDelete(id)
                                            }
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Stack>
                </GridCard>
            </Grid>
        </Stack>
    );
};

export { Owners };
