import {
    Autocomplete,
    Box,
    Button,
    Divider,
    Modal,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {
    CustomDateField,
    CustomDatePicker,
    CustomTextField,
    GridCardHeader,
} from "../../../../common/components";
import { useTranslation } from "react-i18next";
import { AreaChart } from "../components/AreaChart";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteOutlier } from "../components/DeleteOutlier";

const fakeData = [
    {
        name: "12:00 AM",
        "Actual Sales Data": 4000,
        Forcast: 2400,
        amt: 2400,
    },
    {
        name: "04:00 AM",
        "Actual Sales Data": 3000,
        Forcast: 1398,
        amt: 2210,
    },
    {
        name: "08:00 AM",
        "Actual Sales Data": 2000,
        Forcast: 1800,
        amt: 2290,
    },
    {
        name: "12:00 PM",
        "Actual Sales Data": 2780,
        Forcast: 1908,
        amt: 2000,
    },
    {
        name: "04:00 PM",
        "Actual Sales Data": 1890,
        Forcast: 2800,
        amt: 2181,
    },
    {
        name: "08:00 PM",
        Forcast: 2800,
        amt: 2500,
    },
    {
        name: "11:00 PM",
        Forcast: 4300,
        amt: 2100,
    },
];

const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -30%)",
    width: 510,
    height: 450,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 10,
    borderRadius: 4,
};

const timeOptions = Array.from({ length: 24 }, (_, index) => {
    const hour = String(index).padStart(2, "0");
    const time = `${hour}:00 ${index < 12 ? "AM" : "PM"}`;
    return {
        id: time,
        label: time,
        value: time,
    };
});

const CustomTimeField = styled(TextField)({
    "& label.Mui-focused": {
        //   color: 'green',
    },
    "& .MuiInput-underline:after": {
        //   borderBottomColor: 'green',
    },
    "& .MuiOutlinedInput-root": {
        height: 32,
        borderRadius: 4,
        fontSize: "15px",
        "& fieldset": {
            border: "1px solid #d0d0d0",
        },
        "&:hover fieldset": {
            borderColor: "#4d4d4d",
        },
        "&.Mui-focused fieldset": {
            border: "1px solid #4d4d4d",
        },
    },
    "& .MuiInputBase-input": {
        height: 8,
    },
});

const TotalSalesData = () => {
    const { t } = useTranslation();
    const [outliers, setOutliers] = useState([]);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [toBeDeletedOutlier, setToBeDeletedOutlier] = useState({});

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const handleDeleteOpen = (outlier) => {
        setIsDeleteOpen(true);
        setToBeDeletedOutlier(outlier);
    };
    const handleDeleteClose = () => setIsDeleteOpen(false);

    const { handleSubmit, control, getValues, setValue } = useForm({
        defaultValues: {
            outlier: "",
            date_from: "",
            time_from: "",
            date_to: "",
            time_to: "",
        },
    });

    const addNewOutlier = () => {
        const newOutlier = {
            name: getValues("name"),
            date_from: "",
            time_from: "",
            date_to: "",
            time_to: "",
            from: "04:00 AM",
            to: "08:00 AM",
            id: Math.random(),
        };

        setOutliers([...outliers, newOutlier]);
        handleClose();
    };

    const handleDeleteOutlier = (outlier) => {
        const newOutliers = outliers.filter(
            (item) => item.name !== outlier.name
        );
        setOutliers(newOutliers);

        handleDeleteClose();
    };

    const onSubmit = (values) => {};

    const [startTime, setStartTime] = useState([]);
    const [endTime, setEndTime] = useState([]);

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <GridCardHeader title="Total Sales Data" />
                {/* <GridCardHeader title={t("forcast.total_sales_data")} /> */}
            </Box>

            <Divider />

            <Stack>
                <Box width="100%" height="340px" px={2} py={10}>
                    <AreaChart
                        data={fakeData}
                        key1="Forcast"
                        key2="Actual Sales Data"
                        handleAddOutlier={handleOpen}
                        outliers={outliers}
                    />
                </Box>

                <Box display="flex" alignItems="center" gap={6} px={6}>
                    <Box display="flex" alignItems="center" gap={3}>
                        <Box
                            width="16px"
                            height="16px"
                            backgroundColor="#14C594"
                            borderRadius="4px"
                        />

                        <Typography fontSize="16px" color="#202020">
                            Actual Sales Data
                        </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" gap={3}>
                        <Box
                            width="16px"
                            height="16px"
                            backgroundColor="#DFDE7F"
                            borderRadius="4px"
                        />

                        <Typography fontSize="16px" color="#202020">
                            Forcast
                        </Typography>
                    </Box>

                    {outliers.map((outlier, index) => (
                        <Box display="flex" alignItems="center" gap={2}>
                            <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                gap={3}
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                    setValue("name", outlier.name);
                                    handleOpen();
                                }}
                            >
                                <Box
                                    width="16px"
                                    height="16px"
                                    backgroundColor="#5BCFDE"
                                    borderRadius="4px"
                                />

                                <Typography fontSize="16px" color="#202020">
                                    {outlier.name}
                                </Typography>
                            </Box>
                            <CloseIcon
                                sx={{
                                    color: "red",
                                    fontSize: 17,
                                    cursor: "pointer",
                                }}
                                onClick={() => handleDeleteOpen(outlier)}
                            />
                        </Box>
                    ))}
                </Box>
            </Stack>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableAutoFocus
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={style}>
                        <Stack spacing={10} flex={1}>
                            <Box
                                display="flex"
                                alignItems="center"
                                gap={2}
                                justifyContent="center"
                            >
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                    align="center"
                                >
                                    Add Outlier
                                </Typography>
                            </Box>

                            <Stack spacing={5}>
                                <Stack spacing={3}>
                                    <Box width={250}>
                                        <Typography
                                            fontWeight="bold"
                                            color="#06262D"
                                            fontSize={14}
                                        >
                                            Outlier
                                        </Typography>
                                    </Box>

                                    <Box width={420}>
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={t(
                                                        "settings.prompt_name"
                                                    )}
                                                    variant="outlined"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Stack>

                                <Box display="flex" gap={6}>
                                    <Stack spacing={3}>
                                        <Typography
                                            fontWeight="bold"
                                            color="#06262D"
                                            fontSize={14}
                                        >
                                            Date From
                                        </Typography>

                                        <CustomDatePicker />
                                    </Stack>

                                    <Stack spacing={3}>
                                        <Typography
                                            fontWeight="bold"
                                            color="#06262D"
                                            fontSize={14}
                                        >
                                            Time From
                                        </Typography>

                                        <Box width={210}>
                                            <Autocomplete
                                                size="small"
                                                value={startTime}
                                                onChange={(e, value) =>
                                                    setStartTime(value)
                                                }
                                                disablePortal
                                                options={timeOptions}
                                                sx={{ width: 210 }}
                                                disableClearable
                                                renderInput={(params) => (
                                                    <CustomTimeField
                                                        {...params}
                                                        sx={{ height: 32 }}
                                                        InputLabelProps={{
                                                            style: {
                                                                color: "#6B7280",
                                                            },
                                                        }}
                                                        placeholder="00:00"
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Stack>
                                </Box>

                                <Box display="flex" gap={6}>
                                    <Stack spacing={3}>
                                        <Typography
                                            fontWeight="bold"
                                            color="#06262D"
                                            fontSize={14}
                                        >
                                            Date To
                                        </Typography>

                                        <CustomDatePicker />
                                    </Stack>

                                    <Stack spacing={3}>
                                        <Typography
                                            fontWeight="bold"
                                            color="#06262D"
                                            fontSize={14}
                                        >
                                            Time To
                                        </Typography>

                                        <Box width={210}>
                                            <Autocomplete
                                                size="small"
                                                value={endTime}
                                                onChange={(e, value) =>
                                                    setEndTime(value)
                                                }
                                                disablePortal
                                                options={timeOptions}
                                                sx={{ width: 210 }}
                                                disableClearable
                                                renderInput={(params) => (
                                                    <CustomTimeField
                                                        {...params}
                                                        sx={{ height: 32 }}
                                                        InputLabelProps={{
                                                            style: {
                                                                color: "#6B7280",
                                                            },
                                                        }}
                                                        placeholder="00:00"
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Stack>

                        <Box
                            display="flex"
                            mt={10}
                            justifyContent="flex-end"
                            gap={5}
                        >
                            <Button
                                variant="outlined"
                                sx={{
                                    textTransform: "capitalize",
                                    borderRadius: "6px",
                                }}
                                onClick={handleClose}
                            >
                                {t("common.cancel")}
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    textTransform: "capitalize",
                                    borderRadius: "6px",
                                    width: "fit-content",
                                    color: "white",
                                    backgroundColor: "#5BCFDE",
                                    border: "1px solid #EEEFF4",
                                    "&:hover": {
                                        border: "1px solid #EEEFF4",
                                        backgroundColor: "#5BCFDE",
                                    },
                                }}
                                onClick={addNewOutlier}
                            >
                                Add
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Modal>

            {/* Delete Outlier Modal */}
            {isDeleteOpen && (
                <DeleteOutlier
                    outlier={toBeDeletedOutlier}
                    handleClose={handleDeleteClose}
                    handleDeleteOutlier={handleDeleteOutlier}
                />
            )}
        </Stack>
    );
};

export { TotalSalesData };
