import { Box, Divider, Stack } from "@mui/material";
import {
    GridCardFlatLabels,
    GridCardHeader,
} from "../../../../common/components";
import { useTranslation } from "react-i18next";
import { VerticalBarChart } from "./VerticalBarChart";
import { NewVerticalBarChart } from "./NewVerticalChart";

const BranchSales = ({ data }) => {
    const { t } = useTranslation();

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <GridCardHeader title={t("overview.branch_sales")} />

                {/* {!isMobileView && (
                    <Box
                        flex={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        gap={2}
                    >
                        {data?.map((label) => (
                            <Box
                                display="flex"
                                alignItems="center"
                                gap={3}
                                key={label.fill}
                            >
                                <Box
                                    backgroundColor={label.fill}
                                    borderRadius="50%"
                                    width="16px"
                                    height="16px"
                                />

                                <Typography fontSize="14px">
                                    {label.name}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                )} */}
            </Box>

            <Divider />

            <Stack>
                <GridCardFlatLabels data={data} isFlat />

                <Box width="100%" height="300px" px={6}>
                    <NewVerticalBarChart data={data} />
                </Box>
            </Stack>
        </Stack>
    );
};

export { BranchSales };
