import { Stack, Typography, Box } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useTranslation } from "react-i18next";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "white",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: "0 0 0 2px rgb(238, 239, 244)",
        fontSize: 11,
    },
}));

const CustomTooltip = ({ item }) => {
    return (
        <Stack backgroundColor="white" width="100%">
            <Typography color="#818992" fontSize="14px" fontWeight="bold">
                {item.value} %
            </Typography>

            <div
                style={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        backgroundColor: item.fill,
                        width: "16px",
                        height: "16px",
                    }}
                />

                <Typography color="#06262D" fontSize="12px">
                    {item.name.split("_")[0]}
                </Typography>
            </div>
        </Stack>
    );
};

export const NewVerticalBarChart = ({ data, height = "250px" }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    return (
        <Box width="100%" height={height}>
            <Box
                width="100%"
                height={height}
                border="1px solid rgb(238, 239, 244)"
                position="relative"
            >
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    height="100%"
                    position="absolute"
                    zIndex="1"
                >
                    {[0, 20, 40, 60, 80, 100].map((value) => (
                        <Box
                            width="1px"
                            backgroundColor="rgb(238, 239, 244)"
                            height="100%"
                        />
                    ))}
                </Box>

                <Box
                    width="calc(100% + 13px)"
                    position="relative"
                    zIndex={2}
                    overflow="scroll"
                    height="100%"
                    paddingY={2}
                    paddingRight={isArabic ? 0 : "6px"}
                    paddingLeft={isArabic ? "6px" : 0}
                    sx={{
                        overflowX: "hidden",
                        scrollbarWidth: "thin", // For Firefox
                        "&::-webkit-scrollbar": {
                            width: 8,
                            borderRadius: "3px",
                        },
                        "&::-webkit-scrollbar-track": {
                            background: "white",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "#EDEFF3",
                            borderRadius: "3px",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                            background: "#EDEFF3",
                            borderRadius: "3px",
                        },
                    }}
                >
                    {data
                        ?.sort((a, b) => b.value - a.value)
                        .map((item, i) => {
                            const isLast = i === data.length - 1;

                            return (
                                <LightTooltip
                                    title={<CustomTooltip item={item} />}
                                    sx={{
                                        backgroundColor: "white", // Remove padding
                                        // Add more custom styles as needed
                                    }}
                                >
                                    <Box
                                        width="100%"
                                        sx={{
                                            "&:hover": {
                                                boxShadow:
                                                    "0 0 0 1px rgb(238, 239, 244)",
                                            },
                                        }}
                                    >
                                        <Box
                                            height="12px"
                                            width={`${item.value || 0}%`}
                                            backgroundColor={item.fill}
                                            marginBottom={isLast ? 0 : 2}
                                            // borderRadius={
                                            //     !isArabic
                                            //         ? "0 8px 8px 0"
                                            //         : "8px 0 0 8px"
                                            // }
                                        ></Box>
                                    </Box>
                                </LightTooltip>
                            );
                        })}
                </Box>
            </Box>

            <Box width="100%" display="flex" justifyContent="space-between">
                {[0, 20, 40, 60, 80, 100].map((value) => (
                    <Typography color="rgb(32, 32, 32)">{value}</Typography>
                ))}
            </Box>
        </Box>
    );
};
