import { Divider, Stack, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridCardHeader, SimpleBar } from "../../../../common/components";

const TrendingHours = ({ data }) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ overflowX: "scroll", overflowY: "hidden" }}>
            <Stack minWidth="750px">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    padding={6}
                    height={74}
                >
                    <GridCardHeader title={t("overview.trending_hours")} />
                </Box>

                <Divider />

                <Box padding={6}>
                    <SimpleBar
                        data={data}
                        container={{ width: "100%", height: 250 }}
                        margin={{}}
                    />
                </Box>
            </Stack>
        </Box>
    );
};

export { TrendingHours };
