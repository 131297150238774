import { Box, Stack, Typography } from "@mui/material";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { SimpleLineChart } from "../../../../common/components/Charts/SimpleLineChart/SimpleLineChart";
import { useTranslation } from "react-i18next";

const PerformanceLineChart = ({ title, data, periods, yMax }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    return (
        <Stack gap={12}>
            <Box
                display="flex"
                alignItems="baseline"
                justifyContent="space-between"
            >
                <Stack spacing={1}>
                    <Typography
                        fontSize={18}
                        fontWeight="bold"
                        textTransform="capitalize"
                    >
                        {title}
                    </Typography>

                    {/* <Box display="flex" alignItems="baseline" gap={1}>
                        <Typography fontSize={25} fontWeight="bold">
                            {periods?.currentTotal}
                        </Typography>

                        <Typography fontSize={14} sx={{ color: "#6B7280" }}>
                            From {periods?.prevTotal}
                        </Typography>
                    </Box> */}
                </Stack>

                <Box display="flex" gap={2}>
                    <Box
                        // backgroundColor="#f2f2f2"
                        padding={1}
                        borderRadius="50%"
                        width="26px"
                        height="26px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {/* {isCurrentHigher ? <ArrowUpwardIcon sx={{ fontSize: 18 }} /> : <ArrowDownwardIcon sx={{ fontSize: 18 }} />} */}
                        {/* <ArrowUpwardIcon sx={{ fontSize: 18 }} /> */}
                    </Box>

                    {/* <Box paddingY={1}>{periods?.percentage}%</Box> */}
                </Box>

                <Stack alignItems="flex-end"></Stack>
            </Box>

            <Stack position="relative" spacing={4}>
                <Box>
                    <SimpleLineChart
                        data={data}
                        withGrid={false}
                        xKey="total sales"
                        yAxisConfigs={{
                            domain: [0, yMax || 1000],
                            orientation: isArabic ? "right" : "left",
                        }}
                        xAxisConfigs={{
                            domain: [0, 100],
                            reversed: isArabic,
                        }}
                    />
                </Box>

                <Box
                    display="flex"
                    alignItems="baseline"
                    justifyContent="space-between"
                    position="relative"
                    top={-10}
                    paddingLeft={15}
                >
                    <Typography variant="body2">
                        {t("common.current_year")}
                    </Typography>
                    <Typography variant="body2">{t("common.today")}</Typography>
                </Box>
            </Stack>
        </Stack>
    );
};

export { PerformanceLineChart };
