import { useState } from "react";
import { Box, Typography, List, ListItemButton, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const SideNavItemExtend = ({
    iconPath,
    to,
    label,
    subItems = [],
    isActive,
    isDisabled,
    isSoon,
    isBeta,
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();

    const handleToggle = () => {
        if (!isDisabled) {
            setIsExpanded((prev) => !prev);
        }
    };

    return (
        <Box
            sx={{
                cursor: isDisabled ? "not-allowed" : "pointer",
                width: "100%",
                paddingX: 2, // Added spacing around the main item
                boxSizing: "border-box",
            }}
        >
            {/* Main Item */}
            <Box
                padding={2}
                borderRadius={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                backgroundColor={isActive ? "#02161A" : "transparent"}
                onClick={handleToggle}
                sx={{
                    "&:hover": {
                        backgroundColor: "#031F24",
                        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)", // Subtle shadow on hover
                    },
                    transition:
                        "background-color 0.3s ease, box-shadow 0.3s ease",
                }}
            >
                <Box display="flex" gap={3} alignItems="center">
                    <img src={iconPath} alt="sideNavBarIcon" />

                    <Typography
                        fontSize={18}
                        color={
                            isDisabled
                                ? "#999999"
                                : isActive
                                ? "#5BCFDE"
                                : "#FFFFFF"
                        }
                        textTransform="capitalize"
                    >
                        {label}
                    </Typography>

                    {isSoon && (
                        <Box
                            backgroundColor="#3498db"
                            borderRadius="3px"
                            padding="0px 4px"
                            color="white"
                            fontSize="12px"
                        >
                            Soon
                        </Box>
                    )}
                    {isBeta && (
                        <Box
                            backgroundColor="#1abc9c"
                            borderRadius="3px"
                            padding="0px 4px"
                            color="white"
                            fontSize="12px"
                        >
                            Beta
                        </Box>
                    )}
                </Box>

                {!!subItems.length &&
                    !isDisabled &&
                    (isExpanded ? (
                        <ExpandLessIcon
                            sx={{
                                color: "white",
                                transition: "transform 0.3s ease",
                            }}
                        />
                    ) : (
                        <ExpandMoreIcon
                            sx={{
                                color: "white",
                                transition: "transform 0.3s ease",
                            }}
                        />
                    ))}
            </Box>

            {/* Nested Items */}
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <List sx={{ width: "100%", paddingX: 0, marginTop: 1 }}>
                    {subItems.map((subItem) => (
                        <ListItemButton
                            key={subItem.to}
                            sx={{
                                paddingY: 1.5,
                                borderRadius: "4px",
                                backgroundColor: "transparent",
                                width: "calc(100% - 32px)", // Ensures full hover width
                                marginLeft: "16px", // Align nested items
                                "&:hover": {
                                    backgroundColor: "#02161A",
                                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
                                },
                            }}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent parent click
                                subItem.onClick
                                    ? subItem.onClick()
                                    : navigate(subItem.to);
                            }}
                            disabled={subItem.isDisabled}
                        >
                            <Box
                                display="flex"
                                gap={2}
                                alignItems="center"
                                width="100%"
                            >
                                <img
                                    width="22px"
                                    height="24px"
                                    src={subItem.iconPath}
                                    alt="icon"
                                />
                                <Typography
                                    fontSize={16}
                                    color="#FFFFFF" // White color for nested items
                                    textAlign={isArabic ? "right" : "left"}
                                >
                                    {subItem.label}
                                </Typography>
                            </Box>
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </Box>
    );
};
