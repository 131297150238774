import { useRouteError } from "react-router-dom";

export default function ErrorPage() {

  return (
    <div id="error-page" style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ textAlign: 'center' }}>
      <h1>Oops!</h1>
      <h3>Page Not Found</h3>
      </div>
    </div>
  );
}
