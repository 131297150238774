import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { uniqBy } from "lodash";
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    // IconButton,
    Pagination,
    // Popover,
    Stack,
    Typography,
} from "@mui/material";
// import IosShareIcon from "@mui/icons-material/IosShare";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import FilterListIcon from "@mui/icons-material/FilterList";
import GridViewIcon from "@mui/icons-material/GridView";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import {
    ButtonSelect,
    FilterSelect,
    RangePicker,
    SearchField,
} from "../../../../common/components";
import { GridView } from "./GridView";
import { TableView } from "./TableView";
import { services } from "../../services";
import { format } from "date-fns";
import { getToken, getUserStorage } from "../../../../common/utils";
import { constants } from "../../../../config/constants";
import { toast } from "react-toastify";
import { ExportButton } from "../../../../common/components/Export/Export";
import { useNavigate, useSearchParams } from "react-router-dom";

const filterItems = [
    {
        value: "Class",
        label: "Class",
        key: "class",
        subItems: ["Star", "Horse", "Turtle", "Puzzle"],
    },
    {
        value: "Categories",
        label: "Categories",
        key: "category",
        subItems: ["Pizza", "Burger", "Pasta", "Sandwiches"],
    },
    // { label: 'Top products', value: 'Top products', key: 'profitablity' }
];

const sortByItems = [
    { label: "Price", value: "price", key: "price" },
    { label: "Cost Sales", value: "sales", key: "sales" },
    { label: "Quantity", value: "quantity", key: "quantity" },
];

export const useDefaultFilters = () => {
    const [searchParams] = useSearchParams();

    const filtersValues = useMemo(() => {
        try {
            return JSON.parse(searchParams.get("filters"));
        } catch (e) {
            return {};
        }
    }, [searchParams]);

    return { filtersValues };
};

const Products = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const { filtersValues } = useDefaultFilters();

    const user = getUserStorage();
    const isOwner = user.role === 1;
    const userBranches =
        isOwner && !user.manager_view ? user?.merchant?.branches : user?.branch;
    const allBranches = userBranches.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
    }));

    const isGroupOwner = user.role === 9;

    const userGroups = user?.group;
    const allGroups =
        userGroups?.map((group) => ({
            id: group.merchant_id,
            label: group.name,
        })) || [];
    const allGroupsOption = { id: "all", label: t("common.all_groups") };
    const [selectedGroup, setSelectedGroup] = useState(allGroupsOption.id);

    const isManagerView = user.manager_view;
    const allBranchesOption = { id: "all", label: t("common.all_branches") };

    const [selectedBranch, setSelectedBranch] = useState(
        filtersValues?.selectedBranch
            ? filtersValues?.selectedBranch
            : isManagerView
            ? allBranches[0].id
            : allBranchesOption.id
    );

    const [allProducts, setAllProducts] = useState([]);
    const [menuProducts, setMenuProducts] = useState([]);

    const [overviewPDF, setOverviewPDF] = useState({});
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

    const [period, setPeriod] = useState({
        startDate: filtersValues?.period?.startDate
            ? filtersValues?.period?.startDate
            : null,
        endDate: filtersValues?.period?.endDate
            ? filtersValues?.period?.endDate
            : format(new Date(), "y-M-d"),
    });

    const { mutate: getMenuProducts, isLoading } = useMutation({
        mutationFn: services.getMenuEngineeringProducts,
        onSuccess: (res) => {
            if (res.data.response && !res.data.error) {
                const response = res.data.response;
                setMenuProducts(response);
                setAllProducts(response);

                setOverviewPDF({
                    menu_products: response,
                    all_products: response,
                    period,
                });
            }

            if (res.data.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    const filterItems = [
        {
            value: "Class",
            label: t("common.class"),
            key: "class",
            subItems: ["Star", "Horse", "Turtle", "Puzzle"],
        },
        {
            value: "Categories",
            label: t("overview.categories"),
            key: "category",
            isDisabled: !Array.from(new Set(allProducts.map((p) => p.category)))
                .length,
            subItems: Array.from(new Set(allProducts.map((p) => p.category))),
        },
    ];

    const [searchValue, setSearchValue] = useState(
        filtersValues?.searchValue ? filtersValues.searchValue : ""
    );

    const [order, setOrder] = useState(filtersValues?.order || "desc");
    const [orderBy, setOrderBy] = useState(filtersValues?.orderBy || "price");

    const [page, setPage] = useState(
        filtersValues?.page ? filtersValues?.page : 0
    );
    const rowsPerPage = 10;
    const count = menuProducts.length;
    const pagesCount = Math.ceil(count / rowsPerPage);

    const [filterList, setFilterList] = useState(
        filtersValues?.filterList ? filtersValues.filterList : []
    );

    const [isTableView, setIsTableView] = useState(
        filtersValues?.isTableView ? filtersValues?.isTableView : false
    );

    const handlePageChange = (newPage) => setPage(newPage);

    const paginatedItems = stableSort(
        menuProducts,
        getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleFilter = ({ filterParam }) => {
        const isAddedBefore = filterList.some(
            (fp) => fp.value === filterParam.value
        );

        if (isAddedBefore)
            setFilterList(
                filterList.filter((f) => f.value !== filterParam.value)
            );
        else setFilterList(uniqBy([...filterList, filterParam]));
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleDatesChange = useCallback(({ startDate, endDate }) => {
        if (startDate !== "1970-1-1") {
            setPeriod({ startDate, endDate });
        }
    }, []);

    const handleBranchChange = useCallback((e) => {
        const newSelectedBranchValue = e.target.value;
        setSelectedBranch(newSelectedBranchValue);
    }, []);

    const handleGroupChange = (e) => {
        let newSelectedGroupValue = e.target.value;
        const lastElement =
            newSelectedGroupValue[newSelectedGroupValue.length - 1];
        if (lastElement === "all" || !newSelectedGroupValue.length)
            newSelectedGroupValue = "all";
        else
            newSelectedGroupValue = newSelectedGroupValue.filter(
                (i) => i !== "all"
            );

        setSelectedGroup(newSelectedGroupValue);
    };

    useEffect(() => {
        if (period.startDate) {
            getMenuProducts({
                from_date: period.startDate,
                till_date: period.endDate,
                [`${isGroupOwner ? "merchant_filter" : "branch_id"}`]:
                    isGroupOwner ? selectedGroup : selectedBranch,
            });
        }
    }, [
        period.startDate,
        period.endDate,
        getMenuProducts,
        selectedBranch,
        selectedGroup,
        isGroupOwner,
    ]);

    // function filterProducts

    const filterProducts = useCallback(() => {
        const selectedFilters = { class: [], category: [], profitablity: [] };
        // biome-ignore lint/complexity/noForEach: <explanation>
        filterList.forEach(
            (filter) =>
                (selectedFilters[filter.key] = [
                    ...selectedFilters[filter.key],
                    filter.value,
                ])
        );

        if (allProducts.length) {
            setPage(0);
            setMenuProducts(
                allProducts?.filter((product) => {
                    const isClassFilterShouldApply =
                        !!selectedFilters.class.length;
                    const isCategoryFilterShouldApply =
                        !!selectedFilters.category.length;
                    const isSearchShouldApply = !!searchValue.length;
                    const filtersState = {
                        class: true,
                        category: true,
                        searchQuery: true,
                    };

                    if (isClassFilterShouldApply)
                        filtersState.class = selectedFilters.class.includes(
                            product.class
                        );
                    if (isCategoryFilterShouldApply)
                        filtersState.category =
                            selectedFilters.category.includes(product.category);
                    if (isSearchShouldApply)
                        filtersState.searchQuery = product.product_name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase());

                    if (Object.values(filtersState).every((e) => e === true))
                        return true;
                    return false;
                })
            );
        }
    }, [allProducts, filterList, searchValue]);

    useEffect(() => {
        filterProducts();
    }, [filterList, searchValue, filterProducts]);

    useEffect(() => {
        window.pendo.pageLoad({
            name: "Menu Engineering Products Page",
        });
    }, []);

    useEffect(() => {
        const appliedFilters = {
            period,
            selectedBranch,
            order,
            orderBy,
            filterList,
            isTableView,
            page,
            searchValue,
        };

        const newParams = new URLSearchParams(searchParams);
        newParams.set("filters", JSON.stringify(appliedFilters)); // Add or update a query param

        // Push the updated query params to the URL
        navigate(`?${newParams.toString()}`, { replace: false });
    }, [
        page,
        period,
        selectedBranch,
        order,
        orderBy,
        filterList,
        isTableView,
        navigate,
        searchParams,
        searchValue,
    ]);

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <Grid container flexWrap="nowrap">
                <Grid
                    item
                    xs={6}
                    marginRight={isArabic ? 0 : 8}
                    marginLeft={isArabic ? 8 : 0}
                >
                    <Stack direction="row" alignItems="baseline" columnGap={3}>
                        <Typography fontSize={30} fontWeight="bold">
                            {t("sales.products")}
                        </Typography>

                        <Typography fontSize={16} color="#06262D">
                            {menuProducts.length} {t("common.product_2")}
                        </Typography>
                    </Stack>
                </Grid>

                <Grid item xs={6}>
                    <SearchField
                        value={searchValue}
                        placeholder={t("menu.search_product")}
                        onChange={(val) => setSearchValue(val)}
                    />
                </Grid>
            </Grid>

            <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center" gap={3}>
                    <RangePicker
                        onChange={(value) => handleDatesChange(value)}
                        isLoading={isLoading}
                    />

                    {/* {isLoading && (
                        <CircularProgress color="primary" size={20} />
                    )} */}
                </Box>

                <Box display="flex" alignItems="center" gap={4}>
                    <Box
                        sx={{
                            border: "1px solid #EEEFF4",
                            width: 36,
                            height: 36,
                            borderRadius: 4,
                            backgroundColor: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => setIsTableView(!isTableView)}
                    >
                        {isTableView ? <GridViewIcon /> : <MenuIcon />}
                    </Box>
                    <FilterSelect
                        label={t("common.filter")}
                        items={filterItems}
                        onChange={(value) =>
                            handleFilter({ filterParam: value })
                        }
                        icon={FilterListIcon}
                        filterList={filterList}
                        isLoading={isLoading}
                    />

                    <FilterSelect
                        label="Sort by"
                        items={sortByItems}
                        onChange={({ value }) => handleRequestSort(value)}
                        icon={SwapVertIcon}
                        isLoading={isLoading}
                    />

                    {!isManagerView && !isGroupOwner && (
                        <ButtonSelect
                            value={selectedBranch}
                            onChange={handleBranchChange}
                            options={[allBranchesOption, ...allBranches]}
                            isLoading={isLoading}
                        />
                    )}

                    {isGroupOwner && (
                        <ButtonSelect
                            value={selectedGroup}
                            onChange={handleGroupChange}
                            options={[allGroupsOption, ...allGroups]}
                            isLoading={isLoading}
                            isMulti
                        />
                    )}

                    <ExportButton
                        overviewPDF={{
                            ...overviewPDF,
                            filterList,
                            order,
                            orderBy,
                            isArabic,
                            printDate: `${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`,
                        }}
                        pageName="MenuProductsPDF"
                        isLoading={isLoading}
                    />

                    {/* <IconButton
                        color="primary"
                        aria-label="add to shopping cart"
                    >
                        <IosShareIcon />
                    </IconButton> */}
                </Box>
            </Box>

            {!!filterList.length && (
                <Box display="flex" alignItems="center" gap={4}>
                    <Typography variant="body2" sx={{ color: "#6d7482" }}>
                        FILTERS
                    </Typography>

                    <Divider orientation="vertical" flexItem />

                    {filterList.map((filterParam) => (
                        <Chip
                            key={filterParam.value}
                            label={filterParam.value}
                            variant="outlined"
                            sx={{ backgroundColor: "white" }}
                            deleteIcon={<CloseIcon />}
                            onDelete={() => handleFilter({ filterParam })}
                        />
                    ))}
                </Box>
            )}

            {isLoading && (
                <Box width="100%" textAlign="center">
                    <CircularProgress size={20} />
                </Box>
            )}

            <Box minHeight={400}>
                {!paginatedItems.length && !isLoading && (
                    <Typography variant="body2" align="center">
                        {t("common.empty")}
                    </Typography>
                )}

                {isTableView ? (
                    <TableView items={paginatedItems} />
                ) : (
                    <GridView items={paginatedItems} />
                )}
            </Box>

            <Box display="flex">
                <Box
                    display="flex"
                    gap={4}
                    width="100%"
                    justifyContent="space-between"
                >
                    <Button
                        variant="outlined"
                        sx={{
                            borderRadius: 4,
                            borderColor: "#EEEFF4",
                            textTransform: "capitalize",
                            backgroundColor: "white",
                            "&:hover": {
                                backgroundColor: "white",
                                borderColor: "#EEEFF4",
                            },
                        }}
                        onClick={() => handlePageChange(page - 1)}
                        disabled={page === 0}
                    >
                        {t("common.previous")}
                    </Button>
                    <Pagination
                        count={pagesCount}
                        hideNextButton
                        hidePrevButton
                        variant="outlined"
                        shape="rounded"
                        page={page + 1}
                        onChange={(e, val) => handlePageChange(val - 1)}
                        sx={{
                            "& .Mui-selected": {
                                borderColor: "lightBlue",
                                color: "lightBlue",
                                backgroundColor: "white",
                                ":hover": {
                                    backgroundColor: "white",
                                },
                            },
                            "& button": {
                                backgroundColor: "white",
                                ":hover": {
                                    backgroundColor: "white",
                                },
                            },
                        }}
                    />

                    <Button
                        variant="outlined"
                        sx={{
                            borderRadius: 4,
                            borderColor: "#EEEFF4",
                            textTransform: "capitalize",
                            backgroundColor: "white",
                            "&:hover": {
                                backgroundColor: "white",
                                borderColor: "#EEEFF4",
                            },
                        }}
                        disabled={
                            paginatedItems.length === 0 ||
                            Math.ceil(count / rowsPerPage) === page + 1
                        }
                        onClick={() => handlePageChange(page + 1)}
                    >
                        {t("common.next")}
                    </Button>
                </Box>
            </Box>
        </Stack>
    );
};

export { Products };
