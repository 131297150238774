import { Box, Divider, Stack, Typography } from "@mui/material";
import { VictoryLabel, VictoryPie } from "victory";
import { useTranslation } from "react-i18next";
import { constants } from "../../../../config/constants";
import { GridCardFlatLabels } from "../../../../common/components";

const CustomersCountsData = ({ data }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const emptyChart = [
        {
            name: t("common.empty"),
            value: 100,
            fill: "#E3E3E3",
            outerRadius: 200,
        },
    ];

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Typography fontWeight="bold" fontSize={20} color="#202020">
                    {t("customer.new_customers")} /{" "}
                    {t("customer.retained_customers")}
                </Typography>
            </Box>

            <Divider />

            <Box>
                <Box display="flex" alignItems="center">
                    <Box width="100%" height="100%" position="relative">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            dir="ltr"
                            position="relative"
                            left="50px"
                        >
                            <Box width="50%" maxWidth={300} height="100%">
                                <svg
                                    viewBox="0 0 550 320"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        padding: "0",
                                        marginTop: "10px",
                                        overflow: "visible",
                                    }}
                                >
                                    <VictoryPie
                                        standalone={false}
                                        x="name"
                                        y="value"
                                        data={data?.length ? data : emptyChart}
                                        colorScale={
                                            data?.length
                                                ? constants.pieColors
                                                : ["#E3E3E3"]
                                        }
                                        radius={47}
                                        innerRadius={52}
                                        labelRadius={({ datum }) =>
                                            datum.outerRadius + 80
                                        }
                                        labels={({ datum }) => ""}
                                        style={{
                                            labels: {
                                                fontSize: 26,
                                            },
                                        }}
                                    />

                                    <VictoryPie
                                        standalone={false}
                                        x="name"
                                        y="value"
                                        data={data?.length ? data : emptyChart}
                                        colorScale={
                                            data?.length
                                                ? constants.pieColors
                                                : ["#E3E3E3"]
                                        }
                                        radius={({ datum }) =>
                                            datum.outerRadius
                                        }
                                        labelRadius={80}
                                        innerRadius={55}
                                        style={{
                                            labels: {
                                                fontSize: 24,
                                                color: "white",
                                                fill: "white",
                                            },
                                        }}
                                        labels={({ datum }) =>
                                            `${datum.value}%`
                                        }
                                    />
                                </svg>
                            </Box>
                        </Box>

                        <Box
                            position="relative"
                            top="45px"
                            display="flex"
                            justifyContent="center"
                            width="100%"
                        >
                            <GridCardFlatLabels
                                data={data?.length ? data : emptyChart}
                                isFlat
                                isCenter
                                withPercentage={false}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Stack>
    );
};

export { CustomersCountsData };
