import {
    Box,
    Divider,
    Stack,
    Typography,
    Select,
    MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import {
    CustomSelectOutline,
    GridCardFlatLabels,
} from "../../../../../common/components";
import { constants } from "../../../../../config/constants";
import VerticalStackedBarChart from "./StackedVerticalChart";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const MultiMeetricChart = ({ data, isCompare, isPDF }) => {
    const { t } = useTranslation();
    const [meetricIndicator, setMeetricIndicator] = useState("total_orders");
    const [chartData, setChartData] = useState(null);

    const [bars, setBars] = useState([]);

    useEffect(() => {
        if (chartData?.length) {
            const barsSet = new Set();

            chartData.forEach((bar) => {
                Object.keys(bar).forEach((k) => {
                    if (k !== "name" && k !== "prev") barsSet.add(k);
                });
            });

            setBars(Array.from(barsSet));
        }
    }, [chartData]);

    const labelsData = useMemo(
        () =>
            bars.map((labelName, index) => ({
                name: labelName,
                fill: constants.pieColors[index],
            })),
        [bars]
    );

    useEffect(() => {
        if (!data) return;
        setChartData(data[`${meetricIndicator}`]);
    }, [data, meetricIndicator]);

    const handleMeetricsUpdate = (e) => {
        setMeetricIndicator(e.target.value);

        setChartData(chartData);
    };

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Stack gap={4} flexDirection="row" alignItems="center">
                    <Typography fontWeight="bold" fontSize={20} color="#202020">
                        {t(`overview.${meetricIndicator}`)}
                    </Typography>

                    <Stack gap={1} flexDirection="row">
                        <Box
                            backgroundColor="#f2f2f2"
                            padding={1}
                            borderRadius="50%"
                            width="26px"
                            height="26px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <ArrowUpwardIcon sx={{ fontSize: 18 }} />
                        </Box>
                    </Stack>
                </Stack>

                {!isPDF && (
                    <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={meetricIndicator}
                        onChange={(e) => handleMeetricsUpdate(e)}
                        input={<CustomSelectOutline />}
                    >
                        <MenuItem value="total_sales">
                            {t("overview.total_sales")}
                        </MenuItem>
                        <MenuItem value="avg_basket_size">
                            {t("overview.avg_basket_size")}
                        </MenuItem>
                        <MenuItem value="total_orders">
                            {t("overview.total_orders")}
                        </MenuItem>
                    </Select>
                )}
            </Box>

            <Divider />

            <Stack>
                <GridCardFlatLabels
                    data={labelsData}
                    isFlat
                    withPercentage={false}
                />

                <Box width="100%" height="300px" px={6}>
                    <VerticalStackedBarChart
                        data={chartData}
                        withCompare={isCompare}
                    />
                </Box>
            </Stack>
        </Stack>
    );
};

export { MultiMeetricChart };
