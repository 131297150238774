import { Box, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { DoughnutChart } from "../../../../common/components";

export const options = {
    is3D: true,
    legend: { position: "top", alignment: "start", maxLines: 4 },
    slices: {
        0: { offset: 0.05, color: "#bdbdbd" },
        1: { offset: 0.05, color: "#454545" },
        2: { offset: 0.05, color: "#b8b1b1" },
        3: { offset: 0.05, color: "#dbdbdb" },
        4: { offset: 0.05, color: "#b8b1b1" },
        5: { offset: 0.05, color: "#dbdbdb" },
        6: { offset: 0.05, color: "#afafaf" },
        7: { offset: 0.05, color: "#575757" },
    },
    chartArea: { width: "95%", height: "60%" },
};

const ChannelsSales = ({ title = "Sales Channels", data }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Typography fontWeight="bold" fontSize={20} color="#202020">
                    {t("sales.sales_channels")}
                </Typography>
            </Box>

            <Divider />

            <Box display="flex" alignItems="center">
                <Stack
                    spacing={3}
                    flex={1}
                    paddingX={12}
                    paddingY={3}
                    maxHeight="330px"
                    flexWrap="wrap"
                    width="50%"
                >
                    {data?.map((label) => (
                        <Box display="flex" alignItems="center" gap={3}>
                            <Box
                                backgroundColor={label.fill}
                                borderRadius="50%"
                                width="16px"
                                height="16px"
                            />

                            <Typography fontSize="14px">
                                {label.name}{" "}
                                {`${
                                    isArabic
                                        ? "%" + label.value
                                        : label.value + "%"
                                }`}
                            </Typography>
                        </Box>
                    ))}
                </Stack>

                <Box
                    width="350px"
                    height="100%"
                    pr={8}
                    position="relative"
                    right="-44px"
                >
                    <DoughnutChart
                        data={data}
                        pieProps={{
                            innerRadius: 40,
                            outerRadius: 95,
                            cy: "38%",
                            cx: "55%",
                            paddingAngle: 0,
                            // label: data.length > 5 && false,
                        }}
                        legendProps={{
                            height: 0,
                            iconType: "circle",
                            layout: "vertical",
                            verticalAlign: "middle",
                            align: "left",
                            // wrapperStyle: { top: 0 },
                        }}
                        hideLegend
                        hideLabels
                        isInnerlabel
                    />
                </Box>
            </Box>
        </Stack>
        // <Box display="flex" alignItems="flex-start">
        //     {title && (
        //         <Box sx={{ minWidth: 110 }}>
        //             <Typography variant="body1" fontWeight="bold">
        //                 {title}
        //             </Typography>
        //         </Box>
        //     )}

        //     <Box position="relative" top="-12px" width="100%">
        //         {data?.length > 1 && (
        //             <Chart
        //                 chartType="PieChart"
        //                 data={data}
        //                 options={options}
        //                 width={"100%"}
        //                 height={330}
        //             />
        //         )}
        //     </Box>
        // </Box>
    );
};

export { ChannelsSales };
