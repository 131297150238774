import Http from "../../config/http";
import { constants } from "../../config/constants";

const AuthServices = {
    login: (credentials) =>
        Http.POST(`${constants.API}/auth/login`, credentials),
    logout: () => Http.POST(`${constants.API}/auth/logout`),
    getCurrentUser: () => Http.GET(`${constants.API}/api/v1/profile/`),
    getMerchantFirstDate: () => Http.GET(`${constants.API}/api/v1/first-date`),
    checkCodeState: (code) => Http.POST(`${constants.API}/auth/signup`, code),
    inviteOwner: (data) =>
        Http.POST(`${constants.API}/auth/invite-owner`, data),
    paymentSuccess: (paymentData) =>
        Http.POST(`${constants.API}/auth/pay/`, paymentData),
    updateRole: (data) => Http.PUT(`${constants.API}/auth/manager-view/`, data),
    lastUpdate: (data) => Http.GET(`${constants.API}/api/v1/last-date`),
};

export { AuthServices };
