import { FormControl, InputBase, MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled, useTheme } from "@mui/material/styles";

const CustomSelectCSS = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        position: "relative",
        backgroundColor: "white",
        border: "1px solid #ced4da",
        padding: "5px 16px 6px 12px",
        fontSize: 14,
        borderRadius: "16px",
        borderRight: 0,
        // "&:focus": {
        //     borderRadius: "16px",
        // },
    },
}));

function getStyles(value, values, theme) {
    return {
        fontWeight: values.includes(value)
            ? "500"
            : theme.typography.fontWeightRegular,
        backgroundColor: values.includes(value)
            ? "rgba(60, 60, 89, 0.125)"
            : "white",
    };
}

const ButtonSelect = ({ value, onChange, options, isLoading, isMulti }) => {
    const theme = useTheme();

    if (isMulti) {
        return (
            <FormControl
                sx={{
                    width: 150,
                    backgroundColor: "white",
                    height: 36,
                    borderRadius: "16px",
                }}
            >
                <Select
                    value={value === "all" ? ["all"] : value}
                    onChange={onChange}
                    displayEmpty
                    input={<CustomSelectCSS />}
                    IconComponent={ExpandMoreIcon}
                    disabled={isLoading}
                    multiple
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option}
                            value={option.id}
                            style={getStyles(option.id, value, theme)}
                            disabled={isLoading}
                        >
                            {option.label || option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    return (
        <FormControl
            sx={{
                width: 150,
                backgroundColor: "white",
                height: 36,
                borderRadius: "16px",
            }}
        >
            <Select
                value={value}
                onChange={onChange}
                displayEmpty
                input={<CustomSelectCSS />}
                IconComponent={ExpandMoreIcon}
                disabled={isLoading}
            >
                {options.map((option) => (
                    <MenuItem key={option} value={option.id}>
                        {option.label || option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export { ButtonSelect };
