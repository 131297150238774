import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomTextField } from "../../../common/components";
import { getUserStorage } from "../../../common/utils";
import { useMutation } from "@tanstack/react-query";
import { services } from "../services";
import { toast } from "react-toastify";

export const emailFormatRegExp =
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const ProfileRow = ({
    isEditMode,
    handleUpdate,
    onUpdate,
    control,
    name,
    placeholder,
    label,
    value,
    watch,
}) => {
    const { t } = useTranslation();
    const isValidEmail = emailFormatRegExp.test(watch("email"));

    const formSchema = {
        email: {
            required: "Required",
            pattern: {
                value: emailFormatRegExp,
                message: "Invalid Email",
            },
        },
    };

    return (
        <Stack gap={4}>
            <Box display="flex" alignItems="center">
                <Typography fontSize={14} flex={1} maxWidth={300}>
                    {label}
                </Typography>
                <Box flex={1}>
                    {isEditMode ? (
                        <Box width={400}>
                            <Controller
                                rules={formSchema.email}
                                name={name}
                                control={control}
                                render={({ field }) => (
                                    <CustomTextField
                                        fullWidth
                                        placeholder={placeholder}
                                        variant="outlined"
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                    ) : (
                        <Typography sx={{ color: "#64748B", fontSize: 14 }}>
                            {value}
                        </Typography>
                    )}
                </Box>

                <Box gap={4} display="flex">
                    {isEditMode ? (
                        <Button
                            variant="text"
                            sx={{
                                color: "#5BCFDE",
                                textTransform: "capitalize",
                            }}
                            onClick={handleUpdate}
                            disabled={!isValidEmail}
                        >
                            {t("account.save")}
                        </Button>
                    ) : (
                        <Button
                            variant="text"
                            sx={{
                                color: "#5BCFDE",
                                textTransform: "capitalize",
                            }}
                            onClick={onUpdate}
                        >
                            {t("account.update")}
                        </Button>
                    )}
                </Box>
            </Box>
        </Stack>
    );
};

const Email = () => {
    const { mutate: updateCurrentProfile } = useMutation({
        mutationFn: services.updateCurrentProfile,
        onSuccess: (res) => {
            if (!res.data.error) {
                toast.success(t("account.email_updated"), {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    const user = getUserStorage();
    const { t } = useTranslation();

    const [updateList, setUpdateList] = useState({
        email: false,
    });

    const { control, getValues, watch } = useForm({
        defaultValues: {
            email: user.email,
        },
    });

    const handleEmailUpdate = () => {
        setUpdateList({
            ...updateList,
            email: false,
        });

        updateCurrentProfile({ password: getValues("password") });
    };

    const formSchema = {
        email: {
            required: "Required",
            pattern: {
                value: emailFormatRegExp,
                message: "Invalid Email",
            },
        },
    };

    return (
        <Stack spacing={8} pb={40}>
            <Typography>{t("account.change_email")}</Typography>

            <Stack gap={4}>
                <ProfileRow
                    name="email"
                    value={getValues("email")}
                    label={t("account.email")}
                    placeholder={t("account.email")}
                    isEditMode={updateList.email}
                    onUpdate={() =>
                        setUpdateList({ ...updateList, email: true })
                    }
                    control={control}
                    handleUpdate={handleEmailUpdate}
                    rules={formSchema.email}
                    watch={watch}
                />
                <Divider />
            </Stack>
        </Stack>
    );
};

export { Email };
