import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    FormControl,
    InputBase,
    MenuItem,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

const invoiceData = [
    {
        month: "Sep 2024",
        invoices: [
            {
                channelName: "Al-Arid",
                revenue: "7000.00 SAR",
                foodCosts: "1500.00 SAR",
                deliveryFees: "300.00 SAR",
                adCosts: "400.00 SAR",
                compensations: "80.00 SAR",
                commission: "700.00 SAR",
                pnl: "5020.00 SAR",
            },
            {
                channelName: "Al-Narjis",
                revenue: "8000.00 SAR",
                foodCosts: "2000.00 SAR",
                deliveryFees: "400.00 SAR",
                adCosts: "500.00 SAR",
                compensations: "90.00 SAR",
                commission: "800.00 SAR",
                pnl: "5210.00 SAR",
            },
        ],
    },
    {
        month: "Aug 2024",
        invoices: [
            {
                channelName: "Al-Rayan",
                revenue: "5000.00 SAR",
                foodCosts: "1000.00 SAR",
                deliveryFees: "200.00 SAR",
                adCosts: "300.00 SAR",
                compensations: "50.00 SAR",
                commission: "500.00 SAR",
                pnl: "3950.00 SAR",
            },
            {
                channelName: "Al-Safa",
                revenue: "6000.00 SAR",
                foodCosts: "1200.00 SAR",
                deliveryFees: "250.00 SAR",
                adCosts: "400.00 SAR",
                compensations: "70.00 SAR",
                commission: "600.00 SAR",
                pnl: "4480.00 SAR",
            },
        ],
    },
    {
        month: "Jul 2024",
        invoices: [
            {
                channelName: "Al-Malqa",
                revenue: "9000.00 SAR",
                foodCosts: "2500.00 SAR",
                deliveryFees: "500.00 SAR",
                adCosts: "600.00 SAR",
                compensations: "100.00 SAR",
                commission: "900.00 SAR",
                pnl: "5400.00 SAR",
            },
            {
                channelName: "Al-Rayan",
                revenue: "10000.00 SAR",
                foodCosts: "3000.00 SAR",
                deliveryFees: "600.00 SAR",
                adCosts: "700.00 SAR",
                compensations: "120.00 SAR",
                commission: "1000.00 SAR",
                pnl: "5580.00 SAR",
            },
        ],
    },
    {
        month: "Jun 2024",
        invoices: [
            {
                channelName: "Al-Arid",
                revenue: "11000.00 SAR",
                foodCosts: "3500.00 SAR",
                deliveryFees: "700.00 SAR",
                adCosts: "800.00 SAR",
                compensations: "150.00 SAR",
                commission: "1100.00 SAR",
                pnl: "5750.00 SAR",
            },
            {
                channelName: "Al-Safa",
                revenue: "12000.00 SAR",
                foodCosts: "4000.00 SAR",
                deliveryFees: "800.00 SAR",
                adCosts: "900.00 SAR",
                compensations: "170.00 SAR",
                commission: "1200.00 SAR",
                pnl: "5930.00 SAR",
            },
        ],
    },
];

const CustomSelectCSS = styled(InputBase)(({ theme }) => {
    return {
        "label + &": {
            marginTop: theme.spacing(3),
        },
        "& .MuiInputBase-input": {
            position: "relative",
            backgroundColor: "white",
            border: "1px solid #ced4da",
            padding: "5px 16px 6px 12px",
            fontSize: 14,
            borderRadius: "8px",
            borderRight: 0,
            "&:focus": {
                borderRadius: "8px",
            },
        },
    };
});

const ProfitAndLosePage = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px={2}
            >
                <Box display="flex" alignItems="center" gap={4}>
                    {isArabic ? (
                        <ArrowForwardIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => navigate("/sales/aggregators")}
                        />
                    ) : (
                        <ArrowBackIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => navigate("/sales/aggregators")}
                        />
                    )}
                    <Typography variant="h5">P&L history record</Typography>
                </Box>

                <FormControl
                    sx={{
                        width: 150,
                        backgroundColor: "white",
                        height: 36,
                        borderRadius: "16px",
                    }}
                >
                    <Select
                        displayEmpty
                        input={<CustomSelectCSS />}
                        IconComponent={ExpandMoreIcon}
                        defaultValue={2024}
                    >
                        {[2024, 2023, 2022, 2021, 2020, 2019].map(
                            (definedDate) => (
                                <MenuItem key={definedDate} value={definedDate}>
                                    {definedDate}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{ p: 2 }}>
                {invoiceData.map((section, index) => (
                    <Accordion
                        key={index}
                        defaultExpanded={section.invoices.length > 0}
                        sx={{
                            borderRadius: "16px",
                            mb: 2,
                            overflow: "hidden", // ensures border-radius applies to AccordionDetails as well
                            "&:before": { display: "none" },
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                                borderBottom:
                                    "1px solid rgba(224, 224, 224, 1)",
                                height: 18,
                            }}
                        >
                            <Typography variant="h6">
                                {section.month}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                borderRadius: "16px", // applies the bottom corners border radius
                                padding: 0,
                            }}
                        >
                            {section.invoices.length > 0 ? (
                                <Table
                                    component={Paper}
                                    sx={{
                                        boxShadow: "none",
                                        borderRadius: "16px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Channel Name</TableCell>
                                            <TableCell>Revenue</TableCell>
                                            <TableCell>Food Costs</TableCell>
                                            <TableCell>Delivery Fees</TableCell>
                                            <TableCell>Ad Costs</TableCell>
                                            <TableCell>Compensations</TableCell>
                                            <TableCell>Commission</TableCell>
                                            <TableCell>P&L</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {section.invoices.map(
                                            (invoice, idx) => (
                                                <TableRow
                                                    key={idx}
                                                    sx={{
                                                        borderBottom:
                                                            idx ===
                                                            section.invoices
                                                                .length -
                                                                1
                                                                ? "none"
                                                                : "1px solid rgba(224, 224, 224, 1)", // removes border for the last row
                                                    }}
                                                >
                                                    <TableCell>
                                                        {invoice.channelName}
                                                    </TableCell>
                                                    <TableCell>
                                                        {invoice.revenue}
                                                    </TableCell>
                                                    <TableCell>
                                                        {invoice.foodCosts}
                                                    </TableCell>
                                                    <TableCell>
                                                        {invoice.deliveryFees}
                                                    </TableCell>
                                                    <TableCell>
                                                        {invoice.adCosts}
                                                    </TableCell>
                                                    <TableCell>
                                                        {invoice.compensations}
                                                    </TableCell>
                                                    <TableCell>
                                                        {invoice.commission}
                                                    </TableCell>
                                                    <TableCell>
                                                        {invoice.pnl}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            ) : (
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{ padding: "16px" }}
                                >
                                    {section.upcomingMessage
                                        ? section.upcomingMessage
                                        : "No data available for this month."}
                                </Typography>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Stack>
    );
};

export { ProfitAndLosePage };
