import { Cell, Pie, PieChart } from "recharts";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
}) => {
    const radius = 55;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="black"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="middle"
            fontSize={12}
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const ProgressPie = ({ completedPerc = 100, withLabel }) => {
    const data = [
        { name: "L1", value: completedPerc },
        { name: "L2", value: 100 - +completedPerc },
    ];

    return (
        <PieChart width={150} height={150}>
            {withLabel && (
                <>
                    <text
                        x={78}
                        y={72}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize={32}
                    >
                        {completedPerc}%
                    </text>
                    <text
                        x={77}
                        y={97}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize={14}
                    >
                        Completed
                    </text>
                </>
            )}
            <Pie
                data={data}
                dataKey="value"
                innerRadius="70%"
                outerRadius="100%"
                fill="#f2f2f2"
                startAngle={90}
                endAngle={-270}
                paddingAngle={0}
                blendStroke
                // label={renderCustomizedLabel}
                labelLine={false}
            >
                <Cell key="test" fill="#5BCFDE" />
            </Pie>
        </PieChart>
    );
};

export { ProgressPie };
