import { Typography, useMediaQuery, useTheme } from "@mui/material";

const PageHeader = ({ title }) => {
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.only("xs"));

    return (
        <Typography
            fontSize={isMobileView ? 24 : 30}
            fontWeight="bold"
            textTransform="capitalize"
        >
            {title}
        </Typography>
    );
};

export { PageHeader };
