import React from "react";
import {
    Avatar,
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ImageIcon from "@mui/icons-material/Image";
import { useNavigate } from "react-router-dom";
import { constants } from "../../../../config/constants";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";

const TableView = ({ items, withViewAction = true }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const navigate = useNavigate();

    return (
        <Box>
            {!!items.length && (
                <TableContainer
                    sx={{
                        border: "1px solid #cccccc",
                        borderRadius: 2,
                        backgroundColor: "white",
                    }}
                >
                    <Table aria-label="simple table">
                        <TableHead
                            sx={{
                                backgroundColor: "#f9fafb",
                                "& .MuiTableCell-root": { color: "#7c818e" },
                            }}
                        >
                            <TableRow>
                                <TableCell align={isArabic ? "right" : "left"}>
                                    {t("common.product")}
                                </TableCell>
                                <TableCell align={isArabic ? "right" : "left"}>
                                    {t("common.class")}
                                </TableCell>
                                <TableCell align={isArabic ? "right" : "left"}>
                                    {t("common.quantity")}
                                </TableCell>
                                <TableCell align={isArabic ? "right" : "left"}>
                                    {t("common.cost")}
                                </TableCell>
                                <TableCell align={isArabic ? "right" : "left"}>
                                    {t("common.price")}
                                </TableCell>
                                <TableCell align={isArabic ? "right" : "left"}>
                                    {t("common.total_sales")}
                                </TableCell>
                                <TableCell align={isArabic ? "right" : "left"}>
                                    {t("common.profitability")}
                                </TableCell>
                                {withViewAction && (
                                    <TableCell
                                        align={isArabic ? "right" : "left"}
                                    >
                                        {t("common.action")}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((row, i) => (
                                <TableRow
                                    key={row.product_id + i}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap={4}
                                        >
                                            <Avatar src={row.image}>
                                                <ImageIcon />
                                            </Avatar>

                                            <Stack spacing={1}>
                                                <Typography fontSize={14}>
                                                    {row.product_name}
                                                </Typography>
                                                <Typography
                                                    fontSize={14}
                                                    color="#6B7280"
                                                >
                                                    {row.Category}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        align={isArabic ? "right" : "left"}
                                    >
                                        {row.class}
                                    </TableCell>
                                    <TableCell
                                        align={isArabic ? "right" : "left"}
                                    >
                                        {row.quantities_sold}
                                    </TableCell>
                                    <TableCell
                                        align={isArabic ? "right" : "left"}
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        {numberWithCommas(row.cost)}{" "}
                                        {t("common.currency")}
                                    </TableCell>
                                    <TableCell
                                        align={isArabic ? "right" : "left"}
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        {numberWithCommas(row.price)}{" "}
                                        {t("common.currency")}
                                    </TableCell>
                                    <TableCell
                                        align={isArabic ? "right" : "left"}
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        {numberWithCommas(
                                            Math.round(row.total_sales).toFixed(
                                                2
                                            )
                                        )}{" "}
                                        {t("common.currency")}
                                    </TableCell>
                                    <TableCell
                                        align={isArabic ? "right" : "left"}
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        {numberWithCommas(
                                            Math.round(
                                                row.total_profit
                                            ).toFixed(2)
                                        )}{" "}
                                        {t("common.currency")}
                                    </TableCell>
                                    {withViewAction && (
                                        <TableCell
                                            align={isArabic ? "right" : "left"}
                                            onClick={() =>
                                                navigate(`${row.product_id}`)
                                            }
                                            sx={{
                                                cursor: "pointer",
                                                color: "lightBlue",
                                            }}
                                        >
                                            {t("common.view")}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export { TableView };
