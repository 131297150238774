import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { uniqBy } from "lodash";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputBase,
    MenuItem,
    Popover,
    Select,
    Stack,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CustomDateField } from "..";
import { subDays, format, subWeeks, subMonths } from "date-fns";
import { styled } from "@mui/material/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useQuery } from "@tanstack/react-query";
import { AuthServices } from "../../../Auth/services";
import { constants } from "../../../config/constants";

const CustomSelectCSS = styled(InputBase)(({ theme }) => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    return {
        "label + &": {
            marginTop: theme.spacing(3),
        },
        "& .MuiInputBase-input": {
            position: "relative",
            backgroundColor: "white",
            border: "1px solid #ced4da",
            padding: "5px 16px 6px 12px",
            fontSize: 14,
            borderRadius: isArabic ? " 0 16px 16px 0px" : "16px 0 0 16px",
            borderRight: 0,
            "&:focus": {
                borderRadius: isArabic ? " 0 16px 16px 0px" : "16px 0 0 16px",
            },
        },
    };
});

const CustomDatePicker = ({ onChange, isLoading, isToday }) => {
    const [firstData, setFirstDate] = useState(null);
    const { data } = useQuery({
        queryKey: ["firstSalesDate"],
        queryFn: AuthServices.getMerchantFirstDate,
        refetchOnWindowFocus: false,
    });

    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.only("xs"));

    const currentDate = new Date();

    const defaultRange = [
        {
            label: t("common.today"),
            startDate: currentDate.toLocaleDateString("en"),
        },
        {
            label: t("common.last_7_days"),
            startDate: subDays(currentDate, 7).toLocaleDateString("en"),
        },
        {
            label: t("common.last_4_weeks"),
            startDate: subWeeks(currentDate, 4).toLocaleDateString("en"),
        },
        {
            label: t("common.last_3_months"),
            startDate: subMonths(currentDate, 3).toLocaleDateString("en"),
        },
        {
            label: t("common.last_12_months"),
            startDate: subMonths(currentDate, 12).toLocaleDateString("en"),
        },
        // { label: 'Month to date', startDate: subMonths(currentDate, 1).toLocaleDateString("en") },
        // { label: 'Year to date', startDate: subYears(currentDate, 1).toLocaleDateString("en") },
        {
            label: t("common.all_time"),
            startDate: null,
        },
    ];
    const [preDefinedRanges, setPreDefinedRanges] = useState(defaultRange);

    const [dayRange, setDayRange] = useState(
        isToday ? preDefinedRanges[0].startDate : preDefinedRanges[5].startDate
    );

    const handleChange = (event) => {
        setDayRange(event.target.value);
        setStartDate(new Date(event.target.value));
        setEndDate(new Date());

        setPreDefinedRanges(
            preDefinedRanges.filter((date) => date.startDate !== "custom")
        );
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [startDate, setStartDate] = useState(
        new Date(
            isToday
                ? preDefinedRanges[0].startDate
                : preDefinedRanges[5].startDate
        )
    );
    const [endDate, setEndDate] = useState(new Date());

    const handleDatePickerChange = (date) => {
        // const [start, end] = dates;
        setStartDate(date);
        handleClose();
    };

    useEffect(() => {
        if (onChange) {
            onChange({
                startDate: formattedDate(startDate),
                endDate: endDate
                    ? formattedDate(endDate)
                    : formattedDate(new Date()),
            });
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (data?.data && !data?.data?.error) {
            const firstSalesDate = new Date(
                data?.data.response.first_date
            ).toLocaleDateString("en");

            setFirstDate(firstSalesDate);

            setPreDefinedRanges([
                ...defaultRange.slice(0, defaultRange.length - 1),
                {
                    label: t("common.all_time"),
                    startDate: firstSalesDate,
                },
            ]);

            if (!isToday) {
                setDayRange(firstSalesDate);

                setStartDate(new Date(firstSalesDate));
            }

            sessionStorage.setItem(constants.storage.firstDate, firstSalesDate);
        }
    }, [data]);

    function formattedDate(date) {
        return format(date, "y-M-d");
    }

    function setSelectToCustom() {
        setPreDefinedRanges(
            uniqBy(
                [
                    ...preDefinedRanges,
                    { label: "Custom  ", startDate: "custom", disabled: true },
                ],
                (d) => d.label
            )
        );

        setDayRange("custom");
    }

    return (
        <Box display="flex" alignItems="center" gap={3}>
            <Box display="flex">
                <Box onClick={handleClick}>
                    <CustomDateField
                        value={startDate}
                        onChange={(val) => {
                            setSelectToCustom();
                            setStartDate(new Date(val));
                        }}
                        maxValue={endDate}
                    />
                </Box>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    onClose={handleClose}
                >
                    <Stack padding="28px 8px 8px 14px" spacing={4} width={310}>
                        <DatePicker
                            selected={null}
                            onChange={(value) => handleDatePickerChange(value)}
                            inline
                            disabledKeyboardNavigation
                            scrollableYearDropdown
                        />
                    </Stack>
                </Popover>
            </Box>
        </Box>
    );
};

export { CustomDatePicker };
