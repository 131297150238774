import {
    Box,
    Icon,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const InfoCard = ({
    label,
    value,
    valueTextVariant = "h5",
    containerStyles,
    iconPath,
    tooltipLabel,
}) => {
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.only("xs"));

    return (
        <Stack
            spacing={4}
            padding={6}
            backgroundColor="white"
            borderRadius={5}
            boxShadow="0 1px 3px hsla(220,6%,43%,0.2)"
            height={isMobileView ? "140px" : "174px"}
            width="100%"
            // maxWidth={320}
            minWidth={225}
            {...containerStyles}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box display="flex" gap={3} alignItems="baseline">
                    <Typography
                        variant="body1"
                        color="#999999"
                        fontSize={isMobileView ? 14 : 16}
                    >
                        {label}
                    </Typography>

                    <Tooltip title={tooltipLabel}>
                        <HelpOutlineIcon sx={{ fontSize: 16 }} />
                    </Tooltip>
                </Box>

                <Box
                    width={isMobileView ? "32px" : "42px"}
                    height={isMobileView ? "32px" : "42px"}
                    backgroundColor="#F1F5F9"
                    borderRadius="6px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    // padding={2.3}
                >
                    <Icon
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img src={iconPath} alt="infoCardIcon" />
                    </Icon>
                </Box>
            </Box>

            <Typography
                color="#202020"
                fontSize={isMobileView ? "24px" : "32px"}
                // fontWeight="500"
                variant={valueTextVariant}
            >
                {value}
            </Typography>
        </Stack>
    );
};

export { InfoCard };
