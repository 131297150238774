import { Box, Stack, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { SimpleLineChart } from "../../../../common/components/Charts/SimpleLineChart/SimpleLineChart";
import { useTranslation } from "react-i18next";

const PerformanceLineChart = ({
    title,
    data,
    periods,
    yMax,
    isDrop,
    isNoComparison,
    hidePerc,
    unit,
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    return (
        <Stack gap={12}>
            <Box
                display="flex"
                alignItems="baseline"
                justifyContent="space-between"
                padding={6}
                pb={0}
            >
                <Box display="flex" alignItems="center" gap={2}>
                    <Typography variant="body1" fontWeight="bold">
                        {title}
                    </Typography>

                    {!hidePerc && (
                        <>
                            {!isNoComparison && (
                                <Box
                                    backgroundColor="#f2f2f2"
                                    padding={1}
                                    borderRadius="50%"
                                    width="26px"
                                    height="26px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    {!isDrop ? (
                                        <ArrowUpwardIcon
                                            sx={{ fontSize: 18 }}
                                        />
                                    ) : (
                                        <ArrowDownwardIcon
                                            sx={{ fontSize: 18 }}
                                        />
                                    )}
                                </Box>
                            )}

                            {!isNoComparison && (
                                <Box
                                    paddingY={1}
                                    color={isDrop ? "red" : "green"}
                                >
                                    {periods?.percentage}%
                                </Box>
                            )}
                        </>
                    )}
                </Box>

                <Stack alignItems="flex-end">
                    <Typography variant="h5" fontWeight="bold">
                        {periods?.currentTotal}
                    </Typography>
                    {!isNoComparison && (
                        <Typography variant="body1" sx={{ color: "#c6c6c6" }}>
                            {t("sales.previous_period")}: {periods?.prevTotal}
                        </Typography>
                    )}
                </Stack>
            </Box>

            <SimpleLineChart
                data={data}
                yAxisConfigs={{
                    domain: [0, yMax || 1000],
                    orientation: isArabic ? "right" : "left",
                }}
                yKey="Previous Period"
                xKey="Current Period"
                noComparison={isNoComparison}
                unit={unit}
            />
        </Stack>
    );
};

export { PerformanceLineChart };
