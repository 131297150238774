import { createTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";

const createLocaleTheme = (lang) => {
    const theme = createTheme({
        palette: {
            primary: {
                light: "#9194ce",
                main: "#2b3c59",
                dark: "#263056",
            },
            white: "#fff",
            black: "#303030",
            lightBlue: "#5BCFDE",
            lightGreen: "#9FE3B9",
            dark: "#06262D",
        },
        spacing: 4,
        typography: {
            fontFamily: lang === "ar" ? "Noto Sans Arabic" : "Outfit",
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 1200,
                lg: 1500,
                xl: 1920,
            },
        },
    });

    return theme;
};

const MuiThemeProvider = ({ children }) => {
    const { i18n } = useTranslation();

    return (
        <ThemeProvider theme={createLocaleTheme(i18n.language)}>
            {children}
        </ThemeProvider>
    );
};

export { MuiThemeProvider };
