import { Box, Stack, Typography } from "@mui/material";
import ChatInput from "./ChatInput";
import Lottie from "lottie-react";
import animationData from "./ballurh_lottie.json";
import BotAnimation from "./bot-animation.json";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { UserContext } from "../../../common/components/SharedLayout/SharedLayout";
import { constants } from "../../../config/constants";
import { PredefinedQuestions } from "./PredefinedQuestions";

import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import "katex/dist/katex.min.css"; // Import KaTeX CSS for math rendering
import remarkGfm from "remark-gfm"; // Import remark-gfm
import { useTranslation } from "react-i18next";

const TypingIndicator = () => {
    return (
        <Box
            sx={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                "& span": {
                    display: "block",
                    width: "6px",
                    height: "6px",
                    backgroundColor: "#5BCFDE",
                    borderRadius: "50%",
                    animation: "typing 1s infinite",
                },
                "& span:nth-of-type(2)": {
                    animationDelay: "0.2s",
                },
                "& span:nth-of-type(3)": {
                    animationDelay: "0.4s",
                },
                "@keyframes typing": {
                    "0%, 60%, 100%": { opacity: 0.3 },
                    "30%": { opacity: 1 },
                },
            }}
        >
            <span></span>
            <span></span>
            <span></span>
        </Box>
    );
};

async function queryDatabase(question, merchantDetails) {
    const { merchant_name, merchant_id, merchant_key } = merchantDetails;

    const response = await fetch(
        `${constants.baseURL}/llmchatbot/api/query_db`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_question: question,
                merchant_name,
                merchant_id,
                merchant_key,
            }),
        }
    );

    if (!response.body) {
        throw new Error("Readable stream is not supported");
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let result = "";

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += decoder.decode(value, { stream: true });
        // Here you can update your state to display the response incrementally
    }

    return result;
}

function preprocessMarkdown(input) {
    return input.replace(/\[\s*([^[]+?)\s*\]/g, "\\[$1\\]");
}

const BallurhAssistant = () => {
    const { t } = useTranslation();

    const { userData } = useContext(UserContext);

    const [messages, setMessages] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const messagesEndRef = useRef(null);

    const mutation = useMutation(
        ({ question, merchantDetails }) =>
            queryDatabase(question, merchantDetails),
        {
            onMutate: () => {
                setIsTyping(true); // Show typing indicator when the mutation starts
            },
            onSuccess: (data) => {
                setIsTyping(false);
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { sender: "bot", text: JSON.parse(data).output },
                ]);
            },
            onError: (error) => {
                setIsTyping(false);
                console.error("Error fetching data: ", error);
            },
        }
    );

    const handleSendMessage = (message) => {
        setMessages((prevMessages) => [
            ...prevMessages,
            { sender: "user", text: message },
        ]);

        const merchantDetails = {
            merchant_name: userData.merchant.name,
            merchant_id: userData.merchant.merchant_id,
            merchant_key: userData.merchant.id,
        };

        mutation.mutate({ question: message, merchantDetails });
    };

    // Scroll to the bottom when messages change
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages, isTyping]);

    return (
        <Stack sx={{ height: "calc(100vh - 90px)" }} padding={4}>
            <Box
                sx={{
                    flex: 1,
                    overflowY: "auto",
                    padding: 2,
                    paddingBottom: 4,
                    borderRadius: "8px",
                    marginBottom: 2,
                }}
            >
                {!messages.length && (
                    <Stack
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flex: 1,
                        }}
                        height="57%"
                    >
                        <Lottie
                            animationData={animationData}
                            loop={true}
                            style={{ width: 200, height: 200 }}
                        />
                        <Typography color="#5BCFDE" variant="h5">
                            {t("chat.aiAnalyst")}
                        </Typography>
                        <Typography>{t("chat.seeTheStory")}</Typography>
                    </Stack>
                )}
                {messages.map((msg, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: "flex",
                            justifyContent:
                                msg.sender === "user"
                                    ? "flex-end"
                                    : "flex-start",
                            marginBottom: 1,
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: "70%",
                                padding: "8px 12px",
                                borderRadius: "16px",
                                wordBreak: "break-word", // Break long words
                                backgroundColor:
                                    msg.sender === "user"
                                        ? "#5BCFDE"
                                        : "#f1f1f1",
                                color: msg.sender === "user" ? "#fff" : "#000",
                                fontSize: "14px",
                            }}
                        >
                            <ReactMarkdown
                                children={preprocessMarkdown(msg.text)} // Preprocess Markdown
                                remarkPlugins={[remarkGfm, remarkMath]} // Enable tables and math
                                rehypePlugins={[rehypeKatex]} // Render LaTeX math
                                components={{
                                    p: ({ node, ...props }) => (
                                        <Typography
                                            {...props}
                                            sx={{ margin: "4px 0" }}
                                        />
                                    ),
                                    h1: ({ node, ...props }) => (
                                        <Typography
                                            {...props}
                                            variant="h6"
                                            sx={{
                                                fontWeight: "bold",
                                                marginBottom: "8px",
                                            }}
                                        />
                                    ),
                                    h2: ({ node, ...props }) => (
                                        <Typography
                                            {...props}
                                            variant="subtitle1"
                                            sx={{
                                                fontWeight: "medium",
                                                marginBottom: "6px",
                                            }}
                                        />
                                    ),
                                    table: ({ node, ...props }) => (
                                        <Box
                                            component="table"
                                            {...props}
                                            sx={{
                                                width: "100%",
                                                borderCollapse: "collapse",
                                                marginBottom: "16px",
                                            }}
                                        />
                                    ),
                                    th: ({ node, ...props }) => (
                                        <Box
                                            component="th"
                                            {...props}
                                            sx={{
                                                border: "1px solid #ddd",
                                                padding: "8px",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                backgroundColor: "#f9f9f9",
                                            }}
                                        />
                                    ),
                                    td: ({ node, ...props }) => (
                                        <Box
                                            component="td"
                                            {...props}
                                            sx={{
                                                border: "1px solid #ddd",
                                                padding: "8px",
                                                textAlign: "left",
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </Box>
                    </Box>
                ))}
                {isTyping && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginBottom: 1,
                        }}
                    >
                        <TypingIndicator />{" "}
                        {/* Use the TypingIndicator component */}
                    </Box>
                )}
                {/* Reference to scroll into view */}
                <div ref={messagesEndRef} />

                {!messages.length && (
                    <PredefinedQuestions onSend={handleSendMessage} />
                )}
            </Box>

            <Box display="flex" alignItems="center" gap={2}>
                {/* <Box sx={{ cursor: "pointer" }}>
                    <AttachFileIcon />
                </Box> */}
                <ChatInput onSend={handleSendMessage} />
            </Box>
        </Stack>
    );
};

export { BallurhAssistant };
