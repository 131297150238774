import { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { GridCard } from "../../../../../common/components";
import { BranchSales } from "../../components/BranchSales";
import { OrderTypes } from "../../components/OrderTypes";
import { TrendingHours } from "../../components/TrendingHours";
import { ChannelsSales } from "../../components/ChannelsSales";
import { InfoCard } from "../../components/InfoCard";
import { getUserStorage } from "../../../../../common/utils";
import { numberWithCommas } from "../../../../../common/utils/numberWithCommas";
import { PageHeader } from "../../../../../common/components";
import { constants } from "../../../../../config/constants";
import { ExtendArabicLogoPDF } from "../../../Overview/pdf/OverviewPDF";

const SalesInsightsPDF = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const user = getUserStorage();
    const isOwner = user.role === 1;

    const [printDate, setPrintDate] = useState("");
    const [period, setPeriod] = useState({
        startDate: null,
        endDate: format(new Date(), "y-M-d"),
    });
    const [salesData, setSalesData] = useState(null);
    const [orderTypesData, setOrderTypesData] = useState(null);
    const [channelsData, setChannelsData] = useState(null);
    const [branchesSales, setBranchesSales] = useState(null);
    const [trendingHoursData, setTrendingHoursData] = useState([]);

    useEffect(() => {
        const pdfData = JSON.parse(localStorage.getItem("overview-pdf"));
        if (isOwner) setBranchesSales(pdfData.branches_sales);

        setSalesData(pdfData.sales);
        setOrderTypesData(pdfData.order_types);
        setChannelsData(pdfData.channels);
        setTrendingHoursData(pdfData.trending_hours);
        setPrintDate(pdfData.printDate);
        setPeriod(pdfData.period);

        if (pdfData.isArabic) {
            localStorage.setItem(constants.storage.language, "ar");
            i18n.changeLanguage("ar");
        }
    }, []);

    return (
        <Stack dir={isArabic ? "rtl" : "ltr"}>
            <Box
                height={55}
                backgroundColor="black"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={8}
                sx={{
                    pageBreakAfter: "avoid",
                }}
            >
                {isArabic ? (
                    <ExtendArabicLogoPDF />
                ) : (
                    <img
                        src="/logo_extend.svg"
                        width={176}
                        height="31px"
                        alt="lgo"
                    />
                )}

                <Typography
                    fontSize={12}
                    color="white"
                    fontWeight="bold"
                    position="relative"
                    top="12px"
                >
                    {printDate}
                </Typography>
            </Box>

            <Stack padding={8} spacing={8} minWidth="100%">
                <Stack spacing={4}>
                    <PageHeader title={t("sales.insights")} />

                    <Typography fontSize={14}>
                        {period.startDate} &rarr; {period.endDate}
                    </Typography>
                </Stack>

                <Grid item xs={12} md={6}>
                    <Grid container spacing={4}>
                        <Grid item xs={6} md={6}>
                            <InfoCard
                                iconPath="/ic_sales_outline.svg"
                                label={t("sales.total_sales")}
                                value={`${
                                    numberWithCommas(
                                        salesData?.sales_insights_totals[0]
                                            ?.total_sales
                                    ) || 0
                                } ${t("common.currency")} `}
                                tooltipLabel={t("tooltips.sales_total_sales")}
                            />
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <InfoCard
                                iconPath="/ic_sales_outline.svg"
                                label={t("sales.total_profitability")}
                                value={`${
                                    numberWithCommas(
                                        salesData?.sales_insights_totals[0]
                                            ?.total_profitability
                                    ) || 0
                                } ${t("common.currency")} `}
                                tooltipLabel={t(
                                    "tooltips.sales_total_profitability"
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <InfoCard
                                iconPath="/ic_transactions_outline.svg"
                                label={t("sales.total_transactions")}
                                value={`${
                                    salesData?.sales_insights_totals[0]
                                        ?.total_transactions || 0
                                }`}
                                tooltipLabel={t(
                                    "tooltips.sales_total_transactions"
                                )}
                            />
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <InfoCard
                                iconPath="/products.svg"
                                label={t("sales.total_products")}
                                value={`${
                                    salesData?.sales_insights_totals[0]
                                        ?.total_product || 0
                                } ${t("common.product_2")}`}
                                tooltipLabel={t(
                                    "tooltips.sales_total_products"
                                )}
                            />
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <InfoCard
                                iconPath="/ic_cart_outline.svg"
                                containerStyles={{
                                    height: 157,
                                }}
                                label={t("sales.avg_basket_value")}
                                value={`${
                                    numberWithCommas(
                                        salesData?.sales_insights_totals[0]
                                            ?.avg_basket_size_value
                                    ) || 0
                                } ${t("common.currency")} `}
                                tooltipLabel={t(
                                    "tooltips.customer_avg_basket_value"
                                )}
                            />
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <InfoCard
                                iconPath="/ic_quantity.svg"
                                containerStyles={{
                                    height: 157,
                                }}
                                label={t("sales.avg_basket_quantity")}
                                value={`${
                                    salesData?.sales_insights_totals[0]
                                        ?.avg_basket_size_quantity || 0
                                } ${t("common.products_clean")}`}
                                tooltipLabel={t(
                                    "tooltips.customer_avg_basket_size"
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    container
                    spacing={6}
                    style={{
                        margin: isArabic ? 0 : "-24px",
                        marginTop: 0,
                        marginBottom: 0,
                    }}
                    sx={{
                        minHeight: 400,
                        height: "fit-content",
                    }}
                >
                    <GridCard
                        gridProps={{ xs: 12, md: 6 }}
                        padding={0}
                        height="inherit"
                    >
                        {isOwner ? (
                            <BranchSales data={branchesSales} />
                        ) : (
                            <OrderTypes data={orderTypesData} />
                        )}
                    </GridCard>

                    <GridCard
                        gridProps={{ xs: 12, md: 6 }}
                        padding={0}
                        height="inherit"
                    >
                        <ChannelsSales data={channelsData} />
                    </GridCard>
                </Grid>

                <Grid container spacing={0} flexWrap="nowrap" paddingTop={10}>
                    <GridCard padding={0} height={338} fullWidth>
                        <OrderTypes
                            data={orderTypesData}
                            pieContainerStyles={{ top: "-12px" }}
                        />
                    </GridCard>
                </Grid>

                <Grid container spacing={0} flexWrap="nowrap">
                    <GridCard fullWidth padding={0} gutter={isArabic}>
                        <TrendingHours data={trendingHoursData} />
                    </GridCard>
                </Grid>

                {/* This is changed because now the branch manager could have many branches */}
                {/* {!isOwner && (
                <Grid container spacing={0} flexWrap="nowrap">
                    <GridCard gutter>
                        <OrderTypes data={orderTypesData} />
                    </GridCard>

                    <Grid item xs={6} />
                </Grid>
            )} */}
            </Stack>
        </Stack>
    );
};

export { SalesInsightsPDF };
