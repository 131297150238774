import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { getUserStorage } from "../../../../common/utils";
import { useTranslation } from "react-i18next";
import { differenceInDays } from "date-fns";

const FreeTrialAlert = ({ closeAlert }) => {
    const { t } = useTranslation();
    const user = getUserStorage();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const remainingDays = differenceInDays(
        new Date(user?.merchant?.subscription_expiry),
        new Date()
    );

    if (pathname === "/settings/subscription") return;
    if (
        user?.merchant.subscription !== 1 ||
        !user?.merchant?.subscription_expiry
    )
        return;

    if (remainingDays <= 0)
        return (
            <Box
                w="full"
                height="131px"
                backgroundColor="#C95151"
                display="flex"
                alignItems="center"
                p={8}
                position="relative"
            >
                <Stack spacing={3} flex={1}>
                    <Typography fontSize="16px" color="white">
                        {t("common.thanks_free_trail_end")}
                    </Typography>
                    <Typography fontSize="14px" color="white">
                        {t("common.free_trial_end_msg")}
                    </Typography>
                </Stack>

                <Box>
                    <Button
                        variant="contained"
                        sx={{
                            textTransform: "capitalize",
                            backgroundColor: "#06262D",
                            _hover: { backgroundColor: "#06262D" },
                            borderRadius: "16px",
                        }}
                        onClick={() => {
                            closeAlert();
                            navigate("/settings/subscription");
                        }}
                    >
                        {t("common.upgrade_now")}
                    </Button>
                </Box>
            </Box>
        );

    return (
        <Box
            w="full"
            height="131px"
            backgroundColor="#DDFBA9"
            display="flex"
            alignItems="center"
            p={8}
            position="relative"
        >
            <Stack spacing={3} flex={1}>
                <Typography fontSize="16px" color="#06262D">
                    {t("common.thanks_free_trail")}
                </Typography>
                <Typography fontSize="14px" color="#06262D">
                    {t("common.free_trial_end", { remainingDays })}
                </Typography>
            </Stack>

            <Box>
                <Button
                    variant="contained"
                    sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#06262D",
                        _hover: { backgroundColor: "#06262D" },
                        borderRadius: "16px",
                    }}
                    onClick={() => {
                        closeAlert();
                        navigate("/settings/subscription");
                    }}
                >
                    {t("common.upgrade_now")}
                </Button>
            </Box>
        </Box>
    );
};

export { FreeTrialAlert };
