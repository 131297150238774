import { Button } from "@mui/material";

const OutlineButton = ({ children, buttonStyles, buttonProps, onClick }) => {
    return (
        <Button
            sx={{
                width: 160,
                color: "#06262D",
                backgroundColor: "white",
                border: "1px solid #EEEFF4",
                "&:hover": {
                    border: "1px solid #EEEFF4",
                    backgroundColor: "white",
                },
                textTransform: "capitalize",
                justifyContent: "space-between",
                borderRadius: 4,
                height: 42,
                ...buttonStyles,
            }}
            variant="outlined"
            onClick={onClick}
            {...buttonProps}
        >
            {children}
        </Button>
    );
};

export { OutlineButton };
