import { Box, Icon, Stack, Tooltip, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const InfoCard = ({
    label,
    value,
    valueTextVariant = "h5",
    containerStyles,
    iconPath,
    tooltipLabel,
    prevVal,
    isCompare,
}) => (
    <Stack
        spacing={4}
        padding={6}
        backgroundColor="white"
        borderRadius={5}
        sx={{
            boxShadow: "0 1px 3px hsla(220,6%,43%,0.2)",
            "@media print": {
                boxShadow: "none", // Shadow for printing
                border: "1px solid hsla(220,6%,43%,0.2)", // Ensure colors render as expected during print
            },
        }}
        height="174px"
        width="100%"
        {...containerStyles}
    >
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" gap={3} alignItems="baseline">
                <Stack>
                    <Typography variant="body1" color="#999999" fontSize={16}>
                        {label}
                    </Typography>

                    {isCompare && (
                        <Box
                            color={prevVal > 0 ? "green" : "red"}
                            fontSize={14}
                        >
                            {prevVal}%
                        </Box>
                    )}
                </Stack>

                <Tooltip title={tooltipLabel}>
                    <HelpOutlineIcon sx={{ fontSize: 16 }} />
                </Tooltip>
            </Box>

            {iconPath && (
                <Box
                    width="42px"
                    height="42px"
                    backgroundColor="#F1F5F9"
                    borderRadius="6px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    // padding={2.3}
                >
                    <Icon
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img src={iconPath} alt="infoCardIcon" />
                    </Icon>
                </Box>
            )}
        </Box>

        <Typography
            color="#202020"
            fontSize="32px"
            // fontWeight="500"
            variant={valueTextVariant}
        >
            {value}
        </Typography>
    </Stack>
);

export { InfoCard };
