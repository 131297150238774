import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isValid, isBefore, isEqual } from "date-fns";
import { useEffect, useState } from "react";

const CustomTextField = styled(TextField)({
    "& label.Mui-focused": {
        //   color: 'green',
    },
    "& .MuiInput-underline:after": {
        //   borderBottomColor: 'green',
    },
    "& .MuiOutlinedInput-root": {
        height: 32,
        borderRadius: 4,
        fontSize: "15px",
        "& fieldset": {
            border: "1px solid #d0d0d0",
        },
        "&:hover fieldset": {
            borderColor: "#4d4d4d",
        },
        "&.Mui-focused fieldset": {
            border: "1px solid #4d4d4d",
        },
    },
    "& .MuiInputBase-input": {
        height: 8,
    },
});

const CustomDateField = ({ value, onChange, maxValue }) => {
    const [fieldValue, setFieldValue] = useState(
        new Date(value)?.toLocaleDateString("en")
    );

    const handleChange = (newValue) => {
        const dateValue = new Date(newValue);
        const isValidDate = maxValue
            ? isValid(new Date(newValue)) &&
              (isBefore(dateValue, new Date(maxValue)) ||
                  isEqual(dateValue, new Date(maxValue)))
            : isValid(new Date(newValue));

        if (isValidDate) onChange(new Date(newValue));
        else setFieldValue(new Date(value)?.toLocaleDateString("en"));
    };

    useEffect(() => {
        if (value) setFieldValue(new Date(value)?.toLocaleDateString("en"));
        else setFieldValue(fieldValue);
    }, [value]);

    return (
        <CustomTextField
            value={fieldValue}
            onChange={(e) => setFieldValue(e.target.value)}
            onBlur={(e) => handleChange(e.target.value)}
        />
    );
};

export { CustomDateField };
