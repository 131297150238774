import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Grid,
    Modal,
    Radio,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { constants } from "../../../config/constants";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomTextField, OutlineButton } from "../../../common/components";
import { GridCard } from "../../../common/components";
import { services } from "../../manager/services";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserStorage } from "../../../common/utils";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 10,
    borderRadius: 4,
};

const AddBranch = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = getUserStorage();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [selectedBranchId, setSelectedBranchId] = useState(null);

    const [selectedBranches, setSelectedBranches] = useState(
        user?.merchant?.branches?.map((branch) => ({
            id: branch.branch_id,
            name: branch.name,
            address: branch.address,
            phone: branch.address,
            manager: branch.manager,
            sales_goal: branch.sales_goal,
        }))
    );

    const { handleSubmit, control } = useForm({
        defaultValues: {},
    });

    const { mutate: addBranch, isLoading } = useMutation({
        mutationFn: services.addBranch,
        onSuccess: (res) => {
            if (!res.data.error) {
                toast.success("Branch Added Sussessfully!", {
                    hideProgressBar: true,
                });

                window.open(
                    `${window.location.origin}/settings/branches`,
                    "_self"
                );
            } else {
                if (res.data.code === 401) {
                    handleOpen();
                }
            }
        },
        onError: (err) => console.log(err),
    });

    const { data } = useQuery({
        queryKey: ["allProducts"],
        queryFn: services.getBranches,
    });

    const onSubmit = () => {
        addBranch({
            branch_idsadsa: selectedBranchId,
        });
    };

    const availabelBranches = useMemo(() => {
        const selectedBranchesIds = selectedBranches.map((sb) => sb.id);
        const branches = [];

        data?.data?.response.forEach((b) => {
            if (!selectedBranchesIds.includes(b.branch_id)) {
                branches.push(b);
            }
        });

        return branches;
    }, [data]);

    const onCancel = () => navigate(-1);

    const handleBranchSelect = (branch) => {
        setSelectedBranchId(branch.branch_id);
    };

    const handlePaybranch = () => {
        const addBranchLink =
            "https://zohosecurepay.com/checkout/9unelny-w0ug2fodecjmb/Add-Branch";

        localStorage.setItem(
            constants.storage.payment_branch,
            JSON.stringify({
                isAddBranch: true,
            })
        );

        window.open(addBranchLink, "_self");
    };

    useEffect(() => {
        return () => sessionStorage.removeItem("toBeEditedBranch");
    }, []);

    const newBranchCost = 99;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack padding={8} spacing={8} minWidth="100%">
                <Stack flexDirection="row" justifyContent="space-between">
                    <Typography variant="h5" fontSize={30} fontWeight="bold">
                        {t("settings.chain_branches")}
                    </Typography>
                </Stack>

                <Grid container flexWrap="nowrap">
                    <GridCard
                        fullWidth
                        boxStyles={{ minHeight: 320, height: "fit-content" }}
                    >
                        <Stack p={8} spacing={4}>
                            <Stack spacing={1}>
                                {availabelBranches.length ? (
                                    <Typography color="#0F172A" fontSize={16}>
                                        {t("settings.please_add_branch")}
                                    </Typography>
                                ) : (
                                    <Typography color="#0F172A" fontSize={16}>
                                        {t("settings.no_branches_to_add")}
                                    </Typography>
                                )}
                                {/* <Typography color="#6B7280" fontSize={14}>
                                    Create your branch information.{" "}
                                </Typography> */}
                            </Stack>

                            <Stack spacing={1}>
                                {availabelBranches.map((branch) => (
                                    <Box
                                        display="flex"
                                        gap={1}
                                        alignItems="center"
                                    >
                                        <Radio
                                            checked={
                                                selectedBranchId ===
                                                branch.branch_id
                                            }
                                            sx={{
                                                "&.Mui-checked": {
                                                    color: "#5BCFDE",
                                                },
                                            }}
                                            onClick={() =>
                                                handleBranchSelect(branch)
                                            }
                                        />
                                        <Typography
                                            fontWeight="bold"
                                            fontSize={14}
                                        >
                                            {branch.name}
                                        </Typography>
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                    </GridCard>
                </Grid>
                {!!availabelBranches.length && (
                    <Box
                        display="flex"
                        alignItems="center"
                        gap={4}
                        justifyContent="flex-end"
                    >
                        <OutlineButton
                            buttonStyles={{ borderRadius: "6px", width: 80 }}
                            onClick={onCancel}
                        >
                            {t("common.cancel")}
                        </OutlineButton>
                        <OutlineButton
                            buttonStyles={{
                                borderRadius: "6px",
                                width: "fit-content",
                                color: "white",
                                backgroundColor: "#5BCFDE",
                                "&:hover": {
                                    border: "1px solid #EEEFF4",
                                    backgroundColor: "#5BCFDE",
                                },
                            }}
                            buttonProps={{
                                type: "submit",
                                disabled: !selectedBranchId,
                            }}
                        >
                            {t("settings.add_selected_branch")}
                        </OutlineButton>
                    </Box>
                )}
            </Stack>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack spacing={5}>
                        <Box display="flex" alignItems="center" gap={2}>
                            <WarningAmberIcon sx={{ color: "#FFD700" }} />
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                            >
                                Branch Limit Reached
                            </Typography>
                        </Box>

                        <Stack>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                You have reached to the maximum number of
                                branches on your subscribtion plan!
                            </Typography>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                but you can add a new branch for {newBranchCost}{" "}
                                {t("common.currency")}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Box
                        display="flex"
                        mt={10}
                        justifyContent="flex-end"
                        gap={5}
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                textTransform: "capitalize",
                                borderRadius: "6px",
                            }}
                            onClick={handleClose}
                        >
                            {t("common.cancel")}
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                textTransform: "capitalize",
                                borderRadius: "6px",
                                width: "fit-content",
                                color: "white",
                                backgroundColor: "#5BCFDE",
                                border: "1px solid #EEEFF4",
                                "&:hover": {
                                    border: "1px solid #EEEFF4",
                                    backgroundColor: "#5BCFDE",
                                },
                            }}
                            onClick={handlePaybranch}
                        >
                            {t("common.proceed")}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </form>
    );
};

export { AddBranch };
