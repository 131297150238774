import { useTranslation } from "react-i18next";
import { GridCard } from "../../../../common/components";
import { PerformanceLineChart } from "../components/PerformanceLineChart";

function calculateProgressPercentage(current, prev) {
    if (prev === 0) {
        return current === 0 ? 0 : 100; // Handle division by zero
    }
    const progressPercentage = ((current - prev) / prev) * 100;
    return progressPercentage.toFixed(2) || 0;
}

export const ComparisonChart = ({
    data,
    isNoComparison,
    label,
    unit,
    isAverage,
    gridCardProps,
}) => {
    const { t } = useTranslation();

    function roundToNearest10(number) {
        return Math.ceil(number / 10) * 10;
    }

    function getHighestValue(data, key) {
        let highestValue = 0;

        for (const item of data) {
            const value = parseFloat(item[key]);
            if (value > highestValue) {
                highestValue = value;
            }
        }

        return highestValue;
    }

    function getTotalCount(arr, key = "Previous Period") {
        let count = 0;
        arr.forEach((i) => (count = count + (+i[key] || 0)));

        return count;
    }

    function getAverageCount(arr, key = "Previous Period") {
        let num = 0;
        let count = 0;
        arr.forEach((i) => {
            num = num + 1;
            count = count + (+i[key] || 0);
        });

        return count / num || 0;
    }

    const highestTotalSales = roundToNearest10(
        Math.max(
            getHighestValue(data, "Previous Period"),
            getHighestValue(data, "Current Period")
        )
    );

    const currentPeriodTotal = isAverage
        ? +getAverageCount(data, "Current Period").toFixed(2)
        : +getTotalCount(data, "Current Period").toFixed(2);
    const prevPeriodTotal = isAverage
        ? +getAverageCount(data, "Previous Period").toFixed(2)
        : +getTotalCount(data, "Previous Period").toFixed(2);

    const perc_rate = calculateProgressPercentage(
        currentPeriodTotal,
        prevPeriodTotal
    );
    const isDrop = perc_rate < 0;

    return (
        <GridCard gutter height={400} padding={0} {...gridCardProps}>
            <PerformanceLineChart
                title={label}
                data={data || []}
                periods={{
                    percentage: Number.isNaN(perc_rate) ? 0 : perc_rate,
                    currentTotal: `${currentPeriodTotal} ${unit}`,
                    prevTotal: `${prevPeriodTotal} ${unit}`,
                }}
                yMax={highestTotalSales}
                isDrop={isDrop}
                isNoComparison={isNoComparison}
                hidePerc={+prevPeriodTotal === 0}
                unit={unit}
            />
        </GridCard>
    );
};
