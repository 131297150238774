import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GridCard, OutlineButton } from "../../../../common/components";
import { CustomTextField } from "../../Pixel/PixelEvents";
import ReactDatePicker from "react-datepicker";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CreateLoseProfit = () => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const isArabic = i18n.language === "ar";

    const { handleSubmit, control } = useForm({
        defaultValues: {
            name: "",
            email: "",
        },
    });

    const onSubmit = (values) => {
        toast.success("Created Sussessfully!", {
            hideProgressBar: true,
        });

        navigate("/sales/aggregators");
    };

    const onCancel = () => navigate(-1);

    const [monthDate, setMonthDate] = useState(new Date());

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack padding={8} spacing={8} minWidth="100%">
                <Box display="flex" alignItems="center" gap={4}>
                    {isArabic ? (
                        <ArrowForwardIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => navigate("/sales/aggregators")}
                        />
                    ) : (
                        <ArrowBackIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => navigate("/sales/aggregators")}
                        />
                    )}
                    <Typography variant="h5" fontSize={30} fontWeight="bold">
                        Create New
                    </Typography>
                </Box>

                <Grid container flexWrap="nowrap">
                    <GridCard
                        fullWidth
                        boxStyles={{ minHeight: 420, height: "fit-content" }}
                    >
                        <Stack p={8} spacing={4}>
                            <Stack spacing={6}>
                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            Channel Name
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={
                                                        "Enter Channel Name"
                                                    }
                                                    variant="outlined"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            Revenue
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Controller
                                            name="revenue"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={
                                                        "Enter Revenue"
                                                    }
                                                    variant="outlined"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            Food Costs
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Controller
                                            name="foodcosts"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={
                                                        "Enter Food Costs"
                                                    }
                                                    variant="outlined"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            Delivery Fees
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Controller
                                            name="deliveryfees"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={
                                                        "Enter Delivery Fees"
                                                    }
                                                    variant="outlined"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            AdCosts
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Controller
                                            name="adcosts"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={
                                                        "Enter AdCosts"
                                                    }
                                                    variant="outlined"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            Compensations
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Controller
                                            name="compensations"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={
                                                        "Enter Compensations"
                                                    }
                                                    variant="outlined"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            Commission
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Controller
                                            name="commission"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={
                                                        "Enter Commission"
                                                    }
                                                    variant="outlined"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            Date
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <ReactDatePicker
                                            selected={monthDate}
                                            onChange={(value) =>
                                                setMonthDate(value)
                                            }
                                            disabledKeyboardNavigation
                                            showYearDropdown
                                            scrollableYearDropdown
                                            customInput={
                                                <CustomTextField fullWidth />
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Stack>
                        </Stack>
                    </GridCard>
                </Grid>
                <Box
                    display="flex"
                    alignItems="center"
                    gap={4}
                    justifyContent="flex-end"
                >
                    <OutlineButton
                        onClick={onCancel}
                        buttonStyles={{ borderRadius: "6px", width: 80 }}
                    >
                        {t("common.cancel")}
                    </OutlineButton>
                    <OutlineButton
                        buttonStyles={{
                            borderRadius: "6px",
                            width: 80,
                            color: "white",
                            backgroundColor: "#5BCFDE",
                            "&:hover": {
                                border: "1px solid #EEEFF4",
                                backgroundColor: "#5BCFDE",
                            },
                        }}
                        buttonProps={{ type: "submit" }}
                    >
                        {t("common.create")}
                    </OutlineButton>
                </Box>
            </Stack>
        </form>
    );
};

export { CreateLoseProfit };
