import {
    Box,
    Button,
    Divider,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    tableCellClasses,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { numberWithCommas } from "../../../../../common/utils/numberWithCommas";

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#FAFCFE",
        color: "#999999",
        minWidth: "135px !important",
    },
    [`&.${tableCellClasses.root}`]: {
        lineHeight: 1,
        paddingLeft: 24,
        minWidth: "120px",
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.root}`]: {
        lineHeight: 1,
        paddingLeft: 24,
    },
}));

const invoiceData = [
    {
        channelName: "Hungerstation",
        revenue: "7000 SAR",
        foodCosts: "1500 SAR",
        deliveryFees: "300 SAR",
        adCosts: "400 SAR",
        compensations: "80 SAR",
        commission: "700 SAR",
        pnl: "5020 SAR",
    },
    {
        channelName: "Ninja",
        revenue: "8000 SAR",
        foodCosts: "2000 SAR",
        deliveryFees: "400 SAR",
        adCosts: "500 SAR",
        compensations: "90 SAR",
        commission: "800 SAR",
        pnl: "5210 SAR",
    },
    {
        channelName: "Mrsool",
        revenue: "5000 SAR",
        foodCosts: "1000 SAR",
        deliveryFees: "200 SAR",
        adCosts: "300 SAR",
        compensations: "50 SAR",
        commission: "500 SAR",
        pnl: "3950 SAR",
    },
    {
        channelName: "Jahez",
        revenue: "12000.00 SAR",
        foodCosts: "4000.00 SAR",
        deliveryFees: "800.00 SAR",
        adCosts: "900.00 SAR",
        compensations: "170.00 SAR",
        commission: "1200.00 SAR",
        pnl: "5930.00 SAR",
    },
];

const PLTable = ({ data }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const navigate = useNavigate();

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Typography fontWeight="bold" fontSize={20} color="#202020">
                    {/* {t("overview.top_products")}     */}
                    Profit & lose statement
                </Typography>

                <Stack flexDirection="row" alignItems="center" gap={4}>
                    <Button
                        variant="outlined"
                        sx={{
                            height: 26,
                            minWidth: 83,
                            borderRadius: 2,
                            color: "#434854",
                            borderColor: "#edecef",
                            textTransform: "capitalize",
                        }}
                        onClick={() => navigate("/pl/history")}
                    >
                        history
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{
                            height: 26,
                            minWidth: 83,
                            borderRadius: 2,
                            color: "#434854",
                            borderColor: "#edecef",
                            textTransform: "capitalize",
                        }}
                        onClick={() => navigate("/pl/create")}
                    >
                        add
                    </Button>
                </Stack>
            </Box>

            <Divider />

            <Box>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableHeadCell
                                    colSpan={6}
                                    align={isArabic ? "right" : "left"}
                                >
                                    channel name
                                </StyledTableHeadCell>
                                <StyledTableHeadCell
                                    align={isArabic ? "right" : "left"}
                                    colSpan={4}
                                >
                                    revenue
                                </StyledTableHeadCell>
                                <StyledTableHeadCell
                                    align={isArabic ? "right" : "left"}
                                >
                                    food costs
                                </StyledTableHeadCell>
                                <StyledTableHeadCell
                                    align={isArabic ? "right" : "left"}
                                >
                                    delivery fees
                                </StyledTableHeadCell>
                                <StyledTableHeadCell
                                    align={isArabic ? "right" : "left"}
                                >
                                    ad costs
                                </StyledTableHeadCell>
                                <StyledTableHeadCell
                                    align={isArabic ? "right" : "left"}
                                >
                                    compensations
                                </StyledTableHeadCell>
                                <StyledTableHeadCell
                                    align={isArabic ? "right" : "left"}
                                >
                                    commision
                                </StyledTableHeadCell>
                                <StyledTableHeadCell
                                    align={isArabic ? "right" : "left"}
                                >
                                    P&L
                                </StyledTableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!invoiceData?.length &&
                                Array.from(Array(4).keys()).map((i) => (
                                    <TableRow
                                        key={i}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                            colSpan={6}
                                        >
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align={isArabic ? "right" : "left"}
                                            colSpan={4}
                                        >
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align={isArabic ? "right" : "left"}
                                        >
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align={isArabic ? "right" : "left"}
                                        >
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align={isArabic ? "right" : "left"}
                                        >
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align={isArabic ? "right" : "left"}
                                        >
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align={isArabic ? "right" : "left"}
                                        >
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align={isArabic ? "right" : "left"}
                                        >
                                            -
                                        </StyledTableCell>
                                    </TableRow>
                                ))}

                            {invoiceData?.map((row) => (
                                <TableRow
                                    key={row.channelName}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <StyledTableCell
                                        component="th"
                                        scope="row"
                                        colSpan={6}
                                        align={isArabic ? "right" : "left"}
                                    >
                                        {row.channelName}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align={isArabic ? "right" : "left"}
                                        colSpan={4}
                                        sx={{ color: "#71767C" }}
                                    >
                                        {" "}
                                        {row.revenue}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align={isArabic ? "right" : "left"}
                                    >
                                        {row.foodCosts}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align={isArabic ? "right" : "left"}
                                        sx={{ color: "#71767C" }}
                                    >
                                        {row.deliveryFees}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align={isArabic ? "right" : "left"}
                                        sx={{ color: "#71767C" }}
                                    >
                                        {row.adCosts}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align={isArabic ? "right" : "left"}
                                        sx={{ color: "#71767C" }}
                                    >
                                        {row.compensations}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align={isArabic ? "right" : "left"}
                                        sx={{ color: "#71767C" }}
                                    >
                                        {row.commission}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align={isArabic ? "right" : "left"}
                                        sx={{ color: "#71767C" }}
                                    >
                                        {row.pnl}
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Stack>
    );
};

export { PLTable };
