import {
    Box,
    Button,
    IconButton,
    Modal,
    Stack,
    Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { CustomTextField } from "../TextField";
import { useMutation } from "@tanstack/react-query";
import LogoutIcon from "@mui/icons-material/Logout";
import { services } from "../../../App/manager/services";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { constants } from "../../../config/constants";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -30%)",
    width: 510,
    height: 280,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 10,
    borderRadius: 4,
};

const PhoneModal = ({ isOpen, handleClose }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { mutate: updateCurrentProfile } = useMutation({
        mutationFn: services.updateCurrentProfile,
        onSuccess: (res) => {
            if (!res.data.error) {
                toast.success(t("account.profile_updated"), {
                    hideProgressBar: true,
                });

                window.location.reload();
            }
        },
        onError: (err) => console.log(err),
    });
    const { handleSubmit, control, watch } = useForm({
        defaultValues: {
            phone: "",
        },
    });
    const onSubmit = (values) => {
        const reqData = { phone: values.phone };

        updateCurrentProfile(reqData);
    };

    const handleLogout = () => {
        localStorage.removeItem(constants.storage.token);
        sessionStorage.removeItem(constants.storage.token);
        localStorage.removeItem(constants.storage.user);
        sessionStorage.removeItem(constants.storage.token);

        navigate("/login");
    };

    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={style}>
                    <Box display="flex" gap={3}>
                        {/* <Box position="relative" top={-4}>
                        <img src="/alert_red.svg" alt="warning" />
                    </Box> */}
                        <Stack spacing={5} flex={1}>
                            <Box display="flex" alignItems="center" gap={2}>
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                    align="left"
                                >
                                    {t("common.add_phone")}
                                </Typography>
                            </Box>
                            <Typography
                                id="modal-modal-title"
                                fontSize={14}
                                align="left"
                                color="#6B7280"
                            >
                                {t("common.add_phone_desc")}
                            </Typography>

                            <Stack spacing={1}>
                                <Controller
                                    name="phone"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomTextField
                                            placeholder={t(
                                                "common.phone_placeholder"
                                            )}
                                            variant="outlined"
                                            {...field}
                                        />
                                    )}
                                />
                            </Stack>
                        </Stack>
                    </Box>

                    <Box
                        display="flex"
                        mt={4}
                        justifyContent="flex-end"
                        gap={5}
                    >
                        <Button
                            variant="outlined"
                            fullWidth
                            sx={{
                                textTransform: "capitalize",
                                borderRadius: "6px",
                                // width: "fit-content",
                                color: "white",
                                backgroundColor: "#3498db",
                                border: "1px solid #3498db",
                                "&:hover": {
                                    border: "1px solid #3498db",
                                    backgroundColor: "#3498db",
                                },
                            }}
                            type="submit"
                            disabled={watch("phone") === ""}
                            // onClick={() => handleDeleteOutlier(outlier)}
                        >
                            {t("account.save")}
                        </Button>
                    </Box>

                    <IconButton
                        aria-label="delete"
                        sx={{ position: "absolute", top: 10, right: 10 }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </form>
        </Modal>
    );
};

export { PhoneModal };
