import { useState } from "react";
import {
    Box,
    Stack,
    ClickAwayListener,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";
import { getUserStorage } from "../../utils";
import { constants } from "../../../config/constants";
import { SideNavItemExtend } from "./SideNavItemExtend";
import { SideNavItem } from "./SideNavItem";
import { useSideNavMenuItems } from "./useSidebarItems";

// Logo Component
const ExtendArabicLogo = () => (
    <Box display="flex">
        <img
            src="/logo_small.svg"
            width="176px"
            height="31px"
            alt="logo"
            style={{ position: "absolute", left: 0, right: "-42px", top: 0 }}
        />
        <img
            src="/logo_ballurh_ar.svg"
            width="200px"
            height="40px"
            alt="logo"
            style={{ position: "relative", top: -6, right: -8 }}
        />
    </Box>
);

const SidebarLogo = ({ isFullSidebar, isArabic, toggleSidebar }) => (
    <Box
        display="flex"
        alignItems="center"
        justifyContent={
            isFullSidebar ? (isArabic ? "right" : "left") : "center"
        }
        paddingLeft={isFullSidebar && !isArabic ? 8 : 0}
        paddingRight={isFullSidebar && isArabic ? 8 : 0}
        width="100%"
        marginTop="34px"
        position="relative"
        sx={{ cursor: "pointer" }}
        onClick={toggleSidebar}
    >
        {isFullSidebar ? (
            isArabic ? (
                <ExtendArabicLogo />
            ) : (
                <img
                    src="/logo_extend.svg"
                    width="176px"
                    height="31px"
                    alt="logo"
                />
            )
        ) : (
            <img
                src="/logo_small.svg"
                width="32px"
                height="31px"
                alt="logo"
                style={{
                    position: "relative",
                    left: isArabic ? 0 : "-4px",
                    right: isArabic ? "-4px" : 0,
                }}
            />
        )}
        {isFullSidebar ? (
            isArabic ? (
                <ArrowForwardIosIcon
                    sx={{
                        color: "white",
                        position: "absolute",
                        top: 4,
                        left: 32,
                    }}
                />
            ) : (
                <ArrowBackIosNewIcon
                    sx={{
                        color: "white",
                        position: "absolute",
                        top: 8,
                        right: 32,
                    }}
                />
            )
        ) : isArabic ? (
            <ArrowBackIosNewIcon
                sx={{
                    color: "white",
                    fontSize: "14px",
                    position: "absolute",
                    top: 8,
                    left: 8,
                }}
            />
        ) : (
            <ArrowForwardIosIcon
                sx={{
                    color: "white",
                    fontSize: "14px",
                    position: "absolute",
                    top: 8,
                    right: 8,
                }}
            />
        )}
    </Box>
);

const SidebarItems = ({
    isFullSidebar,
    sideNavMenuItems,
    ownerNavMenuItems,
    groupOwnerNavMenuItems,
    pathname,
    isOwner,
    isGroupOwner,
    changeLanguage,
    t,
}) => (
    <Stack spacing={3} paddingY={6} pt={20} alignItems="center">
        {/* Overview Item */}
        {isFullSidebar ? (
            <SideNavItemExtend
                isActive={pathname.includes("overview") || pathname === "/"}
                iconPath={
                    pathname.includes("overview")
                        ? "/ic_overview_selected.svg"
                        : "/ic_overview.svg"
                }
                to="overview"
                label={t("overview.overview")}
            />
        ) : (
            <SideNavItem
                iconPath={
                    pathname.includes("overview") || pathname === "/"
                        ? "/ic_overview_selected.svg"
                        : "/ic_overview.svg"
                }
                to="overview"
                label={t("overview.overview")}
            />
        )}

        {/* Render Main Navigation Items */}
        {sideNavMenuItems.map((item) => {
            const Component = isFullSidebar ? SideNavItemExtend : SideNavItem;
            return (
                <Component
                    key={item.key}
                    isActive={pathname.includes(item.key)}
                    {...item}
                />
            );
        })}

        {/* Render Owner-Specific Items */}
        {isOwner &&
            ownerNavMenuItems.map((item) => {
                const Component = isFullSidebar
                    ? SideNavItemExtend
                    : SideNavItem;
                return (
                    <Component
                        key={item.key}
                        isActive={pathname.includes(item.key)}
                        {...item}
                    />
                );
            })}

        {/* Render Group Owner-Specific Items */}
        {isGroupOwner &&
            groupOwnerNavMenuItems.map((item) => {
                const Component = isFullSidebar
                    ? SideNavItemExtend
                    : SideNavItem;
                return (
                    <Component
                        key={item.key}
                        isActive={pathname.includes(item.key)}
                        {...item}
                    />
                );
            })}

        {/* Language Selector */}
        <Box height={30} />
        {isFullSidebar ? (
            <SideNavItemExtend
                iconPath="/ic_language.svg"
                label={t("common.language")}
                subItems={[
                    {
                        label: t("common.english"),
                        onClick: () => changeLanguage("en"),
                        iconPath: "/english.svg",
                    },
                    {
                        label: t("common.arabic"),
                        onClick: () => changeLanguage("ar"),
                        iconPath: "/arabic.svg",
                    },
                ]}
            />
        ) : (
            <SideNavItem
                iconPath="/ic_language.svg"
                label={t("common.language")}
                subItems={[
                    {
                        label: t("common.english"),
                        onClick: () => changeLanguage("en"),
                        iconPath: "/english.svg",
                    },
                    {
                        label: t("common.arabic"),
                        onClick: () => changeLanguage("ar"),
                        iconPath: "/arabic.svg",
                    },
                ]}
            />
        )}
    </Stack>
);

const SideNavbar = () => {
    const user = getUserStorage();
    const { pathname } = useLocation();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.only("xs"));
    const [isFullSidebar, setIsFullSidebar] = useState(false);

    const minimizedWidth = isMobileView ? "75px" : "100px";
    const fullSizeWidth = isMobileView ? "275px" : "300px";

    const isOwner = user.role === 1;
    const isGroupOwner = user.role === 9;

    const isInBallurhAssistant = pathname.includes("assistant");

    const { sideNavMenuItems, groupOwnerNavMenuItems, ownerNavMenuItems } =
        useSideNavMenuItems();

    const changeLanguage = (lang) => {
        localStorage.setItem(constants.storage.language, lang);
        i18n.changeLanguage(lang);
        window.location.reload();
    };

    return (
        <ClickAwayListener onClickAway={() => setIsFullSidebar(false)}>
            <Stack
                minHeight={!isInBallurhAssistant && "100vh"}
                maxHeight={isInBallurhAssistant && "100vh"}
                overflow={isInBallurhAssistant && "scroll"}
                minWidth={isFullSidebar ? fullSizeWidth : minimizedWidth}
                top={0}
                bottom={0}
                position="static"
                bgcolor="#06262D"
                zIndex={1000}
                style={{ transition: "min-width .1s ease" }}
            >
                {/* Sidebar Logo */}
                <SidebarLogo
                    isFullSidebar={isFullSidebar}
                    isArabic={isArabic}
                    toggleSidebar={() => setIsFullSidebar(!isFullSidebar)}
                />

                {/* Sidebar Items */}
                <SidebarItems
                    isFullSidebar={isFullSidebar}
                    sideNavMenuItems={sideNavMenuItems}
                    ownerNavMenuItems={ownerNavMenuItems}
                    groupOwnerNavMenuItems={groupOwnerNavMenuItems}
                    pathname={pathname}
                    isOwner={isOwner}
                    isGroupOwner={isGroupOwner}
                    changeLanguage={changeLanguage}
                    t={t}
                />
            </Stack>
        </ClickAwayListener>
    );
};

export { SideNavbar };
