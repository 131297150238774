import {
    Box,
    Divider,
    Stack,
    Typography,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridCardLabels } from "../../../../../common/components";
import { useEffect, useState } from "react";

const BarColors = {
    "#9FE3B9": {
        backgroundColor: "#339e73",
        "&:after": {
            backgroundColor: "#48d89d",
        },
        "&:before": {
            backgroundColor: "#3fc08b",
        },
    },
    "#06262D": {
        backgroundColor: "#03181b",
        "&:after": {
            backgroundColor: "#104450",
        },
        "&:before": {
            backgroundColor: "#06262d",
        },
    },
    // will be in the else
    "#E3E28D": {
        backgroundColor: "#d5d368",
        "&:after": {
            backgroundColor: "#fbf999",
        },
        "&:before": {
            backgroundColor: "#e3e28d",
        },
    },
    "#E3E3E3": {
        backgroundColor: "#c1c1c1",
        "&:after": {
            backgroundColor: "#f4f4f4",
        },
        "&:before": {
            backgroundColor: "#d5d5d5",
        },
    },
};

const OrderTypes = ({ data }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.only("xs"));

    const total = data?.reduce((acc, datum) => acc + datum.value, 0);

    const [barsData, setBarsData] = useState(null);

    const getBarHeight = (value) => {
        if (value < 15) return `${(200 * value) / 100 + 25}px`;

        return `${(200 * value) / 100}px`;
    };

    useEffect(() => {
        if (data?.length) {
            const refinedChartData = [];

            data.forEach((item, index) => {
                if (BarColors[item.fill])
                    refinedChartData.push({
                        sx: {
                            ...BarColors[item.fill],
                            height: getBarHeight(item.value),
                        },
                        className: `bar-${index}`,
                        ...item,
                    });
                else {
                    refinedChartData.push({
                        sx: {
                            ...BarColors["#E3E28D"],
                            height: getBarHeight(item.value),
                        },
                        className: `bar-${index}`,
                        ...item,
                        fill: "#E3E28D",
                    });
                }
            });

            setBarsData(refinedChartData);
        }
    }, [data]);

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Typography fontWeight="bold" fontSize={20} color="#202020">
                    {t("overview.order_types")}
                </Typography>
            </Box>

            <Divider />

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                flexWrap={isMobileView && "wrap"}
                maxWidth={750}
            >
                <Box width="5%"></Box>
                <GridCardLabels
                    data={barsData?.map((label) => ({
                        ...label,
                        name: t(`charts.category.${label.name}`),
                    }))}
                />

                <Box
                    width="50%"
                    maxWidth="350px"
                    height="400px"
                    position="relative"
                >
                    {barsData?.map((bar) => (
                        <Box
                            className={`bar ${bar.className}`}
                            sx={{ ...bar.sx }}
                        >
                            <Typography
                                size={16}
                                color="white"
                                position="relative"
                                left={isArabic ? -8 : 8}
                                bottom={0}
                                zIndex={1000}
                            >
                                {isArabic ? `%${bar.value}` : `${bar.value}%`}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Stack>
    );
};

export { OrderTypes };
