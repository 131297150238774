import { Box, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { VerticalBarChart } from "../../components/VerticalBarChart";

import { GridCardFlatLabels } from "../../../../../common/components";

const Categories = ({ data }) => {
    const { t } = useTranslation();

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Typography fontWeight="bold" fontSize={20} color="#202020">
                    {t("overview.categories")}
                </Typography>
            </Box>

            <Divider />

            <Stack>
                <GridCardFlatLabels data={data} isFlat />

                <Box width="100%" height="300px" px={6}>
                    <VerticalBarChart data={data} />
                </Box>
            </Stack>
        </Stack>
    );
};

export { Categories };
