import {
    Box,
    Chip,
    List,
    ListItemButton,
    ListItemText,
    Popover as MuiPopover,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { styled } from "@mui/material/styles";

const Popover = styled(MuiPopover)({
    "& .MuiPaper-root": {
        //   color: 'green',
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 16,
        overflow: "visible",
    },
    "& .MuiList-padding": {
        padding: 0,
    },
});

export const SideNavItem = ({
    iconPath,
    to,
    label,
    subItems = [],
    onClick,
    marginTop = 0,
    isDisabled,
    isSoon,
    isBeta,
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const [openedPopover, setOpenedPopover] = useState(false);
    const popoverAnchor = useRef(null);

    const navigate = useNavigate();

    const handlePopoverOpen = (event) => {
        !isDisabled && setOpenedPopover(true);
    };

    const handlePopoverClose = () => {
        setOpenedPopover(false);
    };

    const handleClick = () => {
        if (onClick) onClick();
        else if (to) navigate(to);
    };

    return (
        <Box
            sx={{ cursor: "pointer" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="60px"
            width="100px"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            position="relative"
            ref={popoverAnchor}
            onClick={isDisabled ? () => null : handleClick}
            marginTop={marginTop}
        >
            <Box
                backgroundColor={openedPopover && "#02161A"}
                padding={0}
                borderRadius={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ position: "relative", left: -3 }}
            >
                {isSoon && (
                    <Box
                        position="absolute"
                        backgroundColor="#3498db"
                        borderRadius="3px"
                        padding="0px 4px"
                        color="white"
                        fontSize="10px"
                        top={0}
                        left={isArabic ? 16 : -20}
                    >
                        Soon
                    </Box>
                )}
                {isBeta && (
                    <Box
                        position="absolute"
                        backgroundColor="#1abc9c"
                        borderRadius="3px"
                        padding="0px 4px"
                        color="white"
                        fontSize="10px"
                        top={0}
                        left={isArabic ? 16 : -20}
                    >
                        Beta
                    </Box>
                )}
                <img src={iconPath} alt="sideNavBarIcon" />
            </Box>

            <Popover
                // open
                open={openedPopover && subItems.length}
                sx={{
                    pointerEvents: "none",
                    marginLeft: isArabic ? -18 : -5,
                    marginTop: -5,
                }}
                anchorEl={popoverAnchor.current}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                onClose={handlePopoverClose}
                onMouseEnter={handlePopoverOpen}
                PaperProps={{
                    onMouseEnter: handlePopoverOpen,
                    onMouseLeave: handlePopoverClose,
                }}
                disableRestoreFocus
            >
                <Box
                    sx={{
                        pointerEvents: "auto",
                        position: "relative",
                        zIndex: 10,
                    }}
                >
                    {isArabic ? (
                        <ArrowRightIcon
                            sx={{
                                color: "white",
                                position: "absolute",
                                top: -8,
                                right: -14,
                            }}
                        />
                    ) : (
                        <ArrowLeftIcon
                            sx={{
                                color: "white",
                                position: "absolute",
                                top: -8,
                                left: -14,
                            }}
                        />
                    )}
                    <List sx={{ minWidth: "300px" }}>
                        {subItems.map((subItem) => (
                            <ListItemButton
                                sx={{
                                    height: "fit-content",
                                    paddingY: 1,
                                    direction: isArabic ? "rtl" : "ltr",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    subItem.onClick
                                        ? subItem.onClick()
                                        : navigate(subItem.to);
                                }}
                                key={subItem.to}
                                disabled={subItem.isDisabled}
                            >
                                <Box
                                    paddingLeft={4}
                                    paddingRight={isArabic ? 4 : 0}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    {subItem.iconPath && (
                                        <img
                                            width="22px"
                                            height="24px"
                                            src={subItem.iconPath}
                                            alt="sideNavBarIcon"
                                        />
                                    )}
                                </Box>
                                <ListItemText
                                    sx={{
                                        paddingLeft: 6,
                                        marginBottom: "2px",
                                        textAlign: isArabic ? "right" : "left",
                                    }}
                                    primaryTypographyProps={{
                                        fontSize: 16,
                                        color: "#202020",
                                    }}
                                    primary={subItem.label}
                                />
                                {subItem.isSoon && (
                                    <Box
                                        // position="absolute"
                                        backgroundColor="#3498db"
                                        borderRadius="3px"
                                        padding="0px 4px"
                                        color="white"
                                        fontSize="10px"
                                        // top={0}
                                        // left={isArabic ? 16 : -20}
                                    >
                                        Soon
                                    </Box>
                                )}
                                {subItem.isBeta && (
                                    <Box
                                        // position="absolute"
                                        backgroundColor="#1abc9c"
                                        borderRadius="3px"
                                        padding="0px 4px"
                                        color="white"
                                        fontSize="10px"
                                        // top={0}
                                        // left={isArabic ? 16 : -20}
                                    >
                                        Beta
                                    </Box>
                                )}
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
            </Popover>
        </Box>
    );
};
