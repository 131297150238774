import { Box, Divider, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
    GridCardFlatLabels,
    GridCardHeader,
} from "../../../../../common/components";
import { VerticalBarChart } from "../../components/VerticalBarChart";

const PaymentMethods = ({ data }) => {
    const { t } = useTranslation();

    return (
        <Stack
            sx={{
                pageBreakInside: "avoid",
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={50}
            >
                <GridCardHeader title={t("overview.payment_methods")} />
            </Box>

            <Divider />

            <Stack>
                <GridCardFlatLabels data={data} isFlat />

                <Box width="100%" height="300px" px={6}>
                    <VerticalBarChart data={data} />
                </Box>
            </Stack>
        </Stack>
    );
};

export { PaymentMethods };
