import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomTextField = styled(TextField)({
    "& label.Mui-focused": {
        //   color: 'green',
    },
    "& .MuiInput-underline:after": {
        //   borderBottomColor: 'green',
    },
    "& .MuiOutlinedInput-root": {
        height: 40,
        borderRadius: 6,
    },
    "& .MuiInputBase-input": {
        height: 8,
    },
});
