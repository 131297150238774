import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    LabelList,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
} from "recharts";

const CustomizedXAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
        <text x={x} y={y} dy={16} textAnchor="middle" fill="#202020">
            {payload.value}
        </text>
    );
};

const CustomizedLabel = ({ x, y, value, width, hidePercentages }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    if (hidePercentages) return null;

    return (
        <text
            x={isArabic ? x + width - 5 : x + width + 5}
            y={y + 10}
            fill="#00010A"
            textAnchor="start"
            fontSize={12}
        >
            {isArabic ? `%${value}` : `${value}%`}
        </text>
    );
};

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const dataPoint = payload[0].payload;

        return (
            <div className="custom-tooltip">
                <Stack>
                    <Typography fontSize="16px" fontWeight="bold">
                        {+dataPoint.value + 15} Products
                    </Typography>
                    <Typography
                        color="#818992"
                        fontSize="14px"
                        fontWeight="bold"
                    >
                        ({dataPoint.value}) %
                    </Typography>

                    <div
                        style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: dataPoint.fill,
                                width: "16px",
                                height: "16px",
                            }}
                        />

                        <Typography color="#06262D" fontSize="12px">
                            {dataPoint.name.split("_")[0]}
                        </Typography>
                    </div>
                </Stack>
            </div>
        );
    }
    return null;
};

const VerticalBarChart = ({ data, barSize = 10, margin, yAxisProps }) => {
    const [chartData, setChartData] = useState();

    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    useEffect(() => {
        if (data?.length) setChartData(addUniqueIdentifiers(data));
    }, [data]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                layout="vertical"
                width={500}
                height={400}
                data={chartData?.map((d) => ({
                    ...d,
                    value: Number(d.value).toFixed(2),
                }))}
                margin={
                    margin || {
                        top: 20,
                        right: isArabic ? -55 : 0,
                        bottom: 20,
                        left: isArabic ? 0 : -55,
                    }
                }
            >
                <CartesianGrid stroke="#EEEFF4" />
                <XAxis
                    type="number"
                    tickLine={false}
                    stroke="#EEEFF4"
                    tick={<CustomizedXAxisTick />}
                    // domain={[0, 100]}
                    ticks={[0, 20, 40, 60, 80, 100]}
                    reversed={isArabic}
                />
                <YAxis
                    dataKey="name"
                    type="category"
                    scale="band"
                    tick={false}
                    stroke="#EEEFF4"
                    orientation={isArabic ? "right" : "left"}
                    tickFormatter={(value) => {
                        // Extract the original name from the unique identifier
                        const originalName = value.split("_")[0]; // Assumes the identifier format is "originalName_count"
                        return originalName;
                    }}
                    {...yAxisProps}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                    dataKey="value"
                    barSize={
                        data?.length > 5 ? barSize : data?.length <= 2 ? 25 : 15
                    }
                    fill="#413ea0"
                >
                    <LabelList
                        dataKey="value"
                        content={
                            <CustomizedLabel
                                hidePercentages={data?.length > 10}
                            />
                        }
                    />
                </Bar>
            </ComposedChart>
        </ResponsiveContainer>
    );
};

// Function to add unique identifiers to data points
function addUniqueIdentifiers(data) {
    const uniqueData = [];
    const nameCounts = {};

    for (const item of data) {
        const { name, value } = item;
        const count = nameCounts[name] || 0;
        const uniqueName = `${name}_${count}`;
        nameCounts[name] = count + 1;
        uniqueData.push({ name: uniqueName, value, fill: item.fill });
    }

    return uniqueData;
}

export { VerticalBarChart };
