import { Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useMutation } from "@tanstack/react-query";
import { AuthServices } from "../../../../Auth/services";
import { useEffect, useState } from "react";
import { constants } from "../../../../config/constants";
import { useNavigate } from "react-router-dom";

const PaymentBranchSuccess = () => {
    const [isSuccess, setIsSucces] = useState(false);
    const navigate = useNavigate();

    const { mutate: finalizePayment, isLoading } = useMutation({
        mutationFn: AuthServices.paymentSuccess,
        onSuccess: (res) => {
            if (!res.data.error) {
                setIsSucces(true);

                localStorage.removeItem(constants.storage.payment_branch);
                setTimeout(() => {
                    navigate("/overview");
                }, 2000);
            }
        },
        onError: (err) => console.log(err),
    });

    useEffect(() => {
        const addBranchData = JSON.parse(
            localStorage.getItem(constants.storage.payment_branch)
        );

        if (!addBranchData) navigate("/overview");
        else {
            finalizePayment({ payment_type: "add_branch" });
        }
    }, []);

    return (
        <>
            {isSuccess && (
                <Stack
                    width="100%"
                    height="100vh"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    spacing={4}
                >
                    <CheckCircleIcon sx={{ fontSize: 160, color: "green" }} />
                    <Typography variant="h6" color="green">
                        Payment Successful
                    </Typography>
                    <Typography fontSize="14px" color="gray">
                        you will be redirected to Ballurh again shortly
                    </Typography>
                </Stack>
            )}
        </>
    );
};

export { PaymentBranchSuccess };
