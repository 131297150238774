import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItemButton,
    ListItemText,
    Popover,
    Stack,
    Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { services } from "../../../manager/services";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

const ConfirmedTag = ({ label }) => (
    <Box
        height={26}
        backgroundColor="#effff4"
        border="1px solid"
        borderColor="#d9f3e1"
        borderRadius={4}
        display="flex"
        alignItems="center"
        px={2}
    >
        <Typography fontSize={14} color="#177F3D">
            {label}
        </Typography>
    </Box>
);

const InvitationSentTag = ({ label }) => {
    const { t } = useTranslation();

    return (
        <Box
            height={26}
            backgroundColor="#fff7ed"
            border="1px solid"
            borderColor="#F97316"
            borderRadius={4}
            display="flex"
            alignItems="center"
            px={2}
        >
            <Typography fontSize={14} color="#F97316">
                {t("settings.invitations_sent")}
            </Typography>
        </Box>
    );
};

const SettingsOwnerCard = ({ owner, gutter, onUserDelete }) => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const { mutate: deleteOwner, isLoading } = useMutation({
        mutationFn: services.deleteOwner,
        onSuccess: (res) => {
            if (!res.data.error) {
                const response = res.data.response;

                toast.success(res.data.response);
                onUserDelete && onUserDelete(owner.id);
            } else {
                toast.error(res.data.message);
            }
        },
        onError: (err) => console.log(err),
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleUpdate = () => {
        sessionStorage.setItem(
            "toBeEditedManager",
            JSON.stringify({
                id: owner.id,
                name: owner.first_name,
                email: owner.email,
                phone: owner.phone,
                ...(owner.role === 9
                    ? { merchant_ids: owner.group?.map((g) => g.merchant_id) }
                    : { merchant_id: owner.merchant?.merchant_id }),
            })
        );

        owner.role === 9 ? navigate("newGroup") : navigate("new");
    };

    const handleDelete = () => {
        deleteOwner(owner.id);
    };

    return (
        <Box paddingRight={gutter ? 6 : 0} paddingBottom={6}>
            <Stack
                border="1px solid #E5E7EB"
                width="100%"
                height={270}
                borderRadius={2}
                overflow="hidden"
            >
                {/* Card Header */}
                <Box
                    display="flex"
                    alignItems="center"
                    padding={5}
                    borderBottom="1px solid #E5E7EB"
                    backgroundColor="#F9FAFB"
                    height={86}
                >
                    {/* Header Logo */}
                    <Box display="flex" alignItems="center" gap={3} flex={1}>
                        <Box>
                            <Avatar
                                alt="owner logo"
                                sx={{ width: 40, height: 40 }}
                            >
                                {owner?.first_name?.charAt(0)}
                            </Avatar>
                        </Box>

                        <Stack spacing={0}>
                            <Typography fontSize={20} fontWeight="bold">
                                {owner?.first_name}
                            </Typography>
                            <Typography fontSize={16} color="#6B7280">
                                {owner.role === 9
                                    ? t("settings.manager")
                                    : t("settings.owner")}
                            </Typography>
                        </Stack>
                    </Box>

                    <Box display="flex" gap={4} alignItems="center">
                        <IconButton color="primary" onClick={handleClick}>
                            <MoreHorizIcon sx={{ color: "#9CA3AF" }} />
                        </IconButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            sx={{ marginTop: 1 }}
                        >
                            <List sx={{ width: "220px" }}>
                                <ListItemButton
                                    sx={{ height: 32 }}
                                    onClick={handleUpdate}
                                >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 14,
                                        }}
                                        primary={
                                            owner.role === 9
                                                ? t("settings.edit_manager")
                                                : t("settings.edit_owner")
                                        }
                                    />
                                </ListItemButton>
                                <ListItemButton
                                    sx={{ height: 32 }}
                                    onClick={handleDelete}
                                >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 14,
                                        }}
                                        primary={t("common.delete")}
                                    />
                                </ListItemButton>
                            </List>
                        </Popover>
                    </Box>
                    {/* Header Actions */}
                </Box>

                {/* Card Content */}
                <Stack px={5}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        py={4}
                        borderBottom="1px solid #E5E7EB"
                    >
                        <Typography fontSize={16} color="#101827">
                            {t("common.email")}
                        </Typography>
                        <Typography fontSize={16} color="#6C7381">
                            {owner.email}
                        </Typography>
                    </Box>

                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        py={4}
                        borderBottom="1px solid #E5E7EB"
                    >
                        <Typography fontSize={16} color="#101827">
                            {t("common.phone")}
                        </Typography>
                        <Typography fontSize={16} color="#6C7381">
                            {owner.phone || "-"}
                        </Typography>
                    </Box>

                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        py={4}
                    >
                        <Typography fontSize={16} color="#101827">
                            {t("common.status")}
                        </Typography>

                        {owner.confirmed ? (
                            <ConfirmedTag label="Confirmed" />
                        ) : (
                            <InvitationSentTag />
                        )}
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
};

export { SettingsOwnerCard };
