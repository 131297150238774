import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const QuestionChip = ({ label, onClick }) => {
    return (
        <Box
            onClick={() => onClick(label.slice(3))}
            sx={{
                display: "inline-block",
                padding: "4px 8px",
                backgroundColor: "#E0E0E0", // Light gray
                color: "black",
                borderRadius: "16px",
                cursor: "pointer",
                textAlign: "center",
                fontSize: "12px", // Smaller font size
                transition: "all 0.3s ease",
                "&:hover": {
                    backgroundColor: "#BDBDBD", // Slightly darker gray on hover
                    transform: "scale(1.05)",
                },
            }}
            maxWidth="fit-content"
        >
            {label}
        </Box>
    );
};

export const PredefinedQuestions = ({ onSend }) => {
    const { t } = useTranslation();

    return (
        <Box
            display="flex"
            gap={4}
            justifyContent="space-between"
            marginTop={10}
        >
            {/* Column 1 */}
            <Box
                sx={{
                    animation: "fadeIn 0.6s ease-in-out",
                    "@keyframes fadeIn": {
                        "0%": { opacity: 0, transform: "translateY(50px)" },
                        "100%": { opacity: 1, transform: "translateY(0)" },
                    },
                }}
            >
                <Stack spacing={4}>
                    {/* Section Header with Icon */}
                    <Box display="flex" alignItems="center" gap={1}>
                        <Box
                            component="span"
                            sx={{
                                fontSize: "18px", // Reduced font size for the title
                            }}
                        >
                            📊
                        </Box>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: "bold",
                                color: "primary.main",
                                fontSize: "16px", // Reduced font size for the text
                            }}
                        >
                            {t("chat.totalSalesPerformanceTitle")}
                        </Typography>
                    </Box>

                    <Stack spacing={2} paddingX={4}>
                        <QuestionChip
                            onClick={onSend}
                            label={t("chat.revenueGrowth")}
                        />
                        <QuestionChip
                            onClick={onSend}
                            label={t("chat.bestDeliveryPlatforms")}
                        />
                        <QuestionChip
                            onClick={onSend}
                            label={t("chat.momGrowth")}
                        />
                    </Stack>
                </Stack>
            </Box>

            {/* Column 2 */}
            <Box
                sx={{
                    animation: "fadeIn 0.6s ease-in-out 0.2s", // Slight delay for staggered animation
                    "@keyframes fadeIn": {
                        "0%": { opacity: 0, transform: "translateY(50px)" },
                        "100%": { opacity: 1, transform: "translateY(0)" },
                    },
                }}
            >
                <Stack spacing={4}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Box
                            component="span"
                            sx={{
                                fontSize: "18px", // Reduced font size for the icon
                            }}
                        >
                            🚚
                        </Box>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: "bold",
                                color: "primary.main",
                                fontSize: "16px", // Reduced font size for the text
                            }}
                        >
                            {t("chat.deliverySalesPerformanceTitle")}
                        </Typography>
                    </Box>

                    <Stack spacing={2} paddingX={4}>
                        <QuestionChip
                            onClick={onSend}
                            label={t("chat.todaySalesComparison")}
                        />
                        <QuestionChip
                            onClick={onSend}
                            label={t("chat.dailySalesComparison")}
                        />
                        <QuestionChip
                            onClick={onSend}
                            label={t("chat.homeDeliveryPercentage")}
                        />
                    </Stack>
                </Stack>
            </Box>

            {/* Column 3 */}
            <Box
                sx={{
                    animation: "fadeIn 0.6s ease-in-out 0.4s", // Additional delay for staggered animation
                    "@keyframes fadeIn": {
                        "0%": { opacity: 0, transform: "translateY(50px)" },
                        "100%": { opacity: 1, transform: "translateY(0)" },
                    },
                }}
            >
                <Stack spacing={4}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Box
                            component="span"
                            sx={{
                                fontSize: "18px", // Reduced font size for the icon
                            }}
                        >
                            🧾
                        </Box>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: "bold",
                                color: "primary.main",
                                fontSize: "16px", // Reduced font size for the text
                            }}
                        >
                            {t("chat.menuOptimizationTitle")}
                        </Typography>
                    </Box>

                    <Stack spacing={2} paddingX={4}>
                        <QuestionChip
                            onClick={onSend}
                            label={t("chat.bestSellingProducts")}
                        />
                        <QuestionChip
                            onClick={onSend}
                            label={t("chat.frequentlyBoughtTogether")}
                        />
                        <QuestionChip
                            onClick={onSend}
                            label={t("chat.productMixImpact")}
                        />
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
};
/*
            <Box
                        sx={{
                            width: 50,
                            height: 50,
                            opacity: 0, // Start invisible
                            animation: "grow 1s forwards",
                            "@keyframes grow": {
                                "0%": {
                                    transform: "scale(0)",
                                    opacity: 0,
                                },
                                "40%": {
                                    transform: "scale(1.2)",
                                    opacity: 0.8,
                                },
                                "65%": {
                                    transform: "scale(1)",
                                    opacity: 0.8,
                                },
                                "80%": {
                                    transform: "scale(1.2)",
                                    opacity: 0.8,
                                },
                                "100%": {
                                    transform: "scale(1)",
                                    opacity: 1,
                                },
                            },
                        }}
                    >
                        <Lottie
                            animationData={animationData}
                            loop={true}
                            style={{ width: 50, height: 50 }}
                        />
                    </Box>
*/
