import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { constants } from "../../../config/constants";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomTextField, OutlineButton } from "../../../common/components";
import { GridCard } from "../../../common/components";
import { services } from "../../manager/services";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { WorkingHours } from "../../manager/Account/WorkingHours";
import { isNumber } from "lodash";

function formatHour(hour) {
    if (isNumber(hour)) {
        if (hour < 0 || hour > 23) {
            throw new Error("Hour must be between 0 and 23");
        }

        const formattedHour = hour.toString().padStart(2, "0");
        return `${formattedHour}:00`;
    }

    return hour;
}

function parseHour(hourString) {
    if (!hourString) return;
    const [hour, minutes] = hourString.split(":").map(Number);

    if (
        Number.isNaN(hour) ||
        Number.isNaN(minutes) ||
        hour < 0 ||
        hour > 23 ||
        minutes !== 0
    ) {
        throw new Error(
            "Invalid hour string format. It should be in the format 'HH:00' and within 00:00 to 23:00."
        );
    }

    return hour;
}

const CreateBranch = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { handleSubmit, control } = useForm({
        defaultValues: {
            name: "",
            manager: "",
            sales_goal: "",
            ...JSON.parse(sessionStorage.getItem("toBeEditedBranch")),
        },
    });

    const [selectedFromTime, setSelectedFromTime] = useState(
        parseHour(
            JSON.parse(sessionStorage.getItem("toBeEditedBranch"))?.opening_from
        )
    );
    const [selectedToTime, setSelectedToTime] = useState(
        parseHour(
            JSON.parse(sessionStorage.getItem("toBeEditedBranch"))?.opening_to
        )
    );

    const { mutate: updateBranch, isLoading } = useMutation({
        mutationFn: services.updateBranch,
        onSuccess: (res) => {
            if (!res.data.error) {
                toast.success("Branch Created Sussessfully!", {
                    hideProgressBar: true,
                });
                sessionStorage.removeItem("toBeEditedBranch");

                window.open(
                    `${window.location.origin}/settings/branches`,
                    "_self"
                );
            } else {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    const onSubmit = (values) => {
        updateBranch({
            sales_goal: values.sales_goal,
            branch_id: values.id,
            opening_from: formatHour(selectedFromTime),
            opening_to: formatHour(selectedToTime),
        });
    };

    const onCancel = () => navigate(-1);

    useEffect(() => {
        return () => sessionStorage.removeItem("toBeEditedBranch");
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack padding={8} spacing={8} minWidth="100%">
                <Stack flexDirection="row" justifyContent="space-between">
                    <Typography variant="h5" fontSize={30} fontWeight="bold">
                        {t("settings.my_branches")}
                    </Typography>
                </Stack>

                <Grid container flexWrap="nowrap">
                    <GridCard
                        fullWidth
                        boxStyles={{ minHeight: 320, height: "fit-content" }}
                    >
                        <Stack p={8} spacing={4}>
                            <Stack spacing={1}>
                                <Typography color="#0F172A" fontSize={16}>
                                    {t("settings.branch_info")}
                                </Typography>
                                {/* <Typography color="#6B7280" fontSize={14}>
                                    Create your branch information.{" "}
                                </Typography> */}
                            </Stack>

                            <Stack spacing={6}>
                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            {t("settings.branch_name")}
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={t(
                                                        "settings.branch_name"
                                                    )}
                                                    variant="outlined"
                                                    disabled
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            {t("settings.sales_goal")}
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Controller
                                            name="sales_goal"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={t(
                                                        "settings.sales_goal"
                                                    )}
                                                    variant="outlined"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>

                                <WorkingHours
                                    selectedFromTime={selectedFromTime}
                                    setSelectedFromTime={setSelectedFromTime}
                                    selectedToTime={selectedToTime}
                                    setSelectedToTime={setSelectedToTime}
                                />

                                {/* <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            Account Manager name
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Autocomplete
                                            size="small"
                                            // value={selectedProduct}
                                            onChange={(e, value) =>
                                                console.log(value)
                                            }
                                            disablePortal
                                            id="combo-box-demo"
                                            options={[]}
                                            sx={{ width: 400 }}
                                            disableClearable
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{ height: 32 }}
                                                    label="Select Manager"
                                                    InputLabelProps={{
                                                        style: {
                                                            color: "#6B7280",
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box> */}
                            </Stack>
                        </Stack>
                    </GridCard>
                </Grid>
                <Box
                    display="flex"
                    alignItems="center"
                    gap={4}
                    justifyContent="flex-end"
                >
                    <OutlineButton
                        buttonStyles={{ borderRadius: "6px", width: 80 }}
                        onClick={onCancel}
                    >
                        {t("common.cancel")}
                    </OutlineButton>
                    <OutlineButton
                        buttonStyles={{
                            borderRadius: "6px",
                            width: 60,
                            color: "white",
                            backgroundColor: "#5BCFDE",
                            "&:hover": {
                                border: "1px solid #EEEFF4",
                                backgroundColor: "#5BCFDE",
                            },
                        }}
                        buttonProps={{ type: "submit" }}
                    >
                        {t("common.save")}
                    </OutlineButton>
                </Box>
            </Stack>
        </form>
    );
};

export { CreateBranch };
