import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Box,
    CircularProgress,
    Divider,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { useMutation, useQuery } from "@tanstack/react-query";
import { services } from "../../services";
import { GridCard } from "../../../../common/components";
import { useTranslation } from "react-i18next";
import { AuthServices } from "../../../../Auth/services";
import { format } from "date-fns";

const InfoRow = ({ label, value, hideComma }) => (
    <Box
        display="flex"
        alignItems="baseline"
        justifyContent="space-between"
        maxWidth={700}
        textTransform="capitalize"
    >
        <Typography fontSize={18} color="#94A3B8">
            {label}
            {hideComma ? "" : ":"}
        </Typography>

        <Typography fontSize={20} color="#06262D">
            {value}
        </Typography>
    </Box>
);

const Product = () => {
    const [firstDate, setFirstDate] = useState(null); // [1
    const { data } = useQuery({
        queryKey: ["firstSalesDate"],
        queryFn: AuthServices.getMerchantFirstDate,
        refetchOnWindowFocus: false,
    });

    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const params = useParams();
    const navigate = useNavigate();
    const [selectedProduct, setSelectedProduct] = useState(null);

    const { mutate: getProduct, isLoading } = useMutation({
        mutationFn: services.getMenuProduct,
        onSuccess: (res) => {
            if (!res.error) {
                setSelectedProduct({
                    ...res.data.response.product_info[0],
                    ingredients: res.data.response.ingredients,
                    recommends: res.data.response.top_five,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    useEffect(() => {
        if (firstDate) {
            getProduct({
                product_id: params.productId,
                from_date: firstDate,
                till_date: format(new Date(), "MM/dd/yyyy"),
            });
        }
    }, [firstDate]);

    useEffect(() => {
        if (data?.data && !data?.data?.error) {
            const firstSalesDate = new Date(
                data?.data.response.first_date
            ).toLocaleDateString("en");

            setFirstDate(firstSalesDate);
        }
    }, [data]);

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            {isLoading ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress size={25} />
                </Box>
            ) : (
                <>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography variant="h5" textTransform="capitalize">
                            {t("sales.products")}
                        </Typography>

                        <Link to="/menu/products">
                            {t("common.products_back")}
                        </Link>
                    </Box>

                    <Grid container rowGap={6}>
                        <GridCard
                            fullWidth
                            padding={0}
                            minHeight={511}
                            height="fit-content"
                        >
                            <Stack>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    padding={6}
                                    height={74}
                                >
                                    <Typography
                                        fontWeight="bold"
                                        fontSize={20}
                                        color="#202020"
                                    >
                                        {t("menu.product_details")}
                                    </Typography>
                                </Box>

                                <Divider />

                                <Box
                                    display="flex"
                                    padding={6}
                                    paddingBottom={12}
                                    gap={8}
                                >
                                    {/* <img /> */}
                                    <Box
                                        width={324}
                                        height={387}
                                        borderRadius={2}
                                        overflow="hidden"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        {selectedProduct?.image ? (
                                            <img
                                                width="100%"
                                                height="100%"
                                                src={selectedProduct?.image}
                                                alt="product"
                                            />
                                        ) : (
                                            <ImageIcon
                                                sx={{
                                                    color: "#cecece",
                                                    fontSize: 100,
                                                }}
                                            />
                                        )}
                                    </Box>

                                    <Stack gap={15} width="100%">
                                        <Stack>
                                            <Typography
                                                fontSize={40}
                                                fontWeight="bold"
                                                color="#06262D"
                                            >
                                                {selectedProduct?.name}
                                            </Typography>

                                            <Typography
                                                fontSize={26}
                                                color="#06262D"
                                            >
                                                {selectedProduct?.price}{" "}
                                                {t("common.currency")}
                                            </Typography>
                                        </Stack>

                                        <Stack spacing={5} width="100%">
                                            <InfoRow
                                                label={t("common.category")}
                                                value={
                                                    selectedProduct?.category
                                                }
                                            />

                                            <InfoRow
                                                label={t("common.cost")}
                                                value={`${
                                                    selectedProduct?.cost || "0"
                                                } ${t("common.currency")}`}
                                            />

                                            <InfoRow
                                                label={t("common.sold")}
                                                value={
                                                    selectedProduct?.quantities_sold ||
                                                    0
                                                }
                                            />

                                            <InfoRow
                                                label={t("common.total_sales")}
                                                value={`${
                                                    selectedProduct?.total_sales ||
                                                    0
                                                } ${t("common.currency")}`}
                                            />

                                            {selectedProduct?.ingredients
                                                ?.length ? (
                                                <Stack
                                                    spacing={1}
                                                    maxWidth="70%"
                                                    pt={4}
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <Typography>
                                                            {t(
                                                                "common.ingredients"
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                    <Divider />
                                                </Stack>
                                            ) : (
                                                ""
                                            )}

                                            {selectedProduct?.ingredients?.map(
                                                (item) => (
                                                    <InfoRow
                                                        hideComma
                                                        label={item.name}
                                                        value={`${
                                                            item?.cost_per_unit ||
                                                            0
                                                        } ${t(
                                                            "common.currency"
                                                        )}`}
                                                    />
                                                )
                                            )}
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Stack>
                        </GridCard>

                        <GridCard
                            fullWidth
                            padding={0}
                            minHeight={433}
                            height="fit-content"
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                padding={6}
                                height={74}
                            >
                                <Typography
                                    fontWeight="bold"
                                    fontSize={20}
                                    color="#202020"
                                >
                                    {t("overview.top_associated_products")}
                                </Typography>
                            </Box>

                            <Divider />

                            <Box
                                display="flex"
                                padding={6}
                                gap={6}
                                flexWrap="wrap"
                            >
                                {selectedProduct?.recommends?.map(
                                    (recommendedItem, index) => {
                                        return (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                key={recommendedItem.product}
                                            >
                                                <Stack
                                                    backgroundColor="white"
                                                    border="1px solid #DCDEE6"
                                                    borderRadius={3}
                                                    width={226}
                                                    height={300}
                                                >
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        backgroundColor="fafafa"
                                                        height="224px"
                                                        width="224px"
                                                        overflow="hidden"
                                                        borderRadius="12px 12px 0px 0px"
                                                    >
                                                        {recommendedItem.image ? (
                                                            <img
                                                                src={
                                                                    recommendedItem.image
                                                                }
                                                                width="100%"
                                                                height="100%"
                                                                alt="product"
                                                            />
                                                        ) : (
                                                            <ImageIcon
                                                                sx={{
                                                                    color: "#cecece",
                                                                    fontSize: 32,
                                                                }}
                                                            />
                                                        )}
                                                    </Box>

                                                    <Divider />

                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        flex={1}
                                                    >
                                                        <Typography
                                                            fontSize={18}
                                                            fontWeight="bold"
                                                            color="#202020"
                                                        >
                                                            {
                                                                recommendedItem.product
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        );
                                    }
                                )}
                            </Box>
                        </GridCard>
                    </Grid>
                </>
            )}
        </Stack>
    );
};

export { Product };
