import { Grid, Stack, Typography, Box } from "@mui/material";

import { useEffect, useState } from "react";

import { ComparisonChart } from "../ComparisonChart";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { PageHeader } from "../../../../../common/components";
import { constants } from "../../../../../config/constants";
import { ExtendArabicLogoPDF } from "../../../Overview/pdf/OverviewPDF";

const PerformancePDF = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const [isNoComparison, setIsNoComparison] = useState(false);
    const [period, setPeriod] = useState({
        startDate: format(new Date(), "y-M-d"),
        endDate: format(new Date(), "y-M-d"),
    });

    const [printDate, setPrintDate] = useState("");
    const [chartsData, setChartsData] = useState({
        AvgBasketQuantity: {
            current: [],
            previous: [],
        },
        ProfitGrowth: {
            current: [],
            previous: [],
        },
        AvgBasketValue: {
            current: [],
            previous: [],
        },
        SalesGrowth: {
            current: [],
            previous: [],
        },
        TotalTransactions: {
            current: [],
            previous: [],
        },
    });
    const [prevPeriod, setPrevPeriod] = useState("");
    const [iteration, setIteration] = useState("");

    function refineChartsData(dataArr) {
        const preCharts = {
            AvgBasketQuantity: {
                Current: [],
                Old: [],
            },
            ProfitGrowth: {
                Current: [],
                Old: [],
            },
            AvgBasketValue: {
                Current: [],
                Old: [],
            },
            SalesGrowth: {
                Current: [],
                Old: [],
            },
            TotalTransactions: {
                Current: [],
                Old: [],
            },
        };

        dataArr.sales_performance_profit.forEach((item) => {
            preCharts[item.figure_].Current.push({
                name: item.measure_desc,
                "Current Period":
                    item.status_ === "Current" ? item.measure_values_ : 0,
                "Previous Period":
                    item.status_ === "Old" ? item.measure_values_ : 0,
            });
        });
        dataArr.sales_performance_summary.forEach((item) => {
            preCharts[item.figure].Current.push({
                name: item.measure_desc,
                "Current Period":
                    item.status_ === "Current" ? item.measure_values : 0,
                "Previous Period":
                    item.status_ === "Old" ? item.measure_values : 0,
            });
        });

        setChartsData(preCharts);
    }

    useEffect(() => {
        const pdfData = JSON.parse(localStorage.getItem("overview-pdf"));
        setPeriod(pdfData.period);
        setPrintDate(pdfData.printDate);
        refineChartsData(pdfData.performanceData);
        setIsNoComparison(pdfData.isNoComparison);
        setPrevPeriod(pdfData.prevPeriod);
        setIteration(pdfData.iteration);

        if (pdfData.isArabic) {
            localStorage.setItem(constants.storage.language, "ar");
            i18n.changeLanguage("ar");
        }
    }, []);

    return (
        <Stack dir={isArabic ? "rtl" : "ltr"}>
            <Box
                height={55}
                backgroundColor="black"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={8}
                sx={{
                    pageBreakAfter: "avoid",
                }}
            >
                {isArabic ? (
                    <ExtendArabicLogoPDF />
                ) : (
                    <img
                        src="/logo_extend.svg"
                        width={176}
                        height="31px"
                        alt="lgo"
                    />
                )}

                <Typography
                    fontSize={12}
                    color="white"
                    fontWeight="bold"
                    position="relative"
                    top="12px"
                >
                    {printDate}
                </Typography>
            </Box>
            <Stack padding={8} spacing={8} minWidth="100%">
                <Stack spacing={4}>
                    <PageHeader title={t("sales.performance")} />

                    <Box display="flex" alignItems="center" gap={2}>
                        <Typography fontSize={14}>
                            {period.startDate.split("T")[0]} &rarr;{" "}
                            {period.endDate.split("T")[0]}
                        </Typography>

                        {!isNoComparison && (
                            <>
                                <Typography variant="body2">
                                    <b>{t("common.compared_to")}</b>
                                </Typography>
                                <Typography variant="body2">
                                    {t(
                                        `sales.previous_${
                                            prevPeriod.split("_")[1]
                                        }`
                                    )}
                                </Typography>
                                ({" "}
                                <Typography variant="body2">
                                    {t(`sales.${iteration}`)}
                                </Typography>
                                )
                            </>
                        )}
                    </Box>
                </Stack>

                <Grid container rowSpacing={8} flexWrap="wrap">
                    <ComparisonChart
                        label={t("sales.sales_growth")}
                        isNoComparison={isNoComparison}
                        data={chartsData.SalesGrowth.Current || []}
                        unit={t("common.currency")}
                        gridCardProps={{ fullWidth: true }}
                    />

                    <ComparisonChart
                        label={t("sales.profitability_growth")}
                        isNoComparison={isNoComparison}
                        data={chartsData.ProfitGrowth.Current || []}
                        unit={t("common.currency")}
                        gridCardProps={{ fullWidth: true }}
                    />
                </Grid>

                <Grid container rowGap={8} flexWrap="wrap" paddingTop={10}>
                    <ComparisonChart
                        label={t("sales.avg_basket_quantity")}
                        isNoComparison={isNoComparison}
                        data={chartsData.AvgBasketQuantity.Current || []}
                        unit={t("common.product_s")}
                        isAverage
                        gridCardProps={{ fullWidth: true }}
                    />
                    <ComparisonChart
                        label={t("sales.avg_basket_value")}
                        isNoComparison={isNoComparison}
                        data={chartsData.AvgBasketValue.Current || []}
                        unit={t("common.currency")}
                        isAverage
                        gridCardProps={{ fullWidth: true }}
                    />
                </Grid>

                <Grid container spacing={0} flexWrap="wrap" paddingTop={10}>
                    <ComparisonChart
                        label={t("sales.total_transactions")}
                        isNoComparison={isNoComparison}
                        data={chartsData.TotalTransactions.Current || []}
                        unit={""}
                        gridCardProps={{ fullWidth: true }}
                    />

                    {/* <GridCard height={400}>
                    <OrderTypesPeriods />
                </GridCard> */}
                </Grid>
            </Stack>
        </Stack>
    );
};

export { PerformancePDF };
