import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { AuthServices } from "../../../Auth/services";

const ManagerViewAlert = ({ closeAlert }) => {
    const { t } = useTranslation();

    const { mutate: updateUserRole } = useMutation({
        mutationFn: AuthServices.updateRole,
        onSuccess: (res) => {
            if (!res.data.error) {
                window.open(`${window.location.origin}/overview`, "_self");
            }
        },
        onError: (err) => console.log(err),
    });

    const handleViewAsOwner = (branch) => {
        const data = {
            manager_view: false,
        };

        updateUserRole(data);
    };

    return (
        <Box
            w="full"
            height="60px"
            backgroundColor="#ECFEFF"
            display="flex"
            alignItems="center"
            p={8}
            position="relative"
        >
            <Stack spacing={0} flex={1}>
                <Typography fontSize="16px" color="#111827">
                    {t("common.manager_view")}
                </Typography>
            </Stack>

            <Box>
                <Typography
                    sx={{
                        textTransform: "capitalize",
                        color: "#43AFBD",
                        fontWeight: "bold",
                        _hover: {
                            backgroundColor: "transparent",
                        },
                        cursor: "pointer",
                        fontSize: "15px",
                    }}
                    onClick={handleViewAsOwner}
                >
                    {t("common.view_as_owner")}
                </Typography>
            </Box>
        </Box>
    );
};

export { ManagerViewAlert };
