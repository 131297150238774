import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { services } from "../services";
import { toast } from "react-toastify";
import { constants } from "../../../config/constants";

import { differenceInMinutes } from "date-fns";
import { useTranslation } from "react-i18next";
import POSCard from "./POSCard";
import { getUserStorage } from "../../../common/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: 140,
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const Integrations = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const user = getUserStorage();
  const [isConnected, setIsConnected] = useState(false);
  const pos = user?.merchant?.pos;

  const [resyncRequested, setResyncRequestd] = useState(false);
  const notify = () =>
    toast.info(
      "Your resync request is being processed, this might take several minutes!",
      {
        hideProgressBar: true,
      }
    );

  // const navigate = useNavigate();

  const { mutate: resyncData, isLoading } = useMutation({
    mutationFn: services.resyncData,
    onSuccess: (res) => {
      if (res.data.error) {
        setResyncRequestd(false);
        localStorage.removeItem(constants.storage.lastResyncDate);
      }
    },
    onError: (err) => console.log(err),
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isModalOpen, setIsModaOpen] = useState(false);
  const handleOpenModal = () => setIsModaOpen(true);
  const handleModalClose = () => setIsModaOpen(false);

  const open = Boolean(anchorEl);

  const handleConnect = () => {
    setIsModaOpen(false);
    setIsConnected(true);
  };

  const handleDisconnect = () => {
    setIsConnected(false);
  };

  const handleResync = () => {
    setResyncRequestd(true);
    localStorage.setItem(
      constants.storage.lastResyncDate,
      JSON.stringify(new Date())
    );
    notify();
    resyncData();
  };

  const lastResyncDate = new Date(
    JSON.parse(localStorage.getItem(constants.storage.lastResyncDate))
  );

  const shouldDisableResync =
    differenceInMinutes(new Date(), lastResyncDate) < 15;

  return (
    <Stack padding={8} spacing={8} minWidth="100%">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">{t("top_nav.integrations")}</Typography>
      </Box>
      <Grid container spacing={0} alignItems="stretch" gap={4}>
        <POSCard
          name="Foodics"
          logo="/foodics_logo.jpeg"
          description="Point of sale & restaurant management solution. Restaurant management made easy. Elevate your resturant's guest experience and business operations with Foodics solutions."
          hlink="www.foodics.com"
          pos={pos}
          handleResync={handleResync}
          shouldDisableResync={shouldDisableResync}
          resyncRequested={resyncRequested}
        />

        <POSCard
          name="Deliverect"
          logo="/Deliverect_logo.png"
          description="Online orders are aggregated, analyzed, and visualized into a single source of truth on your Ballurh's dashboard."
          hlink="www.deliverect.com"
          pos={pos}
          handleResync={handleResync}
          shouldDisableResync={shouldDisableResync}
          resyncRequested={resyncRequested}
        />
        <POSCard
          name="Flavours"
          isConnected={false}
          logo="/Flavours_logo.png"
          description="A complete cashier system in terms of the features that a business owner needs to manage his business."
          hlink="www.flavours.sa"
          pos={pos}
          handleResync={handleResync}
          shouldDisableResync={shouldDisableResync}
          resyncRequested={resyncRequested}
        />
        <POSCard
          name="UrbanPiper"
          logo="/UrabPiper_logo.jpg"
          description="Online orders are aggregated, analyzed, and visualized into a single source of truth on your Ballurh's dashboard."
          hlink="www.urbanpiper.com"
          pos={pos}
          handleResync={handleResync}
          shouldDisableResync={shouldDisableResync}
          resyncRequested={resyncRequested}
        />
      </Grid>
    </Stack>
  );
};

export { Integrations };
