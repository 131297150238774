import { Box, Button, Stack } from "@mui/material";
import { PageHeader } from "../../../common/components";
import { useState } from "react";
import { PixelAudience } from "./PixelAudience";
import { PixelEvents } from "./PixelEvents";
import { useTranslation } from "react-i18next";

export const Pixel = () => {
    const { t } = useTranslation();
    const [selectedPage, setSelectedPage] = useState("audience");

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <PageHeader title={t("common.pixel")} />
            <Stack>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    padding={6}
                    height={100}
                >
                    <Box display="flex" alignItems="baseline" gap={4}>
                        <Button
                            sx={{
                                color: selectedPage === "audience" && "#00C08B",
                                backgroundColor:
                                    selectedPage === "audience" && "#EFFEF4",
                                "&:hover": {
                                    backgroundColor: "#EFFEF4",
                                },
                                textTransform: "capitalize",
                                borderRadius: "8px",
                            }}
                            onClick={() => setSelectedPage("audience")}
                        >
                            {t("common.pixel_aud")}
                        </Button>

                        <Button
                            sx={{
                                color: selectedPage === "events" && "#00C08B",
                                backgroundColor:
                                    selectedPage === "events" && "#EFFEF4",
                                "&:hover": {
                                    backgroundColor: "#EFFEF4",
                                    color: "#00C08B",
                                },
                                textTransform: "capitalize",
                                // backgroundColor: "#5BCFDE",
                                borderRadius: "8px",
                            }}
                            onClick={() => setSelectedPage("events")}
                        >
                            {t("common.pixel_events")}
                        </Button>
                    </Box>
                </Box>

                {selectedPage === "audience" && <PixelAudience />}

                {selectedPage === "events" && <PixelEvents />}
            </Stack>
        </Stack>
    );
};
