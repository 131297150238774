import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { constants } from "../../../../config/constants";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";

const CardInfoRow = ({ label, value }) => (
    <Box display="flex" alignItems="baseline">
        <Typography fontSize={16} color="#94A3B8" width={100}>
            {label}:
        </Typography>

        <Tooltip title={value}>
            <Typography
                fontSize={16}
                color="#06262D"
                align="right"
                flex={1}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
            >
                {value}
            </Typography>
        </Tooltip>
    </Box>
);

const GridView = ({ items }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Box display="flex" gap={8} flexWrap="wrap">
            {items.map((product, i) => (
                <Stack
                    padding={4}
                    spacing={4}
                    backgroundColor="white"
                    borderRadius={5}
                    flex={1}
                    minWidth={409}
                    maxWidth={430}
                    height={323}
                    key={product.product_id + i}
                >
                    <Box display="flex" gap={4}>
                        <Box
                            width="152px"
                            height="184px"
                            borderRadius={2}
                            overflow="hidden"
                            border="1px solid #f4f1f1"
                        >
                            {!product.image ? (
                                <Box
                                    backgroundColor="#f9fafb"
                                    width="100%"
                                    height="100%"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <ImageIcon sx={{ color: "#cecece" }} />
                                </Box>
                            ) : (
                                // eslint-disable-next-line jsx-a11y/alt-text
                                <img
                                    src={product.image}
                                    width="100%"
                                    height="100%"
                                />
                            )}
                        </Box>

                        <Stack spacing={4} width={200}>
                            <CardInfoRow
                                label={t("common.name")}
                                value={product.product_name}
                            />
                            <CardInfoRow
                                label={t("common.category")}
                                value={product.category}
                            />
                            <CardInfoRow
                                label={t("common.class")}
                                value={product.class}
                            />
                            <CardInfoRow
                                label={t("common.total_sales")}
                                value={`${numberWithCommas(
                                    Math.round(product.total_sales).toFixed(2)
                                )} ${t("common.currency")}`}
                            />
                        </Stack>
                    </Box>

                    <Typography variant="h6">
                        {numberWithCommas(product.price)} {t("common.currency")}
                    </Typography>

                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            borderRadius: 4,
                            backgroundColor: "lightBlue",
                            "&:hover": {
                                backgroundColor: "lightBlue",
                            },
                            textTransform: "capitalize",
                        }}
                        onClick={() => navigate(`${product.product_id}`)}
                    >
                        {t("menu.view_product")}
                    </Button>
                </Stack>
            ))}
        </Box>
    );
};

export { GridView };
