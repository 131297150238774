import { useEffect, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { uniqBy } from "lodash";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    MenuItem,
    Pagination,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    ButtonSelect,
    CustomSelectOutline,
    FilterSelect,
    RangePicker,
    SearchField,
} from "../../../../common/components";
import { TableView } from "./TableView";
import { services } from "../../services";
import { getUserStorage } from "../../../../common/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ExportButton } from "../../../../common/components/Export/Export";
import { toast } from "react-toastify";

const CustomersData = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const navigate = useNavigate();

    const user = getUserStorage();
    const isOwner = user.role === 1;
    const userBranches =
        isOwner && !user.manager_view ? user?.merchant?.branches : user?.branch;
    const allBranches = userBranches.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
    }));

    const isGroupOwner = user.role === 9;

    const userGroups = user?.group;
    const allGroups =
        userGroups?.map((group) => ({
            id: group.merchant_id,
            label: group.name,
        })) || [];
    const allGroupsOption = { id: "all", label: t("common.all_groups") };
    const [selectedGroup, setSelectedGroup] = useState(allGroupsOption.id);

    const [selectCurrentSegments, setSelectedCurrentSegments] = useState("all");

    const isManagerView = user.manager_view;
    const allBranchesOption = { id: "all", label: t("common.all_branches") };
    const [selectedBranch, setSelectedBranch] = useState(
        isManagerView ? allBranches[0].id : allBranchesOption.id
    );

    const filterItems = [
        {
            value: "Gender",
            label: t("common.gender"),
            key: "gender",
            subItems: [t("common.male"), t("common.female"), t("common.other")],
        },
    ];

    const [overviewPDF, setOverviewPDF] = useState({});
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

    const { mutate: getAllCustomers, isLoading } = useMutation({
        mutationFn: services.getCustomersData,
        onSuccess: (res) => {
            if (res.data.response && !res.data.error) {
                const response = res.data.response;
                setAllCustomers(response);
                setAllProducts(response);

                setOverviewPDF({
                    all_customers: response,
                    all_products: response,
                    period,
                });
            }

            if (res.data.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const defaultSegment = searchParams.get("segment");
    const defaultEnd = useMemo(
        () =>
            searchParams.get("end") ? new Date(searchParams.get("end")) : null,
        [searchParams]
    );
    const defaultStart = useMemo(
        () =>
            searchParams.get("start")
                ? new Date(searchParams.get("start"))
                : null,
        [searchParams]
    );

    const [period, setPeriod] = useState({
        startDate: defaultStart
            ? format(new Date(defaultStart), "y-M-d")
            : null,
        endDate: defaultEnd ? format(new Date(defaultEnd), "y-M-d") : null,
    });

    const [searchValue, setSearchValue] = useState("");
    const [selectedSegment, setSelectedSegment] = useState(
        defaultSegment || "all"
    );

    const [allProducts, setAllProducts] = useState([]);
    const [allCustomers, setAllCustomers] = useState([]);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("price");

    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    const count = allCustomers.length;
    const pagesCount = Math.ceil(count / rowsPerPage);

    const [filterList, setFilterList] = useState([]);

    const handlePageChange = (newPage) => setPage(newPage);

    const paginatedItems = stableSort(
        allCustomers,
        getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    useEffect(() => {
        if (defaultSegment) handleSegementationFilter(defaultSegment);
    }, []);

    const handleFilter = ({ filterParam }) => {
        const isAddedBefore = filterList.some(
            (fp) => fp.value === filterParam.value
        );

        let newFilterList;
        if (isAddedBefore) {
            newFilterList = filterList.filter(
                (f) => f.value !== filterParam.value
            );

            if (
                filterList.filter((f) => f.value !== filterParam.value)
                    .length === 0
            )
                setSelectedSegment("all");
        } else newFilterList = uniqBy([...filterList, filterParam]);

        setFilterList(newFilterList);

        if (filterParam.key === "segmentation") {
            const segmentations = newFilterList
                .filter((i) => i.key === "segmentation")
                .map((s) => s.value);

            setSelectedCurrentSegments(
                segmentations.length ? segmentations : "all"
            );
        }
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleDatesChange = ({ startDate, endDate }) => {
        if (startDate !== "1970-1-1") {
            setPeriod({ startDate, endDate });
        }
    };

    const handleBranchChange = (e) => {
        const newSelectedBranchValue = e.target.value;
        setSelectedBranch(newSelectedBranchValue);
    };

    const handleGroupChange = (e) => {
        let newSelectedGroupValue = e.target.value;
        const lastElement =
            newSelectedGroupValue[newSelectedGroupValue.length - 1];
        if (lastElement === "all" || !newSelectedGroupValue.length)
            newSelectedGroupValue = "all";
        else
            newSelectedGroupValue = newSelectedGroupValue.filter(
                (i) => i !== "all"
            );
        setSelectedGroup(newSelectedGroupValue);
    };

    const handleSegementationFilter = (value) => {
        setSelectedSegment(value);

        if (value === "all")
            setFilterList(filterList.filter((f) => f.key !== "segmentation"));
        else
            handleFilter({
                filterParam: {
                    key: "segmentation",
                    value,
                },
            });
    };

    useEffect(() => {
        if (period.startDate) {
            getAllCustomers({
                from_date: period.startDate,
                till_date: period.endDate,
                [`${isGroupOwner ? "merchant_filter" : "branch_id"}`]:
                    isGroupOwner ? selectedGroup : selectedBranch,
                segmentations: selectCurrentSegments,
            });
        }
    }, [
        getAllCustomers,
        isGroupOwner,
        period.endDate,
        period.startDate,
        selectCurrentSegments,
        selectedBranch,
        selectedGroup,
    ]);

    useEffect(() => {
        if (!isLoading) {
            filterProducts();
        }
    }, [filterList, searchValue, isLoading]);

    function filterProducts() {
        const selectedFilters = {
            gender: [],
            ltv: [],
            spent: [],
        };
        filterList
            .filter((i) => i.key !== "segmentation")
            .forEach(
                (filter) =>
                    (selectedFilters[filter.key] = [
                        ...selectedFilters[filter.key],
                        filter.value,
                    ])
            );

        if (allProducts.length) {
            setAllCustomers(
                allProducts?.filter((product) => {
                    const isGenderFilterShouldApply =
                        !!selectedFilters.gender.length;
                    const isSearchShouldApply = !!searchValue.length;
                    const filtersState = {
                        gender: true,
                        ltv: true,
                        searchQuery: true,
                        segmentation: true,
                    };

                    if (isGenderFilterShouldApply)
                        filtersState.gender = selectedFilters.gender.includes(
                            product.gender
                        );

                    if (isSearchShouldApply)
                        filtersState.searchQuery = product.customer
                            .toLowerCase()
                            .includes(searchValue.toLowerCase());

                    if (Object.values(filtersState).every((e) => e === true))
                        return true;
                    return false;
                })
            );
        }
    }

    useEffect(() => {
        window.pendo.pageLoad({
            name: "Customers Data Page",
        });
    }, []);

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <Grid container flexWrap="nowrap">
                <Grid
                    item
                    xs={6}
                    marginRight={isArabic ? 0 : 8}
                    marginLeft={isArabic ? 8 : 0}
                >
                    <Stack direction="row" alignItems="baseline" columnGap={3}>
                        <Typography variant="h5">
                            {t("customer.data")}
                        </Typography>

                        <Typography fontSize={16} color="#06262D">
                            {allCustomers.length} {t("customer.customer_clear")}
                        </Typography>
                    </Stack>
                </Grid>

                <Grid item xs={6}>
                    <SearchField
                        placeholder={t("customer.search_customer")}
                        onChange={(val) => setSearchValue(val)}
                        isLoading={isLoading}
                    />
                </Grid>
            </Grid>

            <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" align="center" gap={3}>
                    <RangePicker
                        onChange={(value) => handleDatesChange(value)}
                        isLoading={isLoading}
                        defaultEndDate={defaultEnd}
                        defaultStartDate={defaultStart}
                        isCustom={defaultEnd && defaultStart}
                    />
                </Box>

                <Box display="flex" alignItems="center" gap={4}>
                    <FilterSelect
                        label={t("common.filter")}
                        items={filterItems}
                        onChange={(value) => {
                            handleFilter({ filterParam: value });
                        }}
                        icon={FilterListIcon}
                        filterList={filterList}
                        isLoading={isLoading}
                    />

                    <Select
                        value={selectedSegment}
                        onChange={(e) => {
                            handleSegementationFilter(e.target.value);
                        }}
                        input={<CustomSelectOutline />}
                        IconComponent={ExpandMoreIcon}
                        sx={{ width: 160 }}
                        disabled={isLoading}
                    >
                        <MenuItem value="all">
                            {t("customer.all_customers")}
                        </MenuItem>
                        <MenuItem value="Top Customers">
                            {t("customer.top_customers")}
                        </MenuItem>
                        <MenuItem value="New Customers">
                            {t("customer.new_customers")}
                        </MenuItem>
                        <MenuItem value="One Time Customers">
                            {t("customer.one_time_customers")}
                        </MenuItem>
                        <MenuItem value="Customers at Risk">
                            {t("customer.customers_at_risk")}
                        </MenuItem>
                        <MenuItem value="Lost Customers">
                            {t("customer.lost_customers")}
                        </MenuItem>
                    </Select>

                    {!isManagerView && !isGroupOwner && (
                        <ButtonSelect
                            value={selectedBranch}
                            onChange={handleBranchChange}
                            options={[allBranchesOption, ...allBranches]}
                            isLoading={isLoading}
                        />
                    )}

                    {isGroupOwner && (
                        <ButtonSelect
                            value={selectedGroup}
                            onChange={handleGroupChange}
                            options={[allGroupsOption, ...allGroups]}
                            isLoading={isLoading}
                            isMulti
                        />
                    )}

                    <ExportButton
                        overviewPDF={{
                            ...overviewPDF,
                            filterList,
                            order,
                            orderBy,
                            isArabic,
                            printDate: `${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`,
                        }}
                        pageName="CustomersDataPDF"
                        isLoading={isLoading}
                    />
                </Box>
            </Box>

            {!!filterList.length && (
                <Box display="flex" alignItems="center" gap={4}>
                    <Typography variant="body2" sx={{ color: "#6d7482" }}>
                        FILTERS
                    </Typography>

                    <Divider orientation="vertical" flexItem />

                    {filterList.map((filterParam) => (
                        <Chip
                            key={filterParam.value}
                            label={filterParam.value}
                            variant="outlined"
                            sx={{ backgroundColor: "white" }}
                            deleteIcon={<CloseIcon />}
                            onDelete={() => handleFilter({ filterParam })}
                        />
                    ))}
                </Box>
            )}

            {isLoading && (
                <Box width="100%" textAlign="center">
                    <CircularProgress size={20} />
                </Box>
            )}

            <Box minHeight={400}>
                {!paginatedItems.length && !isLoading && (
                    <Typography variant="body2" align="center">
                        {t("common.empty")}
                    </Typography>
                )}

                {
                    <TableView
                        items={paginatedItems}
                        startDate={period.startDate}
                        endDate={period.endDate}
                    />
                }
            </Box>

            <Box display="flex">
                <Box
                    display="flex"
                    gap={4}
                    width="100%"
                    justifyContent="space-between"
                >
                    <Button
                        variant="outlined"
                        sx={{
                            borderRadius: 4,
                            borderColor: "#EEEFF4",
                            textTransform: "capitalize",
                            backgroundColor: "white",
                            "&:hover": {
                                backgroundColor: "white",
                                borderColor: "#EEEFF4",
                            },
                        }}
                        onClick={() => handlePageChange(page - 1)}
                        disabled={page === 0}
                    >
                        {t("common.previous")}
                    </Button>

                    <Pagination
                        count={pagesCount}
                        hideNextButton
                        hidePrevButton
                        variant="outlined"
                        shape="rounded"
                        page={page + 1}
                        onChange={(e, val) => handlePageChange(val - 1)}
                        sx={{
                            "& .Mui-selected": {
                                borderColor: "lightBlue",
                                color: "lightBlue",
                                backgroundColor: "white",
                                ":hover": {
                                    backgroundColor: "white",
                                },
                            },
                            "& button": {
                                backgroundColor: "white",
                                ":hover": {
                                    backgroundColor: "white",
                                },
                            },
                        }}
                    />

                    <Button
                        variant="outlined"
                        sx={{
                            borderRadius: 4,
                            borderColor: "#EEEFF4",
                            textTransform: "capitalize",
                            backgroundColor: "white",
                            "&:hover": {
                                backgroundColor: "white",
                                borderColor: "#EEEFF4",
                            },
                        }}
                        disabled={
                            paginatedItems.length === 0 ||
                            Math.ceil(count / rowsPerPage) === page + 1
                        }
                        onClick={() => handlePageChange(page + 1)}
                    >
                        {t("common.next")}
                    </Button>
                </Box>
            </Box>
        </Stack>
    );
};

export { CustomersData };
