import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { VictoryPie } from "victory";
import { useTranslation } from "react-i18next";
import { GridCard } from "../../../../../common/components";
import { InfoCard } from "../InfoCard";
import { constants } from "../../../../../config/constants";
import { numberWithCommas } from "../../../../../common/utils/numberWithCommas";
import { PageHeader } from "../../../../../common/components";
import { ExtendArabicLogoPDF } from "../../../Overview/pdf/OverviewPDF";

const BasketProductPDF = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const [period, setPeriod] = useState({
        startDate: new Date("1/1/2000").toLocaleDateString("en"),
        endDate: format(new Date(), "y-M-d"),
    });

    const [productDetails, setProductDetails] = useState(null);
    const [topProducts, setTopProducts] = useState(null);
    const [productsPieData, setProductsPieData] = useState(null);

    const [printDate, setPrintDate] = useState("");
    const [allProducts, setAllProducts] = useState([]);

    const [selectedProduct, setSelectedProduct] = useState("");

    useEffect(() => {
        const pdfData = JSON.parse(localStorage.getItem("overview-pdf"));

        setPeriod(pdfData.period);
        setPrintDate(pdfData.printDate);
        setProductDetails(pdfData.product_details);
        setTopProducts(pdfData.top_products);
        setProductsPieData(pdfData.pie_data);
        setAllProducts(pdfData.all_products);
        setSelectedProduct(pdfData.selected_product);

        if (pdfData.isArabic) {
            localStorage.setItem(constants.storage.language, "ar");
            i18n.changeLanguage("ar");
        }
    }, []);

    return (
        <Stack dir={isArabic ? "rtl" : "ltr"}>
            <Box
                height={55}
                backgroundColor="black"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={8}
                sx={{
                    pageBreakAfter: "avoid",
                }}
            >
                {isArabic ? (
                    <ExtendArabicLogoPDF />
                ) : (
                    <img
                        src="/logo_extend.svg"
                        width={176}
                        height="31px"
                        alt="lgo"
                    />
                )}

                <Typography
                    fontSize={12}
                    color="white"
                    fontWeight="bold"
                    position="relative"
                    top="12px"
                >
                    {printDate}
                </Typography>
            </Box>
            <Stack padding={8} spacing={8} minWidth="100%" marginBottom={12}>
                <Stack spacing={4}>
                    <PageHeader title={t("basket.basket")} />

                    <Typography fontSize={14}>
                        {period.startDate} &rarr; {period.endDate}
                    </Typography>
                </Stack>

                <Grid container>
                    <GridCard fullWidth padding={0} height={900}>
                        <Stack>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                padding={6}
                                height={74}
                            >
                                <Typography
                                    fontWeight="bold"
                                    fontSize={20}
                                    color="#202020"
                                >
                                    {t("basket.basket_analysis")}
                                </Typography>
                            </Box>

                            <Divider />

                            <Stack spacing={6}>
                                <Stack
                                    width="100%"
                                    minHeight={600}
                                    backgroundColor="white"
                                    borderRadius={1}
                                    padding={8}
                                    spacing={4}
                                    alignItems="center"
                                    rowGap={0}
                                >
                                    {selectedProduct.id && (
                                        <>
                                            {!!topProducts?.length && (
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    maxWidth="1800px"
                                                    flexWrap="wrap"
                                                >
                                                    <Box
                                                        width="fit-content"
                                                        margin="auto"
                                                    >
                                                        <svg
                                                            width={450}
                                                            height={450}
                                                        >
                                                            <circle
                                                                cx={225}
                                                                cy={227}
                                                                r={50}
                                                                fill="#CCCCCC"
                                                            />
                                                            <text
                                                                color="#06262D"
                                                                x="50%"
                                                                y="50%"
                                                                dominant-baseline="middle"
                                                                text-anchor="middle"
                                                            >
                                                                {
                                                                    selectedProduct.label
                                                                }
                                                            </text>
                                                            <VictoryPie
                                                                width={450}
                                                                height={450}
                                                                standalone={
                                                                    false
                                                                }
                                                                data={
                                                                    productsPieData
                                                                }
                                                                labelRadius={({
                                                                    innerRadius,
                                                                }) =>
                                                                    innerRadius +
                                                                    25
                                                                }
                                                                radius={({
                                                                    datum,
                                                                    index,
                                                                }) => {
                                                                    return (
                                                                        115 +
                                                                        +datum.perc
                                                                    );
                                                                }}
                                                                innerRadius={60}
                                                                startAngle={90}
                                                                endAngle={-90}
                                                                style={{
                                                                    labels: {
                                                                        fill: "white",
                                                                        fontSize: 16,
                                                                    },
                                                                }}
                                                                labels={(
                                                                    value
                                                                ) =>
                                                                    value.datum
                                                                        .name
                                                                }
                                                                colorScale={
                                                                    constants.pieColors
                                                                }
                                                            />
                                                        </svg>
                                                    </Box>

                                                    <Stack gap={10}>
                                                        <Typography
                                                            fontSize={20}
                                                            color="#06262D"
                                                        >
                                                            {t(
                                                                "basket.customer_who_order"
                                                            )}{" "}
                                                            {
                                                                selectedProduct.label
                                                            }{" "}
                                                            {t(
                                                                "basket.also_order"
                                                            )}
                                                        </Typography>

                                                        <Stack
                                                            gap={3}
                                                            paddingLeft={8}
                                                        >
                                                            {topProducts.map(
                                                                (
                                                                    topProduct
                                                                ) => (
                                                                    <Box
                                                                        display="flex"
                                                                        alignItems="center"
                                                                        gap={3}
                                                                    >
                                                                        <Box
                                                                            width={
                                                                                8
                                                                            }
                                                                            height={
                                                                                8
                                                                            }
                                                                            borderRadius="50%"
                                                                            backgroundColor="#D1D5DB"
                                                                        />

                                                                        <Typography variant="body1">
                                                                            <b>
                                                                                {isArabic
                                                                                    ? `%${topProduct.percentage}`
                                                                                    : `${topProduct.percentage}%`}
                                                                            </b>{" "}
                                                                            {t(
                                                                                "basket.of_all_cus_who_order"
                                                                            )}{" "}
                                                                            <b>
                                                                                {
                                                                                    selectedProduct.label
                                                                                }
                                                                            </b>{" "}
                                                                            {t(
                                                                                "basket.also_order"
                                                                            )}{" "}
                                                                            <b>
                                                                                {
                                                                                    topProduct.item
                                                                                }
                                                                            </b>
                                                                        </Typography>
                                                                    </Box>
                                                                )
                                                            )}
                                                        </Stack>
                                                    </Stack>
                                                </Box>
                                            )}
                                        </>
                                    )}
                                </Stack>
                            </Stack>
                        </Stack>
                    </GridCard>
                </Grid>

                <Grid container paddingTop={10}>
                    <GridCard fullWidth padding={2} height={900}>
                        <Stack>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                padding={6}
                                height={74}
                            >
                                <Typography
                                    fontWeight="bold"
                                    fontSize={20}
                                    color="#202020"
                                >
                                    {t("basket.more_details")}
                                </Typography>
                            </Box>

                            <Divider />
                            {/* More Details */}

                            <Grid
                                container
                                spacing={6}
                                style={{
                                    margin: isArabic ? 0 : "-24px",
                                    marginTop: 2,
                                    marginBottom: 2,
                                }}
                            >
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} md={6}>
                                            <InfoCard
                                                iconPath="/ic_customers_outline.svg"
                                                label={t(
                                                    "basket.number_of_customers"
                                                )}
                                                value={
                                                    productDetails?.number_of_customer ||
                                                    0
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <InfoCard
                                                iconPath="/ic_sales_outline.svg"
                                                label={t(
                                                    "basket.amount_of_sales"
                                                )}
                                                value={`${
                                                    numberWithCommas(
                                                        productDetails?.amount_of_sales
                                                    ) || 0
                                                } ${t("common.currency")} `}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <InfoCard
                                                iconPath="/ic_basket_outline.svg"
                                                label={t(
                                                    "basket.avg_basket_value"
                                                )}
                                                value={` ${
                                                    numberWithCommas(
                                                        productDetails?.avg_basket_value
                                                    ) || 0
                                                } ${t("common.currency")}`}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <InfoCard
                                                iconPath="/ic_quantity.svg"
                                                label={t(
                                                    "basket.avg_basket_quantity"
                                                )}
                                                value={`${
                                                    productDetails?.avg_basket_quantity ||
                                                    0
                                                }`}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Stack>
                    </GridCard>
                </Grid>
            </Stack>
        </Stack>
    );
};

export { BasketProductPDF };
