import { constants } from "../../config/constants"

export const getToken = () => {
    const { storage: { token } } = constants
    const userToken = sessionStorage.getItem(token) || localStorage.getItem(token)
    
    return userToken
}

export const setToken = (token, rememberUser = null) => {
    let browserStorage = localStorage

    if (rememberUser !== null) {
        browserStorage = rememberUser ? localStorage : sessionStorage
    } else {
        browserStorage = localStorage.getItem(constants.storage.token)
            ? localStorage
            : sessionStorage
    }

    browserStorage.setItem(constants.storage.token, token)
    window.dispatchEvent(
        new StorageEvent('storage', {
            key: constants.storage.token,
            newValue: token,
        })
    )
}

export function getUserStorage() {
    if (typeof window === 'undefined') return null
    const userProfile =
        localStorage.getItem(constants.storage.user) ||
        sessionStorage.getItem(constants.storage.user)

    return JSON.parse(userProfile)
}

export function setUserStorage(token, userProfile, rememberUser = true) {
    const browserStorage = rememberUser ? localStorage : sessionStorage

    setToken(token, rememberUser)
    browserStorage.setItem(constants.storage.user, JSON.stringify(userProfile))
}