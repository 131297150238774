import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { uniqBy } from "lodash";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Box, Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { chunk } from "lodash";

import { TableView } from "../TableView";
import { PageHeader } from "../../../../../common/components";
import { constants } from "../../../../../config/constants";
import { ExtendArabicLogoPDF } from "../../../Overview/pdf/OverviewPDF";

const CustomersDataPDF = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const [period, setPeriod] = useState({
        startDate: null,
        endDate: format(new Date(), "y-M-d"),
    });

    const [printDate, setPrintDate] = useState(null);

    useEffect(() => {
        const pdfData = JSON.parse(localStorage.getItem("overview-pdf"));

        setAllCustomers(pdfData.all_customers);
        setAllProducts(pdfData.all_products);
        setPeriod(pdfData.period);
        setPrintDate(pdfData.printDate);
        setFilterList(pdfData.filterList);
        setOrder(pdfData.order);
        setOrderBy(pdfData.orderBy);
        setExportType(pdfData.exportType);

        if (pdfData.isArabic) {
            localStorage.setItem(constants.storage.language, "ar");
            i18n.changeLanguage("ar");
        }
    }, []);

    const [allProducts, setAllProducts] = useState([]);
    const [allCustomers, setAllCustomers] = useState([]);
    const [exportType, setExportType] = useState("pdf");

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("price");

    const [filterList, setFilterList] = useState([]);

    const paginatedItems = stableSort(
        allCustomers,
        getComparator(order, orderBy)
    );

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    return (
        <Stack dir={isArabic ? "rtl" : "ltr"}>
            <Box
                height={55}
                backgroundColor="black"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={8}
                sx={{
                    pageBreakAfter: "avoid",
                }}
            >
                {isArabic ? (
                    <ExtendArabicLogoPDF />
                ) : (
                    <img
                        src="/logo_extend.svg"
                        width={176}
                        height="31px"
                        alt="lgo"
                    />
                )}

                <Typography
                    fontSize={12}
                    color="white"
                    fontWeight="bold"
                    position="relative"
                    top="12px"
                >
                    {printDate}
                </Typography>
            </Box>

            <Stack padding={8} spacing={8} minWidth="100%">
                <Stack spacing={4}>
                    <Stack direction="row" alignItems="baseline" columnGap={3}>
                        <PageHeader title={t("customer.data")} />

                        <Typography fontSize={16} color="#06262D">
                            {allCustomers.length} {t("customer.customer_clear")}
                        </Typography>
                    </Stack>

                    <Typography fontSize={14}>
                        {period.startDate} &rarr; {period.endDate}
                    </Typography>
                </Stack>

                {!!filterList.length && (
                    <Box display="flex" alignItems="center" gap={4}>
                        <Typography variant="body2" sx={{ color: "#6d7482" }}>
                            FILTERS
                        </Typography>

                        <Divider orientation="vertical" flexItem />

                        {filterList.map((filterParam) => (
                            <Chip
                                key={filterParam.value}
                                label={filterParam.value}
                                variant="outlined"
                                sx={{ backgroundColor: "white" }}
                                deleteIcon={<CloseIcon />}
                            />
                        ))}
                    </Box>
                )}

                {!!paginatedItems?.length &&
                    chunk(
                        paginatedItems,
                        exportType === "png" ? paginatedItems.length : 10
                    ).map((arr) => (
                        <Box minHeight={400} paddingTop={10}>
                            {!paginatedItems.length && (
                                <Typography variant="body2" align="center">
                                    {t("common.empty")}
                                </Typography>
                            )}

                            {
                                <TableView
                                    items={arr}
                                    startDate={period.startDate}
                                    endDate={period.endDate}
                                    withViewButton={false}
                                />
                            }
                        </Box>
                    ))}
            </Stack>
        </Stack>
    );
};

export { CustomersDataPDF };
