import { InputBase } from "@mui/material";
import styled from "@emotion/styled";

const CustomSelect = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 16,
        position: "relative",
        backgroundColor: "white",
        border: "none",
        padding: "8px 16px 8px 12px",
        fontSize: 14,
        //   '&:focus': {
        //     borderRadius: 4,
        //     borderColor: '#80bdff',
        //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        //   },
    },
}));

const CustomSelectOutline = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 16,
        position: "relative",
        backgroundColor: "white",
        border: "1px solid #ced4da",
        padding: "5px 16px 7px 12px",
        fontSize: 14,
    },
}));

export { CustomSelect, CustomSelectOutline };
