import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Stack,
    Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthServices } from "../../services";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CustomTextField } from "../../../common/components";
import { emailFormatRegExp } from "../../../config/constants";

const WelcomePage = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const authCode = searchParams.get("code");

    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isAlreadyRegistred, setIsAlreadyRegistred] = useState(false);

    const [merchantId, setMerchantId] = useState(null);

    const { mutate: checkCodeState, isLoading } = useMutation({
        mutationFn: AuthServices.checkCodeState,
        onSuccess: (res) => {
            if (res.data.code === 423) setIsAlreadyRegistred(true);
            if (res.data.response && !res.data.error) {
                setIsAuthorized(true);
                setMerchantId(res.data.response.merchant_id);
            }
        },
        onError: (err) => console.log(err),
    });

    const { mutate: inviteOwner, isLoading: isInvitingOwner } = useMutation({
        mutationFn: AuthServices.inviteOwner,
        onSuccess: (res) => {
            if (res.data.response && !res.data.error) {
                toast.success("Registration Successful!", {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    const { handleSubmit, control, watch } = useForm({
        defaultValues: {
            email: "",
        },
    });

    useEffect(() => {
        if (authCode) {
            checkCodeState({ code: authCode });
        }
    }, []);

    const onSubmit = (values) => {
        const reqData = { merchant_id: merchantId, email: values.email };

        inviteOwner(reqData);
    };

    if (isLoading)
        return (
            <Stack
                width="100%"
                height="100vh"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                spacing={4}
            >
                <CircularProgress />
            </Stack>
        );

    return (
        <Stack
            width="100%"
            height="100vh"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            spacing={4}
        >
            {isAuthorized && (
                <>
                    <CheckCircleIcon sx={{ fontSize: 160, color: "green" }} />
                    <Stack
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography variant="h5">Welcome To Ballurh</Typography>
                        <Typography variant="h6">
                            We have sent an email with your login credentials to
                            your foodics email.
                        </Typography>

                        <Typography variant="h6" color="gray">
                            Enter the alternate owner's email to send an
                            invitation.
                        </Typography>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                gap={4}
                            >
                                <Stack spacing={1}>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <CustomTextField
                                                placeholder="example@example.com    "
                                                variant="outlined"
                                                {...field}
                                            />
                                        )}
                                    />
                                </Stack>

                                <Button
                                    variant="outlined"
                                    sx={{
                                        textTransform: "capitalize",
                                    }}
                                    type="submit"
                                    disabled={
                                        !emailFormatRegExp.test(
                                            watch("email")
                                        ) || isInvitingOwner
                                    }
                                >
                                    Invite
                                </Button>
                            </Box>
                        </form>

                        <Divider flexItem />
                        <Typography variant="h6" color="gray">
                            Feel free to schedule a chat or meeting at your
                            convenience. We're here whenever you're ready.
                        </Typography>

                        <Button
                            variant="outlined"
                            sx={{
                                width: "fit-content",
                                textTransform: "capitalize",
                            }}
                            onClick={() =>
                                window.open(
                                    "https://help.ballurh.io/meetings/abdullah-alhawsawi/demo",
                                    "_self"
                                )
                            }
                        >
                            Schedule Meeting
                        </Button>
                    </Stack>
                </>
            )}

            {isAlreadyRegistred && (
                <>
                    <ErrorIcon sx={{ fontSize: 160, color: "#5BCFDE" }} />
                    <Stack spacing={6}>
                        <Typography variant="h6" color="gray">
                            This user is already registred at ballurh. please
                            login
                        </Typography>
                        <Box display="flex" justifyContent="center">
                            <Button
                                onClick={() => navigate("/login")}
                                variant="contained"
                                sx={{
                                    width: 100,
                                    textTransform: "capitalize",
                                    backgroundColor: "#5BCFDE",
                                    fontSize: 16,
                                    "&:hover": {
                                        backgroundColor: "#5BCFDE",
                                    },
                                }}
                            >
                                Login
                            </Button>
                        </Box>
                    </Stack>
                </>
            )}

            {!isAuthorized && !isAlreadyRegistred && (
                <>
                    <ErrorIcon sx={{ fontSize: 160, color: "gray" }} />
                    <Stack spacing={2}>
                        <Typography variant="h6" color="gray">
                            Something went wrong, please try again later.
                        </Typography>
                    </Stack>
                </>
            )}
        </Stack>
    );
};

export { WelcomePage };
