import { Legend, Pie, PieChart, ResponsiveContainer } from "recharts";

const renderColorfulLegendText = (value, entry) => {
    return (
        <span style={{ color: "#596579", fontWeight: 500, padding: "12px" }}>
            {value}
        </span>
    );
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    percent,
    fontSize = 14,
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const labelAngle = midAngle + (midAngle > 180 ? -90 : 90);

    // Convert angle to radians
    const angleRad = labelAngle * (Math.PI / 180);

    // Calculate the label position
    const x = cx + radius * Math.cos(angleRad);
    const y = cy + radius * Math.sin(angleRad);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="middle"
            fontSize={fontSize}
            fontWeight="bold"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const DoughnutChart = ({
    data,
    container = { width: "100%", height: 300 },
    legendProps,
    pieProps,
    hideLegend,
    isInnerlabel,
    hideLabels,
    labelSize,
    withAnimation = true,
}) => {
    const hideLabel =
        data?.find((i) => i.name === "No Enough Data") || hideLabels;

    return (
        <ResponsiveContainer width={container.width} height={container.height}>
            <PieChart direction="ltr">
                {!hideLegend && (
                    <Legend
                        height={32}
                        iconType="square"
                        layout="horizontal"
                        verticalAlign="top"
                        iconSize={16}
                        align="right"
                        formatter={renderColorfulLegendText}
                        {...legendProps}
                    />
                )}
                <Pie
                    isAnimationActive={withAnimation}
                    data={data}
                    cx="40%"
                    cy={152}
                    innerRadius={40}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={hideLabel ? 0 : 1}
                    dataKey="value"
                    label={
                        hideLabel
                            ? null
                            : isInnerlabel
                            ? (params) =>
                                  renderCustomizedLabel({
                                      ...params,
                                      fontSize: labelSize,
                                  })
                            : (val) => `${val.name} (${val.value}%)`
                    }
                    labelLine={false}
                    legendType="none"
                    {...pieProps}
                ></Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export { DoughnutChart };
