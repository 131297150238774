import React from "react";
import { styled } from "@mui/material/styles";
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
} from "@mui/material";

const CustomSelect = styled(Select)({
    "& .MuiInput-underline:after": {
        // borderBottomColor: 'green',
    },
    "& .MuiOutlinedInput-root": {
        height: 40,
        borderRadius: 6,
    },
    "& .MuiInputBase-input": {
        height: 8,
    },
    "& .MuiSelect-outlined": {
        paddingTop: 8,
        paddingBottom: 8,
    },
});

const TimePicker = ({
    selectedTime,
    handleTimeChange,
    timeOptions = [],
    ...rest
}) => {
    return (
        <FormControl fullWidth variant="outlined">
            <CustomSelect
                labelId="time-picker-label"
                value={selectedTime}
                onChange={handleTimeChange}
                {...rest}
            >
                {timeOptions.map((hour) => (
                    <MenuItem key={hour} value={hour}>
                        {hour < 10 ? `0${hour}` : hour}:00
                    </MenuItem>
                ))}
            </CustomSelect>
        </FormControl>
    );
};

export default TimePicker;
