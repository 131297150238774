import { Box, Divider, Stack, Typography } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { useTranslation } from "react-i18next";
import { GridCard } from "../../../../common/components";
import { constants } from "../../../../config/constants";

const FrequentItems = ({ title, total, items }) => {
    const { t } = useTranslation();

    return (
        <GridCard fullWidth padding={0} minHeight={433} height="fit-content">
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Typography fontWeight="bold" fontSize={20} color="#202020">
                    {title}
                </Typography>

                <Typography variant="body2" fontWeight="bold" color="#777272">
                    {t("basket.total_count")}: <b>{total}</b>
                </Typography>
            </Box>

            <Divider />

            <Box display="flex" padding={6} gap={6} flexWrap="wrap">
                {items.map((recommendedItem, index) => {
                    return (
                        <Box
                            display="flex"
                            alignItems="center"
                            key={recommendedItem.name}
                        >
                            <Stack
                                backgroundColor="white"
                                border="1px solid #DCDEE6"
                                borderRadius={3}
                                width={226}
                                height={300}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    backgroundColor="fafafa"
                                    height="224px"
                                    width="224px"
                                    overflow="hidden"
                                    borderRadius="12px 12px 0px 0px"
                                >
                                    {recommendedItem.img ? (
                                        <img
                                            src={recommendedItem.img}
                                            width="100%"
                                            height="100%"
                                            alt="product"
                                        />
                                    ) : (
                                        <ImageIcon
                                            sx={{
                                                color: "#cecece",
                                                fontSize: 32,
                                            }}
                                        />
                                    )}
                                </Box>

                                <Divider />

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    flex={1}
                                >
                                    <Typography
                                        fontSize={18}
                                        fontWeight="bold"
                                        color="#202020"
                                    >
                                        {recommendedItem.name}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Box>
                    );
                })}
            </Box>
        </GridCard>
    );
};

export { FrequentItems };
