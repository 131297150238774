import {
    Button,
    Grid,
    Stack,
    Typography,
    Box,
    TableCell,
    tableCellClasses,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
} from "@mui/material";
import { GridCard } from "../../../../common/components";
import styled from "@emotion/styled";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";
import { constants } from "../../../../config/constants";
import { useTranslation } from "react-i18next";
import { getUserStorage } from "../../../../common/utils";
import { format } from "date-fns";

const plans = {
    "219.00": "Basic Plan - Monthly Billing",
    "395.00": "Professional Plan - Monthly Billing",
    "249.00": "Basic Plan - Monthly Billing",
    "449.00": "Professional Plan - Monthly Billing",
    "49.00": "Additional User",
    "99.00": "Additional Branch",
};

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#FAFCFE",
        color: "#6B7280",
    },
    [`&.${tableCellClasses.root}`]: {
        lineHeight: 1,
        paddingLeft: 24,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.root}`]: {
        lineHeight: 1,
        paddingLeft: 24,
    },
}));

const fakeData = [];

const MyTransactions = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const user = getUserStorage();
    const transactions = user.merchant.payments;

    return (
        <GridCard
            fullWidth
            boxStyles={{
                minHeight: "300px",
                height: "fit-content",
                position: "relative",
            }}
            padding={0}
        >
            <Stack gap={6}>
                <Stack padding={6} paddingBottom={0}>
                    <Typography fontSize={20}>Payment details</Typography>
                </Stack>

                <Stack width="100%">
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeadCell
                                        colSpan={6}
                                        align={isArabic ? "right" : "left"}
                                    >
                                        DATE
                                    </StyledTableHeadCell>
                                    <StyledTableHeadCell
                                        align={isArabic ? "right" : "left"}
                                        colSpan={4}
                                    >
                                        DESCRIPTION
                                    </StyledTableHeadCell>
                                    <StyledTableHeadCell
                                        align={isArabic ? "right" : "left"}
                                    >
                                        AMOUNT
                                    </StyledTableHeadCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!transactions.length &&
                                    Array.from(Array(4).keys()).map((i) => (
                                        <TableRow
                                            key={i}
                                            sx={{
                                                "&:last-child td, &:last-child th":
                                                    {
                                                        border: 0,
                                                    },
                                            }}
                                        >
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                                colSpan={6}
                                            >
                                                -
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align={
                                                    isArabic ? "right" : "left"
                                                }
                                                colSpan={4}
                                            >
                                                -
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align={
                                                    isArabic ? "right" : "left"
                                                }
                                            >
                                                -
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align={
                                                    isArabic ? "right" : "left"
                                                }
                                            >
                                                -
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}

                                {transactions.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                            colSpan={6}
                                            align={isArabic ? "right" : "left"}
                                        >
                                            {format(
                                                new Date(row.created),
                                                "y-M-d"
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align={isArabic ? "right" : "left"}
                                            colSpan={4}
                                            sx={{ color: "#71767C" }}
                                        >
                                            {plans[`${row.amount}`]}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align={isArabic ? "right" : "left"}
                                        >
                                            {numberWithCommas(row.amount)}{" "}
                                            {t("common.currency")}
                                        </StyledTableCell>
                                        {/* <StyledTableCell
                                            align="left"
                                            sx={{ color: "#71767C" }}
                                        >
                                            <Button
                                                variant="text"
                                                sx={{
                                                    textTransform: "capitalize",
                                                    color: "#303030",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                View receipt
                                            </Button>
                                        </StyledTableCell> */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Stack>
        </GridCard>
    );
};

export { MyTransactions };
