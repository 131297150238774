import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom'
import { Typography, Stack, Button, FormControlLabel, Checkbox, Box } from "@mui/material"
import { CustomTextField } from '../../../common/components'
import { getToken } from "../../../common/utils";

export const Register = () => {
    const session = getToken();
    const navigate = useNavigate()
    const { handleSubmit, control } = useForm({
        defaultValues: {
            email: '',
            password: '',
            name: ''
        }

    })

    const onSubmit = (values) => {
        console.log(values)
    }

    useEffect(() => {
        if (session) navigate('/overview')
    }, [session])

    return (
        <Stack w="100%" height="100vh" bgcolor="#f5f6f6" display="flex" justifyContent="center" alignItems="center" spacing={16}>
            <Typography variant="h3" fontWeight="bold">BALLURH</Typography>

            <Stack
                spacing={12}
                padding={8}
                paddingBottom={14}
                width="360px"
                minHeight="400px"
                bgcolor="white"
                boxShadow="0 1px 3px hsla(220,6%,43%,0.2)"
                borderRadius="8px"
            >
                <Typography variant="h5" fontWeight="bold">Register</Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={4}>
                        <Stack spacing={4}>
                            <Stack spacing={1}>
                                <Typography variant="body2">Email</Typography>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) =>
                                        <CustomTextField placeholder="Enter your email" variant="outlined" {...field} />
                                    }
                                />

                            </Stack>

                            <Stack spacing={1}>
                                <Typography variant="body2">Name</Typography>

                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) =>
                                        <CustomTextField placeholder="Enter your name" variant="outlined" {...field} />
                                    }
                                />
                            </Stack>

                            <Stack spacing={1}>
                                <Typography variant="body2">Password</Typography>

                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) =>
                                        <CustomTextField type="password" placeholder="Min. 8 characters" variant="outlined" {...field} />
                                    }
                                />
                            </Stack>

                            <Box display="flex" alignItems="center" justifyContent="space-between" >
                                <FormControlLabel
                                    control={<Checkbox style={{ padding: '0 8px' }} />}
                                    label={<Typography variant="body2" >Remember me</Typography>}
                                />

                                <Link to="/terms" style={{ textDecoration: 'none', color: 'black' }}>
                                    <Typography variant="body2" >terms & conditions</Typography>
                                </Link>
                            </Box>
                        </Stack>

                        <Button type="submit" variant="contained" style={{ textTransform: 'capitalize' }}>Sign Up</Button>

                        <FormControlLabel
                            control={<Checkbox style={{ padding: 0, paddingRight: 8 }} />}
                            label={<Typography variant="body2" >I agree to terms and conditions.</Typography>}
                        />
                    </Stack>
                </form>
            </Stack>
        </Stack>
    )
}