import { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
// import IosShareIcon from "@mui/icons-material/IosShare";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import {
    // ButtonSelect,
    GridCard,
    PageHeader,
} from "../../../../common/components";
import { SalesGoals } from "./components/SalesGoal";
import { TopLTV } from "./components/TopLTV";
import { TopProducts } from "./components/TopProducts";
import { OrderTypes } from "./components/OrderTypes";
import { Categories } from "./components/CategoriesPast";
import { PaymentMethods } from "./components/PaymentMethods";
import { TrendingHours } from "./components/TrendingHours";
import { InfoCard } from "./components/InfoCard";
import { getUserStorage } from "../../../../common/utils";
import { BranchSales } from "./components/BranchesSales";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";

import "./index.css";
import { constants } from "../../../../config/constants";

export const ExtendArabicLogoPDF = () => (
    <Box display="flex">
        <img
            src="/logo_small.svg"
            width="176px"
            height="31px"
            alt="lgo"
            style={{
                position: "absolute",
                right: -25,
                top: "4px",
            }}
        />
        <img
            src="/logo_ballurh_ar.svg"
            width="200px"
            height="40px"
            alt="lgo"
            style={{ position: "relative", top: -6, right: -8 }}
        />
    </Box>
);

const OverviewPDF = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    useEffect(() => {}, []);

    const user = getUserStorage();
    const isOwner = user?.role === 1;

    const [printDate, setPrintDate] = useState("");
    const [period, setPeriod] = useState({
        startDate: new Date("1/1/2000").toLocaleDateString("en"),
        endDate: format(new Date(), "y-M-d"),
    });
    const [overviewData, setOverviewData] = useState(null);
    const [orderTypesData, setOrderTypesData] = useState(null);
    const [categoryData, setCategoryData] = useState(null);
    const [paymentData, setPaymentData] = useState(null);
    const [topProducts, setTopProducts] = useState(null);
    const [topCustomers, setTopCustomers] = useState(null);
    const [branchesSales, setBranchesSales] = useState(null);

    const [trendingHoursData, setTrendingHoursData] = useState([]);

    useEffect(() => {
        const pdfData = JSON.parse(localStorage.getItem("overview-pdf"));

        setTrendingHoursData(pdfData.refined_trending_hours);
        setTopProducts(pdfData.top_products);
        setTopCustomers(pdfData.top_customers);
        setOrderTypesData(pdfData.order_types);
        setCategoryData(pdfData.category_data);
        setPaymentData(pdfData.payment_data);
        setOverviewData(pdfData.overviewData);
        setPeriod(pdfData.period);
        setPrintDate(pdfData.printDate);

        if (pdfData.isArabic) {
            localStorage.setItem(constants.storage.language, "ar");
            i18n.changeLanguage("ar");
        }

        if (isOwner) setBranchesSales(pdfData.branches_sales);
    }, []);

    return (
        <Stack dir={isArabic ? "rtl" : "ltr"}>
            <Box
                height={55}
                backgroundColor="black"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={8}
                sx={{
                    pageBreakAfter: "avoid",
                }}
            >
                {isArabic ? (
                    <ExtendArabicLogoPDF />
                ) : (
                    <img
                        src="/logo_extend.svg"
                        width={176}
                        height="31px"
                        alt="lgo"
                    />
                )}

                <Typography
                    fontSize={12}
                    color="white"
                    fontWeight="bold"
                    position="relative"
                    top="12px"
                >
                    {printDate}
                </Typography>
            </Box>

            <Stack padding={8} spacing={8} minWidth="100%">
                <Stack spacing={4}>
                    <PageHeader
                        title={
                            isOwner
                                ? t("overview.title")
                                : t("overview..overview")
                        }
                    />

                    <Typography fontSize={14}>
                        {period.startDate} &rarr; {period.endDate}
                    </Typography>
                </Stack>

                <Grid
                    container
                    spacing={6}
                    style={{
                        margin: isArabic ? 0 : "-24px",
                        marginTop: 2,
                        marginBottom: 2,
                    }}
                >
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6}>
                                <InfoCard
                                    label={t("overview.total_sales")}
                                    value={`${
                                        numberWithCommas(
                                            overviewData?.totals.total_sales
                                        ) || 0
                                    } ${t("common.currency")} `}
                                    iconPath="/ic_sales_outline.svg"
                                />
                            </Grid>

                            <Grid item xs={6} md={6}>
                                <InfoCard
                                    label={t("overview.total_customers")}
                                    value={
                                        overviewData?.totals.total_customers ||
                                        0
                                    }
                                    iconPath="/ic_customers_many.svg"
                                />
                            </Grid>

                            <Grid item xs={6} md={6}>
                                <InfoCard
                                    label={t("overview.total_transactions")}
                                    value={
                                        overviewData?.totals
                                            .total_transactions || 0
                                    }
                                    iconPath="/ic_transactions.svg"
                                />
                            </Grid>

                            <Grid item xs={6} md={6}>
                                <InfoCard
                                    label={t("overview.avg_basket_size")}
                                    value={`${
                                        numberWithCommas(
                                            overviewData?.totals.avg_basket_size
                                        ) || 0
                                    } ${t("common.currency")} `}
                                    iconPath="/ic_basket.svg"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <GridCard
                        gridProps={{ xs: 12, md: 6 }}
                        padding={0}
                        height={372}
                        boxStyles={{
                            minHeight: "280px",
                            height: "fit-content",
                        }}
                    >
                        <SalesGoals period={period} />
                    </GridCard>
                </Grid>

                <Grid
                    container
                    spacing={6}
                    style={{
                        margin: isArabic ? 0 : "-24px",
                        marginTop: 0,
                        marginBottom: 0,
                    }}
                    sx={{
                        minHeight: 320,
                        height: "fit-content",
                    }}
                >
                    <GridCard
                        gridProps={{ xs: 12, md: 6 }}
                        // gutter
                        padding={0}
                        height="inherit"
                    >
                        {isOwner ? (
                            <BranchSales data={branchesSales} />
                        ) : (
                            <TopLTV data={topCustomers} />
                        )}
                    </GridCard>
                </Grid>

                {!!paymentData?.length && (
                    <Grid
                        container
                        spacing={6}
                        style={{
                            margin: isArabic ? 0 : "-24px",
                            marginTop: 0,
                            marginBottom: 0,
                        }}
                        sx={{
                            minHeight: 320,
                            height: "fit-content",
                        }}
                    >
                        <GridCard
                            gridProps={{ xs: 12, md: 6 }}
                            padding={0}
                            height="inherit"
                        >
                            {isOwner ? (
                                <PaymentMethods data={paymentData} />
                            ) : (
                                <TopProducts data={topProducts} />
                            )}
                        </GridCard>
                    </Grid>
                )}

                <Grid
                    container
                    spacing={6}
                    style={{
                        margin: isArabic ? 0 : "-24px",
                        marginTop: 0,
                        marginBottom: 0,
                    }}
                >
                    <GridCard
                        gridProps={{ xs: 12, md: 6 }}
                        padding={0}
                        height={360}
                        boxStyles={{ minHeight: 230, height: "fit-content" }}
                    >
                        {isOwner ? (
                            <TopLTV data={topCustomers} />
                        ) : (
                            <Categories data={categoryData} />
                        )}
                    </GridCard>

                    <GridCard
                        gridProps={{ xs: 12, md: 6 }}
                        padding={0}
                        height={360}
                        boxStyles={{ minHeight: 230, height: "fit-content" }}
                    >
                        {isOwner ? (
                            <TopProducts data={topProducts} />
                        ) : (
                            <OrderTypes data={orderTypesData} />
                        )}
                    </GridCard>
                </Grid>

                {isOwner && (
                    <Grid
                        container
                        spacing={6}
                        style={{
                            margin: isArabic ? 0 : "-24px",
                            marginTop: 0,
                            marginBottom: 0,
                        }}
                        sx={{
                            minHeight: 300,
                            height: "fit-content",
                        }}
                    >
                        <GridCard
                            padding={0}
                            gridProps={{ xs: 12, md: 6 }}
                            height="inherit"
                        >
                            <Categories
                                data={categoryData && [...categoryData]}
                            />
                        </GridCard>

                        <GridCard
                            padding={0}
                            gridProps={{ xs: 12, md: 6 }}
                            height="inherit"
                        >
                            <OrderTypes data={orderTypesData} />
                        </GridCard>
                    </Grid>
                )}

                <Grid container spacing={0} sx={{ pageBreakBefore: "always" }}>
                    <GridCard fullWidth padding={0} height="inherit">
                        <TrendingHours data={trendingHoursData} />
                    </GridCard>
                </Grid>
            </Stack>
        </Stack>
    );
};

export { OverviewPDF };
