import {
    Autocomplete,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { services } from "../services";
import { toast } from "react-toastify";
import {
    ButtonSelect,
    CustomSelectOutline,
    FilterSelect,
    RangePicker,
} from "../../../common/components";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { constants } from "../../../config/constants";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";
import { getUserStorage } from "../../../common/utils";
import {
    ClearAllOutlined,
    CloseOutlined,
    RemoveCircle,
    RemoveCircleOutline,
} from "@mui/icons-material";

export const CustomTextField = styled(TextField)({
    "& label.Mui-focused": {
        //   color: 'green',
    },
    "& .MuiInput-underline:after": {
        //   borderBottomColor: 'green',
    },
    "& .MuiOutlinedInput-root": {
        height: 40,
        borderRadius: 16,
    },

    "& .MuiInputBase-input": {
        height: 8,
    },

    "& label": {
        top: -6,
        // Add other label styles here
    },
});

export const PixelEvents = () => {
    const { t } = useTranslation();

    const filterItems = [
        { label: t("common.all"), value: "all", key: "type" },
        { label: t("common.dine_in"), value: "DINING", key: "type" },
        { label: t("common.pickup"), value: "TAKE_AWAY", key: "type" },
        {
            label: t("common.drive_through"),
            value: "DRIVE_THROUGH",
            key: "type",
        },
        { label: t("common.delivery"), value: "DELIVERY", key: "type" },
    ];

    const user = getUserStorage();
    const isOwner = user.role === 1;
    const userBranches =
        isOwner && !user.manager_view ? user?.merchant?.branches : user?.branch;
    const allBranches = userBranches.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
    }));

    const [allProducts, setAllProducts] = useState([]);
    const [selectedSegment, setSelectedSegment] = useState("all");
    const [orderType, setOrderType] = useState(filterItems[0].value);
    const [selectedProduct, setSelectedProduct] = useState("");
    const allBranchesOption = { id: "all", label: t("common.all_branches") };
    const [selectedBranch, setSelectedBranch] = useState(allBranchesOption.id);

    const [period, setPeriod] = useState({
        startDate: format(new Date(), "y-M-d"),
        endDate: format(new Date(), "y-M-d"),
    });

    const { mutate: getMenuProducts } = useMutation({
        mutationFn: services.getMenuEngineeringProducts,
        onSuccess: (res) => {
            if (res.data.response && !res.data.error) {
                const response = res.data.response;
                setAllProducts(
                    response.map((p) => ({
                        label: p.product_name,
                        id: p.product_id,
                    }))
                );
            }
        },
        onError: (err) => console.log(err),
    });

    const { mutate: exportPixelEvents, isLoading } = useMutation({
        mutationFn: services.exportPixelEvents,
        onSuccess: (res) => {
            if (res?.data) {
                const link = document.createElement("a");
                link.href = `${constants.API}${res.data.response.file_path}`;
                link.setAttribute("download", ""); // Set the desired file name
                document.body.appendChild(link);
                link.click();
                link.remove();

                toast.success("Audience report downloaded successfully!", {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => {
            console.log(err);
            toast.error("Failed to download the report.", {
                hideProgressBar: true,
            });
        },
    });

    useEffect(() => {
        getMenuProducts({
            from_date: format(new Date("2000-10-26T06:55:40Z"), "y-M-d"),
            till_date: format(new Date(), "y-M-d"),
        });
    }, [getMenuProducts]);

    const downloadEventsReport = () => {
        exportPixelEvents({
            from_date: period.startDate,
            till_date: period.endDate,
            product_id: selectedProduct.id || null,
            customer_persona: selectedSegment,
            order_type: orderType,
            branch_id: selectedBranch,
        });
        // };
    };

    const handleBranchChange = (e) => {
        const newSelectedBranchValue = e.target.value;
        setSelectedBranch(newSelectedBranchValue);
    };

    const handleFilterChange = (value) => {
        setOrderType(value);
    };

    const handleSegementationFilter = (value) => {
        setSelectedSegment(value);
    };

    const handleProductSelect = (newSelectedProduct) => {
        setSelectedProduct(newSelectedProduct);
    };

    return (
        <Box backgroundColor="white" minHeight="70%" borderRadius={2}>
            <Stack p={6} pt={8} spacing={3} maxWidth={320}>
                <Typography color="#06262D">
                    {" "}
                    {t("common.pixel_events")}
                </Typography>

                <RangePicker
                    onChange={(value) =>
                        setPeriod({
                            startDate: value.startDate,
                            endDate: value.endDate,
                        })
                    }
                    isLoading={isLoading}
                />

                <Stack
                    style={{ marginTop: "28px" }}
                    spacing={4}
                    direction="row"
                >
                    <Box>
                        <ButtonSelect
                            value={selectedBranch}
                            onChange={handleBranchChange}
                            options={[allBranchesOption, ...allBranches]}
                        />
                    </Box>

                    <Box>
                        <FilterSelect
                            value={orderType}
                            // label={t("common.order_type")}
                            label={t(`common.${orderType}`)}
                            items={filterItems}
                            onChange={({ value }) => handleFilterChange(value)}
                            icon={KeyboardArrowDownIcon}
                        />
                    </Box>

                    <Select
                        value={selectedSegment}
                        onChange={(e) =>
                            handleSegementationFilter(e.target.value)
                        }
                        input={<CustomSelectOutline />}
                        IconComponent={ExpandMoreIcon}
                        sx={{ width: 160 }}
                    >
                        <MenuItem value="all">
                            {t("customer.all_customers")}
                        </MenuItem>
                        <MenuItem value="Top Customers">
                            {t("customer.top_customers")}
                        </MenuItem>
                        <MenuItem value="New Customers">
                            {t("customer.new_customers")}
                        </MenuItem>
                        <MenuItem value="One Time Customers">
                            {t("customer.one_time_customers")}
                        </MenuItem>
                        <MenuItem value="Customers at Risk">
                            {t("customer.customers_at_risk")}
                        </MenuItem>
                        <MenuItem value="Lost Customers">
                            {t("customer.lost_customers")}
                        </MenuItem>
                    </Select>
                </Stack>

                <Box
                    style={{
                        marginTop: "28px",
                        display: "flex",
                        gap: 4,
                        alignItems: "center",
                    }}
                >
                    <Autocomplete
                        value={selectedProduct}
                        onChange={(e, value) => handleProductSelect(value)}
                        disablePortal
                        id="combo-box-demo"
                        options={allProducts}
                        sx={{ width: 400 }}
                        disableClearable
                        renderInput={(params) => (
                            <CustomTextField
                                {...params}
                                label={t("common.select_product")}
                            />
                        )}
                    />

                    {selectedProduct && (
                        <Box
                            sx={{ cursor: "pointer" }}
                            onClick={() => setSelectedProduct(null)}
                        >
                            <CloseOutlined color="error" />
                        </Box>
                    )}
                </Box>
                <Button
                    sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#00C08B",
                        textTransform: "capitalize",
                        borderRadius: "6px",
                        px: 4,
                        "&:hover": {
                            backgroundColor: "#00C08B",
                            color: "#FFFFFF",
                        },
                        position: "relative",
                        top: "4px",
                    }}
                    disabled={isLoading}
                    onClick={() => downloadEventsReport()}
                >
                    {t("common.export")}
                    {/* Export */}
                </Button>
            </Stack>

            {isLoading && (
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: 9999999,
                    }}
                    open
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </Box>
    );
};
