import {
    differenceInDays,
    differenceInHours,
    differenceInMonths,
    differenceInWeeks,
    differenceInYears,
} from "date-fns";

function isHourlyIteration({ startDate, endDate }) {
    const hrsInWeeks = 24 * 14;
    return (
        differenceInHours(new Date(endDate), new Date(startDate)) < hrsInWeeks
    );
}

function isDailyIteration({ startDate, endDate }) {
    return differenceInDays(new Date(endDate), new Date(startDate)) >= 2;
}

function isWeeklyIteration({ startDate, endDate }) {
    return differenceInWeeks(new Date(endDate), new Date(startDate)) >= 2;
}

function isMonthlyIteration({ startDate, endDate }) {
    return differenceInMonths(new Date(endDate), new Date(startDate)) >= 2;
}

function isYearlyIteration({ startDate, endDate }) {
    return differenceInYears(new Date(endDate), new Date(startDate)) >= 2;
}

export {
    isHourlyIteration,
    isDailyIteration,
    isWeeklyIteration,
    isMonthlyIteration,
    isYearlyIteration,
};
