import { Box, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import TimePicker from "../../../common/components/TimePicker";

const generateTimeOptions = (startHour, endHour) => {
    const hours = [];
    for (let i = startHour; i <= endHour; i++) {
        hours.push(i);
    }
    return hours;
};

const WorkingHours = ({
    selectedFromTime,
    setSelectedFromTime,
    selectedToTime,
    setSelectedToTime,
}) => {
    const { t } = useTranslation();

    const handleFromTimeChange = (event) => {
        setSelectedFromTime(event.target.value);
    };

    const handleToTimeChange = (event) => {
        setSelectedToTime(event.target.value);
    };

    const fromTimeOptions = useMemo(() => generateTimeOptions(0, 23), []);
    const toTimeOptions = useMemo(
        () => generateTimeOptions(selectedFromTime, 23),
        [selectedFromTime]
    );

    return (
        <Box display="flex" alignItems="center">
            <Typography fontSize={14} flex={1} maxWidth={250}>
                {t("account.working_hours")}
            </Typography>

            <Box flex={1}>
                <Stack spacing={2}>
                    <Box display="flex" alignItems="center" gap={4} width={400}>
                        <TimePicker
                            selectedTime={selectedFromTime}
                            handleTimeChange={handleFromTimeChange}
                            timeOptions={fromTimeOptions}
                        />

                        <Typography>{t("common.to")}</Typography>

                        <TimePicker
                            selectedTime={selectedToTime}
                            handleTimeChange={handleToTimeChange}
                            timeOptions={toTimeOptions}
                        />
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
};

export { WorkingHours };
